import { createAction, props } from '@ngrx/store';
import { Toolbar } from '@highlight-grid/models/settings.model';

const USER_SETTINGS_PREFIX = '[USER_SETTINGS]';

export const USER_SETTINGS_TOOLBAR_TOGGLE = `${USER_SETTINGS_PREFIX} Toolbar toggle`;
export const USER_SETTINGS_DATE_RANGE_SET_CHECKED = `${USER_SETTINGS_PREFIX} Date range set checked`;
export const USER_SETTINGS_DATE_RANGE_SET_START = `${USER_SETTINGS_PREFIX} Date range set start`;
export const USER_SETTINGS_DATE_RANGE_SET_END = `${USER_SETTINGS_PREFIX} Date range set end`;
export const USER_SETTINGS_ANOMALY_HIGHLIGHT = `${USER_SETTINGS_PREFIX} Anomaly highlight`;
export const USER_SETTINGS_ANOMALY_HIGHLIGHT_REMOVE = `${USER_SETTINGS_PREFIX} Anomaly highlight remove`;
export const HIGHLIGHT_SET_CAMERA_FILTER = `${USER_SETTINGS_PREFIX} Highlight grid anomalies set camera filter`;
export const HIGHLIGHT_SET_INCIDENT_FILTER = `${USER_SETTINGS_PREFIX} Highlight grid anomalies set incident filter`;
export const HIGHLIGHT_SET_INCIDENT_FILTER_CATEGORIES = `${USER_SETTINGS_PREFIX} Highlight grid anomalies set incident filter categories`;
export const HIGHLIGHT_SET_ANOMALY_MODEL_FILTERS = `${USER_SETTINGS_PREFIX} Highlight grid anomalies set anomaly model filter`;
export const HIGHLIGHT_SET_ANOMALY_LABEL_FILTERS = `${USER_SETTINGS_PREFIX} Highlight grid anomalies set anomaly label filter`;

export const toolbarToggle = createAction(USER_SETTINGS_TOOLBAR_TOGGLE, props<{ payload: { toolbar: Toolbar } }>());
export const dateRangeSetChecked = createAction(USER_SETTINGS_DATE_RANGE_SET_CHECKED, props<{ id: string }>());
export const dateRangeSetStart = createAction(USER_SETTINGS_DATE_RANGE_SET_START, props<{ date: Date }>());
export const dateRangeSetEnd = createAction(USER_SETTINGS_DATE_RANGE_SET_END, props<{ date: Date }>());
export const anomalyHighlight = createAction(USER_SETTINGS_ANOMALY_HIGHLIGHT);
export const anomalyHighlightRemove = createAction(USER_SETTINGS_ANOMALY_HIGHLIGHT_REMOVE);
export const highlightSetCameraFilter = createAction(HIGHLIGHT_SET_CAMERA_FILTER, props<{ highlightCameraFilter: number[] }>());
export const highlightSetIncidentFilter = createAction(HIGHLIGHT_SET_INCIDENT_FILTER, props<{ id: string }>());
export const highlightSetIncidentFilterCategories = createAction(
    HIGHLIGHT_SET_INCIDENT_FILTER_CATEGORIES,
    props<{ incidentFilterCategories: number[] }>()
);
export const highlightSetAnomalyModelFilters = createAction(
    HIGHLIGHT_SET_ANOMALY_MODEL_FILTERS,
    props<{ anomalyModelFilters: string[] }>()
);
export const highlightSetAnomalyLabelFilters = createAction(
    HIGHLIGHT_SET_ANOMALY_LABEL_FILTERS,
    props<{ anomalyLabelFilters: string[] }>()
);
