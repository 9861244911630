<mat-card class="mat-elevation-z0 icetana-modal-anomaly-view" tabindex="0">
  <div class="top-left" *ngIf="incidentExists" matTooltip="Anomaly saved as incident">
    <mat-icon aria-hidden="false" aria-label="incident saved">star</mat-icon>
  </div>
  <div class="close top-right" (click)="onCloseClick()">
    <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
  </div>

  <mat-card-header>
      <mat-card-title>Camera: {{ camera?.name }}</mat-card-title>
  </mat-card-header>

  <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="36px" fxLayoutGap.sm="20px" fxLayoutGap.xs="20px">

    <div fxFlex="10" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="icetana-modal-anomaly-container-left">
      <div class="modal-content">

        <div class="modal-form" fxLayout="column" fxLayoutAlign="start stretch" fxLayout.sm="row" fxLayout.xs="row wrap" fxLayoutGap="10px">
          
          <div class="details-chip" fxFlex="33.33">
            <div class="details-label">Start</div>
            <div class="details-value details-value-datetime">
              <div>
                <div class="details-value-date">
                  <mat-icon>calendar_today</mat-icon>
                  <span>{{ parseDate(anomaly.start) }}</span>
                </div>
                <div class="details-value-time">
                  <mat-icon>access_time</mat-icon>
                  <span>{{ parseTime(anomaly.start) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="details-chip" fxFlex="33.33">
            <div class="details-label">End</div>
            <div class="details-value details-value-datetime">
              <div>
                <div class="details-value-date">
                  <mat-icon>calendar_today</mat-icon>
                  <span>{{ parseDate(anomaly.end) }}</span>
                </div>
                <div class="details-value-time">
                  <mat-icon>access_time</mat-icon>
                  <span>{{ parseTime(anomaly.end) }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="details-chip" fxFlex="33.33">
            <div class="details-label">Event Info</div>
            <div class="details-value details-value-datetime">
              <div>
                <div class="details-value-date" *ngIf="anomalyModel">
                  <mat-icon>{{ anomalyModel?.icon }}</mat-icon>
                  <span>{{ anomalyModel?.label }}</span>
                </div>
                <div class="details-value-time" *ngIf="anomalyLabel">
                  <mat-icon>{{ anomalyLabel?.icon }}</mat-icon>
                  <span>{{ anomalyLabel?.label }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="85" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="video" fxFlexFill>
        <!-- Start of Context menu on right click -->
        <div
        style="visibility: hidden; position: fixed"
        [style.left]="contextMenuPosition.x"
        [style.top]="contextMenuPosition.y"
        [matMenuTriggerFor]="contextMenu"
        ></div>
        <mat-menu #contextMenu="matMenu">
        <ng-template matMenuContent let-item="item">
          <ng-container>
            <button mat-menu-item [matMenuTriggerFor]="incidentMenu" *ngIf="(availableCategories$ | async).length">Quick save</button>
            <button mat-menu-item (click)="onSaveAndCategorise()">Save and categorise</button>
          </ng-container>
        </ng-template>
        </mat-menu>
        <mat-menu #incidentMenu="matMenu" class="incident-category-menu">
          <ng-container *ngFor="let category of (availableCategories$ | async)">
            <button mat-menu-item (click)="onQuickSave(anomaly,category.id)">{{ category.name }}</button>
          </ng-container>
        </mat-menu>
        <!-- End of Context menu on right click -->

        <icetana-vjs-player
          *ngIf="anomaly.videoUrl"
          [options]="{
            autoplay: true,
            fluid: true,
            controls: true,
            loop: true,
            preload: 'metadata',
            sources: [{ src: anomaly.videoUrl, type: 'video/mp4' }]
          }"
          [overlayPath]="this.featureAnomalyDiagFlag ? anomaly.anomalyDiagnostic?.diagnosticsPath : null"
          #video
        ></icetana-vjs-player>
      </div>
    </div>

    <div fxFlex="5" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
      <div class="modal-content"></div>
      <div class="modal-footer">
        <a
          *ngIf="featureNxWitnessFlag"
          mat-icon-button
          matTooltip="Open anomaly in NxWitness"
          matTooltipPosition="left"
          [href]="nxWitnessCameraTimeUri(camera.uri,anomaly.start)"
          target="_blank"
          rel="noopener"
          [disabled]="!isNxWitnessCamera(camera.uri)"
        >
          <mat-icon>launch</mat-icon>
        </a>
        <button
          mat-icon-button
          matTooltip="Download anomaly video"
          matTooltipPosition="left"
          (click)="downloadVideo()"
        >
          <mat-icon>get_app</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
  
  <mat-card-footer fxLayout="row" fxLayoutAlign="center stretch">
    <div>
      <button
        mat-button
        matTooltip="Show previous anomaly"
        [style.visibility]="data.showPrev ? 'visible' : 'hidden'"
        (click)="goPrevAnomaly()"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div>
      <button
        mat-button
        matTooltip="Show next anomaly"
        [style.visibility]="data.showNext ? 'visible' : 'hidden'"
        (click)="goNextAnomaly()"
      >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </mat-card-footer>
</mat-card>
