import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AnomalyState } from '@core/store/anomalies/anomalies.reducers';
import { REDUCER_ANOMALY_ID } from '@anomalies/constants/anomaly-state.constants';

export const selectAnomaly = createFeatureSelector<AnomalyState>(REDUCER_ANOMALY_ID);

export const metricAnomalies = createSelector(selectAnomaly, (anomaly) => anomaly.metricAnomalies);

export const anomaliesHighlightGridList = createSelector(selectAnomaly, (anomaly) => anomaly.anomaliesHighlight);
export const anomaliesHighlightGridListLoading = createSelector(selectAnomaly, (anomaly) => anomaly.loading);
export const highlightGridAnomaliesPlaybackState = createSelector(
  selectAnomaly,
  (anomaly) => anomaly.anomaliesHighlightPlaybackState
);
export const highlightGridPlaybackSpeed = createSelector(
  selectAnomaly,
  (anomaly) => anomaly.anomaliesHighlightPlaybackSpeed
);
export const selectAnomalyData = createSelector(selectAnomaly, (anomaly) => anomaly.anomaly);
export const selectAnomalyConnectionState = createSelector(selectAnomaly, (anomaly) => anomaly.state);
export const selectAnomalyEntities = createSelector(selectAnomaly, (anomaly) => anomaly.entities);
export const selectCameraAnomalies = (cameraId: number) =>
  createSelector(selectAnomalyEntities, (anomalies) => anomalies[cameraId]);
