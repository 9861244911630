/* eslint-disable @typescript-eslint/naming-convention */
import { CameraStatus } from '@cameras/models/cameras/cameras.models';

// Camera feature state key
export const REDUCER_CAMERAS_ID = 'cameras';

// Camera feature module routes
export const CAMERA_HOMEPAGE = '';
export const CAMERA_CREATE = 'create';
export const CAMERA_VIEW = ':id';
export const CAMERA_UPDATE = ':id/update';

/**
 * Enum for camera page view selection
 */
export enum ExpandMode {
  View = 'View',
  Update = 'Update',
}

/**
 * Enum to Indicate page if page is on update or create mode
 */
export enum CameraFormType {
  update,
  create,
}

/**
 * Validators regex constants
 */
/**
 * IPv4 port pattern
 * {@linkhttps://stackoverflow.com/a/113228} - maximum port number
 * {@link https://stackoverflow.com/a/48294970} - regex
 */
export const REGEX_IPV4PORT =
  /^(0|[1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;

/** Hostname pattern {@link https://stackoverflow.com/a/106223} */
// eslint-disable-next-line max-len
export const REGEX_HOST_NAME =
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;

/** Ip address pattern {@link https://stackoverflow.com/a/106223} */
export const REGEX_IP_ADDRESS =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

/** Ip address pattern {@link https://www.regextester.com/105245} */
export const REGEX_ABSOLUTE_PATH_WITH_EXTENTION = /^\/([A-z0-9-_+]+\/)*([A-z0-9-_+]+\.(mp4))$/;

/** URI protocol: checks between the specified groups */
export const REGEX_URI_PROTOCOL = /^(rtsp|rtsps|rtp|srtp|file)$/;

/** File protocol name */
export const FILE_PROTOCOL_NAME = 'file';
export const REGEX_FILE_URI = /^(file):\/\/\/([A-z0-9-_+]+\/)*([A-z0-9-_+]+\.(mp4))$/i;

/** Camera Description form field max length (used in several places) */
export const DESCRIPTION_MAX_LENGTH = 600;

/** State array for failing camera pipeline */
export const CAMERA_PIPELINE_UNRESPONSIVE_STATES = [CameraStatus.INITIALIZING];

/** List of states allowed for camera deletion */
export const CAMERA_STATES_ALLOWING_DELETION = [
  CameraStatus.STOPPING,
  CameraStatus.STOPPED,
  CameraStatus.FAILED,
];
/** Camera entities refresh timeout */
export const CAMERA_REFRESH_TIMEOUT = 60 * 1000;
