import { createReducer, on } from '@ngrx/store';

import { Client } from '@clients/models/client.models';
import { clientsFetchFail, clientsFetchInit, clientsFetchSuccess } from '@clients/store/clients.actions';

export interface ClientsState {
  entities: Client[];
  loading: boolean;
  count?: number;
  fail?: boolean;
  success?: boolean;
}

export const initialClientsState: ClientsState = {
  entities: [],
  loading: false,
};

export const clientsReducer = createReducer(
  initialClientsState,
  // clients list
  on(clientsFetchInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(clientsFetchSuccess, (state, { payload }) => {
    const results = [...payload.results];
    return {
      ...state,
      count: payload.count,
      entities: [...results],
      loading: false,
      success: true,
    };
  }),
  on(clientsFetchFail, (state, error: Error) => ({
    ...state,
    loading: false,
    fail: true,
    success: false,
  }))
);
