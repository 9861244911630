/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

import { LiveAnomaly } from '@core/models/anomaly/anomaly.model';

const LIVE_ANOMALY_PREFIX = '[LIVE ANOMALY]';

// Live Anomalies
export const LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_INIT = `${LIVE_ANOMALY_PREFIX} Start Playing on the LiveWall Init`;
export const LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_SUCCESS = `${LIVE_ANOMALY_PREFIX} Start Playing on the LiveWall Success`;
export const LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_FAIL = `${LIVE_ANOMALY_PREFIX} Start Playing on the LiveWall Fail`;

export const LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_INIT = `${LIVE_ANOMALY_PREFIX} Stop Playing on the LiveWall Init`;
export const LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_SUCCESS = `${LIVE_ANOMALY_PREFIX} Stop Playing on the LiveWall Success`;
export const LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_FAIL = `${LIVE_ANOMALY_PREFIX} Stop Playing on the LiveWall Fail`;

export const CLEAR_LIVEWALL = `${LIVE_ANOMALY_PREFIX} Clear LiveWall`;

export const LIVEWALL_FETCH_GRID_DIMENSIONS_INIT = `${LIVE_ANOMALY_PREFIX} Fetch grid dimensions Init`;
export const LIVEWALL_FETCH_GRID_DIMENSIONS_SUCCESS = `${LIVE_ANOMALY_PREFIX} Fetch grid dimensions Success`;
export const LIVEWALL_FETCH_GRID_DIMENSIONS_FAIL = `${LIVE_ANOMALY_PREFIX} Fetch grid dimensions Fail`;

export const LIVEWALL_UPDATE_GRID_DIMENSIONS_INIT = `${LIVE_ANOMALY_PREFIX} Update grid dimensions Init`;
export const LIVEWALL_UPDATE_GRID_DIMENSIONS_SUCCESS = `${LIVE_ANOMALY_PREFIX} Update grid dimensions Success`;
export const LIVEWALL_UPDATE_GRID_DIMENSIONS_FAIL = `${LIVE_ANOMALY_PREFIX} Update grid dimensions Fail`;

// Start Live Anomalies
export const liveAnomalyStartPlayingOnLiveWallInit = createAction(
  LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_INIT,
  props<{ payload: LiveAnomaly }>()
);
export const liveAnomalyStartPlayingOnLiveWallSuccess = createAction(
  LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_SUCCESS,
  props<{ payload: LiveAnomaly }>()
);
export const liveAnomalyStartPlayingOnLiveWallFail = createAction(
  LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_FAIL,
  props<Error>()
);

// Stop Live Anomalies
export const liveAnomalyStopPlayingOnLiveWallInit = createAction(
  LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_INIT,
  props<{ payload: LiveAnomaly }>()
);
export const liveAnomalyStopPlayingOnLiveWallSuccess = createAction(
  LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_SUCCESS,
  props<{ payload: LiveAnomaly }>()
);
export const liveAnomalyStopPlayingOnLiveWallFail = createAction(
  LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_FAIL,
  props<Error>()
);

// Clear Livewall
export const clearLiveWall = createAction(CLEAR_LIVEWALL, props<{ serverUrl?: string }>());

// Fetch grid dimensions
export const liveWallFetchGridDimensionsInit = createAction(LIVEWALL_FETCH_GRID_DIMENSIONS_INIT);
export const liveWallFetchGridDimensionsSuccess = createAction(
  LIVEWALL_FETCH_GRID_DIMENSIONS_SUCCESS,
  props<{ payload: { id: number; livewall_columns: number; livewall_rows: number } }>()
);
export const liveWallFetchGridDimensionsFail = createAction(LIVEWALL_FETCH_GRID_DIMENSIONS_FAIL, props<Error>());

// Update grid dimensions
export const liveWallUpdateGridDimensionsInit = createAction(
  LIVEWALL_UPDATE_GRID_DIMENSIONS_INIT,
  props<{ payload: { id: number; livewall_columns: number; livewall_rows: number } }>()
);
export const liveWallUpdateGridDimensionsSuccess = createAction(
  LIVEWALL_UPDATE_GRID_DIMENSIONS_SUCCESS,
  props<{ payload: { id: number; livewall_columns: number; livewall_rows: number } }>()
);
export const liveWallUpdateGridDimensionsFail = createAction(LIVEWALL_UPDATE_GRID_DIMENSIONS_FAIL, props<Error>());
