import { AccessLevel, ProtectedFeature } from '@permissions/models/permissions.models';

export const protectedCameras: ProtectedFeature[] = [
  {
    feature: 'create_camera',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'update_camera',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
  {
    feature: 'delete_camera',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'list_cameras',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser, AccessLevel.Operator],
  },
];
