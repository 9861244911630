/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

import {
  metricsAnomaliesFail,
  metricsAnomaliesSuccess,
  METRICS_ANOMALIES_INIT,
  highlightGridAnomaliesFail,
  highlightGridAnomaliesSuccess,
  HIGHLIGHT_GRID_ANOMALIES_INIT,
} from '@core/store/anomalies/anomalies.actions';
import { Anomaly } from '@core/models/anomaly/anomaly.model';
import { AnomaliesService } from '@highlight-grid/services/anomalies.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import {
  selectSettingsDateRangeEnd,
  selectSettingsDateRanges,
  selectSettingsDateRangeStart,
  selectSettingsHighlightCameraFilter,
} from '@core/store/user-settings/user-settings.selectors';


@Injectable()
export class AnomalyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private anomalyService: AnomaliesService
  ) {}

  fetchHighlightGridAnomalies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HIGHLIGHT_GRID_ANOMALIES_INIT),
      withLatestFrom(
        this.store.select(selectSettingsDateRanges),
        this.store.select(selectSettingsDateRangeStart),
        this.store.select(selectSettingsDateRangeEnd),
        this.store.select(selectSettingsHighlightCameraFilter)
      ),
      mergeMap((data) =>
        this.anomalyService.getAnomaliesByFilters({ startDate: data[2], endDate: data[3] }, null, false, data[4]).pipe(
          map((anomalies: Anomaly[]) => highlightGridAnomaliesSuccess({ payload: { anomalies } })),
          catchError((error: Error) => of(highlightGridAnomaliesFail(error)))
        )
      )
    )
  );

  // Metrics Anomalies fetching on a 7 days time period
  fetchMetricsAnomalies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(METRICS_ANOMALIES_INIT),
      mergeMap(() => {
        const now = moment();
        const startDate = moment().subtract(7, 'days').startOf('day');
        return this.anomalyService
          .getAnomaliesByFilters({ startDate: startDate.toDate(), endDate: now.toDate() }, null, false, null)
          .pipe(
            map((anomalies: Anomaly[]) => metricsAnomaliesSuccess({ payload: { anomalies } })),
            catchError((error: Error) => of(metricsAnomaliesFail(error)))
          );
      })
    )
  );
}
