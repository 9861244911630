import { Injectable } from '@angular/core';
import { LayoutGapParent, LayoutGapStyleBuilder, StyleDefinition } from '@angular/flex-layout';
import { FX_LAYOUT_GAP } from '@shared/constants/material.constants';

/**
 * This service allows to define a default `FxLayoutGap`.
 *
 * When using the `fxLayoutGap` directive the default gap would be the `FX_LAYOUT_GAP`
 * specified on the `core/constants`.
 *
 * Assigning a value to the `fxLayoutGap` will override the default value,
 * so e.g. `fxLayoutGap="12rem"` will set up a gap of **12rem**.
 *
 * For more information: https://github.com/angular/flex-layout/wiki/Style-Builders#overriding-defaults
 */

@Injectable()
export class DefaultLayoutGapStyleBuilder extends LayoutGapStyleBuilder {
  /**
   * This is the `DefaultLayoutGapStyleBuilder` constructor
   *
   * @param gapValue is a string
   * @param _styles is a `StyleDefinition` type
   * @param parent is a `LayoutGapParent` type
   *
   */
  sideEffect(gapValue: string, _styles: StyleDefinition, parent: LayoutGapParent) {
    super.sideEffect(gapValue || FX_LAYOUT_GAP, _styles, parent);
  }
}
