/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { AppState } from '@app/store';
import { LiveAnomaly } from '@core/models/anomaly/anomaly.model';
import { GridDimensionsService } from '@live-wall/services/gridDimensions.service';

import {
  LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_INIT,
  LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_INIT,
  liveAnomalyStartPlayingOnLiveWallSuccess,
  liveAnomalyStopPlayingOnLiveWallSuccess,
  liveAnomalyStopPlayingOnLiveWallInit,
  LIVEWALL_UPDATE_GRID_DIMENSIONS_INIT,
  liveWallUpdateGridDimensionsSuccess,
  liveWallUpdateGridDimensionsFail,
  LIVEWALL_FETCH_GRID_DIMENSIONS_INIT,
  liveWallFetchGridDimensionsSuccess,
  liveWallFetchGridDimensionsFail,
} from '@core/store/livewall/livewall.actions';

interface MessageAction {
  readonly type: string;
  index: number;
  payload: LiveAnomaly;
}

interface GridAction {
  readonly type: string;
  payload: { id: number; livewall_columns: any; livewall_rows: any };
}

@Injectable()
export class LiveWallEffects implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private gridDimensionsService: GridDimensionsService
  ) {}

  playAnomaly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LIVE_ANOMALY_START_PLAY_ON_LIVEWALL_INIT),
      mergeMap(({ payload }: MessageAction) => of(liveAnomalyStartPlayingOnLiveWallSuccess({ payload })))
    )
  );

  stopAnomaly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LIVE_ANOMALY_STOP_PLAY_ON_LIVEWALL_INIT),
      mergeMap(({ payload }: MessageAction) => of(liveAnomalyStopPlayingOnLiveWallSuccess({ payload })))
    )
  );

  fetchLiveWallDimensions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LIVEWALL_FETCH_GRID_DIMENSIONS_INIT),
      mergeMap(() =>
        this.gridDimensionsService.fetchGridSettings().pipe(
          map(
            (payload: { id: number; livewall_columns: number; livewall_rows: number }) => liveWallFetchGridDimensionsSuccess({ payload })),
          catchError((error: Error) => of(liveWallFetchGridDimensionsFail(error)))
        )
      )
    )
  );

  updateLiveWallDimensions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LIVEWALL_UPDATE_GRID_DIMENSIONS_INIT),
      mergeMap(({ payload }: GridAction) =>
        this.gridDimensionsService.updateGridSettings({ ...payload }).pipe(
          map((p: { id: number; livewall_columns: number; livewall_rows: number }) => liveWallUpdateGridDimensionsSuccess({ payload: p })),
          catchError((error: Error) => of(liveWallUpdateGridDimensionsFail(error)))
        )
      )
    )
  );

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
