import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@core/components/home/home.component';
import {
  HOMEPAGE,
  CAMERAS,
  HIGHLIGHT_GRID,
  LIVE_WALL,
  AUTH,
  INCIDENTS,
  ANALYTICS,
  USERS,
  CLIENTS,
  SERVER,
} from '@core/constants/app.constants';

import { AuthGuard } from '@auth/guards/auth-guard';
import { ForceResetGuard } from '@auth/guards/force-reset-guard';
import { PermissionsGuard } from '@permissions/guards/permissions-guard';
import { Feature } from '@permissions/models/permissions.models';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: SERVER,
        loadChildren: () => import('@server/server.module').then((m) => m.ServerModule),
      },
      {
        path: HOMEPAGE,
        component: HomeComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard, ForceResetGuard],
      },
      {
        path: CAMERAS,
        loadChildren: () => import('@cameras/cameras.module').then((m) => m.CamerasModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
        data: { feature: 'list_cameras' } as { feature: Feature },
      },
      {
        path: HIGHLIGHT_GRID,
        loadChildren: () => import('@highlight-grid/highlight-grid.module').then((m) => m.HighlightGridModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
        data: { feature: 'view_highlight_grid' } as { feature: Feature },
      },
      {
        path: LIVE_WALL,
        loadChildren: () => import('@live-wall/live-wall.module').then((m) => m.LiveWallModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
        data: { feature: 'view_livewall' } as { feature: Feature },
      },
      {
        path: AUTH,
        loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: USERS,
        loadChildren: () => import('@users/users.module').then((m) => m.UsersModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
        data: { feature: 'list_users' } as { feature: Feature },
      },
      {
        path: CLIENTS,
        loadChildren: () => import('@clients/clients.module').then((m) => m.ClientsModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
        data: { feature: 'list_clients' } as { feature: Feature },
      },
      {
        path: INCIDENTS,
        loadChildren: () => import('@incidents/incidents.module').then((m) => m.IncidentsModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
        data: { feature: 'list_incidents' } as { feature: Feature },
      },
      {
        path: ANALYTICS,
        loadChildren: () => import('@analytics/analytics.module').then((m) => m.AnalyticsModule),
        canActivate: [AuthGuard, ForceResetGuard, PermissionsGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
