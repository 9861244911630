import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppState } from '@app/store/store.reducers';
import { camerasFetchUnpaginatedInit } from '@cameras/store/cameras/cameras.actions';
import { selectCamerasLoading } from '@cameras/store/cameras/cameras.selectors';
import { clientsFetchInit } from '@clients/store/clients.actions';
import { selectPaginatedClientsLoading } from '@clients/store/clients.selectors';
import { metricsAnomaliesInit } from '@core/store/anomalies/anomalies.actions';
import { anomaliesHighlightGridListLoading } from '@core/store/anomalies/anomalies.selectors';
import { categoriesFetchAllInit } from '@incidents/store/categories.actions';
import { selectCategoriesLoading } from '@incidents/store/categories.selectors';
import { metricsIncidentsInit } from '@incidents/store/incidents.actions';
import { selectIncidentsLoading } from '@incidents/store/incidents.selectors';
import { PermissionsService } from '@permissions/services/permissions.service';
import { ServerService } from '@server/services/server.service';


import { METRICS_REFRESH_TIMEOUT } from '@core/constants/metrics.constant';

@Injectable({
  providedIn: 'root',
})
export class MetricsRefreshService {
  private ngUnsubscribe = new Subject<void>();
  private metricsRefreshTimeout = null;

  constructor(
    private store: Store<AppState>,
    private permissionsService: PermissionsService,
    private serverService: ServerService,
  ) {}

  public initMetricsRefreshTimer(): void {
    this.ngUnsubscribe = new Subject<void>();

    // perform the initial dispatch
    this.onMetricsTimeout();

    combineLatest([
      this.store.pipe(select(selectCamerasLoading),takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectPaginatedClientsLoading),takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(anomaliesHighlightGridListLoading),takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectIncidentsLoading),takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectCategoriesLoading),takeUntil(this.ngUnsubscribe)),
    ])
    .pipe(
      filter(
        ([
          loadingCameras,
          loadingClients,
          loadingAnomalies,
          loadingIncidents,
          loadingCategories
        ]) => !this.metricsRefreshTimeout && (
          !loadingCameras && !loadingClients && !loadingAnomalies && !loadingIncidents && !loadingCategories
        )
      ),
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe(_ => {
      // start the refresh timer
      this.startMetricsRefreshTimer();
    });
  }

  public startMetricsRefreshTimer(): void {
    this.metricsRefreshTimeout = setTimeout(() => {
      this.onMetricsTimeout();
    }, METRICS_REFRESH_TIMEOUT);
  }

  public stopMetricsRefreshTimer(): void {
    clearTimeout(this.metricsRefreshTimeout);

    // reset timeout
    this.metricsRefreshTimeout = null;

    // unsubscribe from all subscriptions
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private onMetricsTimeout(): void {

    const serverList = this.serverService.storedServers.value;

    if ((serverList.length === 1) && this.permissionsService.hasPermissionFor('list_clients')) {
      this.store.dispatch(camerasFetchUnpaginatedInit());
      this.store.dispatch(clientsFetchInit({ payload: { pageLimit: 20, pageOffset: 0 } }));
      this.store.dispatch(metricsAnomaliesInit());
      this.store.dispatch(metricsIncidentsInit());
      this.store.dispatch(categoriesFetchAllInit());
    }
    else {
      this.stopMetricsRefreshTimer();
    }


    // reset timeout
    this.metricsRefreshTimeout = null;
  }
}
