import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  LiveWallSettingsAPI,
  LiveWallGridSettings,
  liveWallGridSerializer,
  liveWallSettingsDeserializer,
} from '@live-wall/models/livewall.models';
import { ServerService } from '@server/services/server.service';

@Injectable({
  providedIn: 'root',
})
export class GridDimensionsService {
  private get settingsBaseUrl(): string {
    return `${this.serverService.currentServer.api}settings/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  public fetchGridSettings(): Observable<LiveWallGridSettings> {
    return this.http
      .get<LiveWallSettingsAPI>(this.settingsBaseUrl)
      .pipe(map((response) => liveWallGridSerializer(response) as LiveWallGridSettings))
      .pipe(catchError((error) => throwError(error)));
  }

  public updateGridSettings(liveWallSettingsDTO: LiveWallSettingsAPI): Observable<LiveWallGridSettings> {
    const { id, ...payload } = liveWallSettingsDTO;
    const endpoint = `${this.settingsBaseUrl}${id}/`;

    return this.http
      .patch<LiveWallSettingsAPI>(endpoint, liveWallSettingsDeserializer({ ...payload }))
      .pipe(map((settings) => settings))
      .pipe(catchError((error) => throwError(error)));
  }

  public getStorageGridSettings(): Observable<any> {
    try {
      return new Observable((o) => {
        o.next(JSON.parse(localStorage.gridSettings));
        o.complete();
      });
    } catch (e) {
      return null;
    }
  }

  public setStorageGridSettings(payload: { width: number; height: number }): Observable<any> {
    localStorage.gridSettings = JSON.stringify(payload);
    return new Observable((o) => {
      o.next(payload);
      o.complete();
    });
  }
}
