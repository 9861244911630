/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { AppState } from '@app/store';
import {
  selectSettingsDateRanges,
  selectSettingsDateRangeStart,
  selectSettingsDateRangeEnd,
} from '@core/store/user-settings/user-settings.selectors';
import {
  dateRangeSetChecked,
  dateRangeSetEnd,
  dateRangeSetStart,
} from '@core/store/user-settings/user-settings.actions';
import { RadioFilterType } from '@highlight-grid/models/settings.model';
import { highlightGridAnomaliesInit } from '@core/store/anomalies/anomalies.actions';
import { highlightGridIncidentsInit } from '@incidents/store/incidents.actions';

@Component({
  selector: 'icetana-date-range-radio',
  templateUrl: './date-range-radio.component.html',
  styleUrls: ['./date-range-radio.component.scss'],
})
export class DateRangeRadioComponent implements OnInit, OnDestroy {
  @ViewChild('startPicker') startPicker;
  @ViewChild('endPicker') endPicker;

  private ngUnsubscribe = new Subject<void>();
  startDate = null;
  endDate = new Date();
  dateRanges$: Observable<Array<RadioFilterType>>;
  dateRangeStart$: Observable<Date | null>;
  dateRangeEnd$: Observable<Date | null>;
  timezone: string;

  constructor(private store: Store<AppState>) {
    this.dateRanges$ = this.store.pipe(select(selectSettingsDateRanges));
    this.dateRangeStart$ = this.store.pipe(select(selectSettingsDateRangeStart));
    this.dateRangeEnd$ = this.store.pipe(select(selectSettingsDateRangeEnd));
  }

  ngOnInit(): void {
    this.timezone = moment.tz.guess();

    this.dateRangeStart$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((startDate) => {
      this.startDate = moment(startDate).toDate();
    });

    this.dateRangeEnd$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((endDate) => {
      this.endDate = moment(endDate).toDate();
    });

    this.dateRanges$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((range) => {
      const now = moment();
      const selectedRange = range.find((r) => r.checked);

      if (selectedRange.id !== 'custom') {
        this.endDate = now.toDate();
      }

      switch (selectedRange.id) {
        case 'today':
          this.startDate = now.startOf('day').toDate();
          break;
        case '1-hr':
          this.startDate = now.subtract('1', 'hours').toDate();
          break;
        case '24-hrs':
          this.startDate = now.subtract('1', 'days').toDate();
          break;
        case '7-days':
          this.startDate = now.subtract('7', 'days').toDate();
          break;
        case '30-days':
          this.startDate = now.subtract('30', 'days').toDate();
          break;
        case 'custom':
          break;
      }


      if (this.startDate) {
        this.store.dispatch(dateRangeSetStart({ date: this.startDate }));
      }
      if (this.endDate) {
        this.store.dispatch(dateRangeSetEnd({ date: this.endDate }));
      }

      this.store.dispatch(highlightGridAnomaliesInit());
      this.store.dispatch(highlightGridIncidentsInit());
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  setDateRange(id: string): void {
    this.store.dispatch(dateRangeSetChecked({ id }));
  }

  startDateChange(val: Date): void {
    const date = moment(val).toDate();
    this.store.dispatch(dateRangeSetStart({ date }));
    this.store.dispatch(dateRangeSetChecked({ id: 'custom' }));
  }

  endDateChange(val: Date): void {
    const date = moment(val).toDate();
    this.store.dispatch(dateRangeSetEnd({ date }));
    this.store.dispatch(dateRangeSetChecked({ id: 'custom' }));
  }
}
