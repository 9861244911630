import { Pipe, PipeTransform } from '@angular/core';

/*
*/
@Pipe({name: 'sortByOrder'})
export class ValueToSortByOrderPipe implements PipeTransform {
  transform(value: any[], sortKey1: string, sortKey2?: string, ): any[] {
    return value.sort((n1,n2) => n2[sortKey1][sortKey2] - n1[sortKey1][sortKey2]);
  }
}
