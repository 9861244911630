import { createReducer, on } from '@ngrx/store';

import {
  liveAnomalyCloseAllConnectionsInit,
  liveAnomalyCloseAllConnectionsFinished,
  liveAnomalyConnectionFail,
  liveAnomalyAllConnectionsInit,
  liveAnomalyAllConnectionsFinished,
  liveAnomalyConnectionInit,
  liveAnomalyConnectionSuccess,
  liveAnomalyCloseConnectionInit,
  liveAnomalyCloseConnectionSuccess,
  liveAnomalyReceiveMessage,
} from '@core/store/live-anomalies/anomalies.actions';
import { EventStreamState } from '@shared/constants/event-stream-state.constant';


export class LiveAnomalyServer {
  state: EventStreamState;
  success: boolean;
  loading: boolean;
  fail: boolean;
  lastHeartBeat: number;
}

export interface LiveAnomalyState {
  servers: Map<string, LiveAnomalyServer>;
  loading: boolean;
}

export const initialLiveAnomalyState: LiveAnomalyState = {
  servers: new Map<string, LiveAnomalyServer>(),
  loading: false,
};

export const liveAnomalyReducer = createReducer(
  initialLiveAnomalyState,
  // Live Anomalies Connection
  on(liveAnomalyAllConnectionsInit, (state) => ({
    ...state,
    loading: true,
    servers: new Map<string, LiveAnomalyServer>()
  })),
  on(liveAnomalyAllConnectionsFinished, (state) => ({
    ...state,
    loading: false,
  })),
  // Live Anomaly close EventStream
  on(liveAnomalyCloseAllConnectionsInit, (state) => ({
    ...state,
    loading: true,
  })),
  // Live Anomaly close EventStream
  on(liveAnomalyCloseAllConnectionsFinished, (state) => ({
    ...state,
    loading: false,
  })),
  on(liveAnomalyConnectionInit, (state, { payload }) => {
    const obj = {...state};
    obj.servers.set(
      payload.url,
      {
        state: EventStreamState.OPEN,
        success: false,
        loading: true,
        fail: false,
        lastHeartBeat: null,
      }
    );
    return {
      ...obj,
    };
  }),
  on(liveAnomalyConnectionSuccess, (state, { payload }) => {
    const obj = {...state};
    obj.servers.set(
      payload.url,
      {
        state: EventStreamState.OPEN,
        success: true,
        loading: false,
        fail: false,
        lastHeartBeat: Date.now(),
      }
    );
    return {
      ...obj,
    };
  }),
  on(liveAnomalyConnectionFail, (state, { payload }) => {
    const obj = {...state};
    const server = obj.servers.get(payload.url);
    obj.servers.set(
      payload.url,
      {
        state: server ? server.state : EventStreamState.OPEN,
        success: false,
        loading: false,
        fail: true,
        lastHeartBeat: Date.now(),
      }
    );
    return {
      ...obj,
    };
  }),
  on(liveAnomalyCloseConnectionInit, (state, { payload }) => {
    const obj = {...state};
    const server = obj.servers.get(payload.url);
    obj.servers.set(
      payload.url,
      {
        state: EventStreamState.CLOSED,
        success: false,
        loading: true,
        fail: false,
        lastHeartBeat: server ? server.lastHeartBeat : null,
      }
    );
    return {
      ...obj,
    };
  }),
  on(liveAnomalyCloseConnectionSuccess, (state, { payload }) => {
    const obj = {...state};
    obj.servers.set(
      payload.url,
      {
        state: EventStreamState.CLOSED,
        success: true,
        loading: false,
        fail: false,
        lastHeartBeat: Date.now(),
      }
    );
    return {
      ...obj,
    };
  }),
  on(liveAnomalyReceiveMessage, (state, { payload }) => {
    const obj = {...state};
    const server = obj.servers.get(payload.url);
    obj.servers.set(
      payload.url,
      {
        state: server ? server.state : EventStreamState.OPEN,
        success: server ? server.success : true,
        loading: server ? server.loading : false,
        fail: server ? server.fail : false,
        lastHeartBeat: Date.now(),
      }
    );
    return {
      ...obj,
    };
  }),
);
