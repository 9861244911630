import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AUTH as AUTH_ROUTE, SERVER as SERVER_ROUTE } from '@core/constants/app.constants';
import { AUTH_ROUTE_LOGIN } from '@auth/constants/auth.constants';
import { AuthService } from '@auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentNavigation = this.router.getCurrentNavigation();
    const redirectState = currentNavigation.extras.state?.redirect;
    return this.authService.isAuthenticated().pipe(mergeMap((isAuthenticated) => {
      const currentBuild = environment.localStorageSchemaVersion;
      let localStorageSchemasEqual = true;
      if (environment.production && currentBuild) {
        localStorageSchemasEqual = this.authService.checkLocalStorageSchemaVersion(currentBuild);
      }
      if (!isAuthenticated || !localStorageSchemasEqual) {
        if (environment.production) {
          this.router.navigate([AUTH_ROUTE, AUTH_ROUTE_LOGIN], { state: { redirect: redirectState || state.url } });
        } else {
          this.router.navigate([SERVER_ROUTE]);
        }
        return of(false);
      }
      else {
        return of(true);
      }
    }));
  }
}
