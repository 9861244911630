/* eslint-disable */
const fakeCategory = {
  id: 11,
  parent: 1,
  name: 'New Category',
  description: 'A new category',
  order: 2,
  created: '2020-01-01 00:00:00',
  updated: null,
  default: true,
};

const result = {
  count: 1,
  results: [fakeCategory],
};

export const handleCategories = () => {
  return result;
};

const fakeIncidentStatuses = [
  {
    id: 1,
    name: 'Unresolved',
    description: 'Unresolved incident',
    order: 1,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: true,
  },
];

const fakeIncidentStatusesPagination = {
  count: 1,
  results: fakeIncidentStatuses,
};

export const handleStatus = () => {
  return fakeIncidentStatusesPagination;
};
