import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IncidentsState } from '@incidents/store/incidents.reducers';

export const REDUCER_INCIDENT_ID = 'incidents';

export const selectIncidents = createFeatureSelector<IncidentsState>(REDUCER_INCIDENT_ID);

export const metricIncidents = createSelector(selectIncidents, (incident) => incident.metricIncidents);

export const highlightGridIncidents = createSelector(selectIncidents, (incident) => incident.highlightGridIncidents);

export const selectIncidentsEntities = createSelector(selectIncidents, (incidentsState) => incidentsState.entities);

export const selectIncidentsCount = createSelector(selectIncidents, (incidentsState) => incidentsState.count);

export const selectIncident = (index: number) =>
  createSelector(selectIncidents, (incidentsState) => incidentsState.entities[index]);

export const selectIncidentsLoading = createSelector(selectIncidents, (incidentsState) => incidentsState.loading);

export const selectIncidentsFail = createSelector(selectIncidents, (incidentsState) => incidentsState.fail);
