/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AppState } from '@app/store';
import {
  selectSettingsDateRangeEnd,
  selectSettingsDateRangeStart,
  selectSettingsHighlightCameraFilter,
} from '@core/store/user-settings/user-settings.selectors';
import { Incident, IncidentPagination } from '@incidents/models/incident.models';
import { IncidentsService } from '@incidents/services/incidents.service';
import {
  incidentsFetchAllSuccess,
  INCIDENTS_FETCH_ALL_INIT,
  incidentsFetchAllFail,
  INCIDENT_UPDATE_INIT,
  incidentUpdateSuccess,
  incidentUpdateFail,
  INCIDENT_CREATE_INIT,
  incidentCreateSuccess,
  incidentCreateFail,
  INCIDENT_DELETE_INIT,
  incidentDeleteFail,
  incidentDeleteSuccess,
  INCIDENT_FETCH_INIT,
  incidentFetchSuccess,
  incidentFetchFail,
  METRICS_INCIDENTS_INIT,
  metricsIncidentsSuccess,
  metricsIncidentsFail,
  HIGHLIGHT_GRID_INCIDENTS_INIT,
  highlightGridIncidentsSuccess,
  highlightGridIncidentsFail,
} from '@incidents/store/incidents.actions';


interface IncidentAction {
  readonly type: string;
  id?: number;
  payload?: Incident;
}

@Injectable()
export class IncidentsEffects implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private incidentsService: IncidentsService
  ) {}

  fetchAllIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INCIDENTS_FETCH_ALL_INIT),
      mergeMap(() =>
        this.incidentsService.fetchAllIncidents().pipe(
          map((incidentPagination: IncidentPagination) => incidentsFetchAllSuccess({ payload: incidentPagination })),
          catchError((error: Error) => of(incidentsFetchAllFail(error)))
        )
      )
    )
  );

  fetchIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INCIDENT_FETCH_INIT),
      mergeMap((action: IncidentAction) =>
        this.incidentsService.fetchIncident(action.id).pipe(
          map((incident: Incident) => incidentFetchSuccess({ payload: incident })),
          catchError((error: Error) => of(incidentFetchFail(error)))
        )
      )
    )
  );

  updateIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INCIDENT_UPDATE_INIT),
      mergeMap((action: IncidentAction) =>
        this.incidentsService.updateIncident({ ...action.payload }).pipe(
          map((incident) => incidentUpdateSuccess({ payload: incident })),
          catchError((error: Error) => of(incidentUpdateFail(error)))
        )
      )
    )
  );

  createIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INCIDENT_CREATE_INIT),
      mergeMap((action: IncidentAction) => this.incidentsService.createIncident({ ...action.payload }).pipe(
        map((incident) => incidentCreateSuccess({ payload: incident })),
        catchError((error: Error) => of(incidentCreateFail(error)))
      ))
    )
  );

  deleteIncident$ = createEffect(() =>
    this.actions$.pipe(
      ofType(INCIDENT_DELETE_INIT),
      mergeMap((action: IncidentAction) =>
        this.incidentsService.deleteIncident({ ...action.payload }).pipe(
          map(() => incidentDeleteSuccess({ payload: action.payload })),
          catchError((error: Error) => of(incidentDeleteFail(error)))
        )
      )
    )
  );

  fetchMetricIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(METRICS_INCIDENTS_INIT),
      mergeMap(() => {
        const now = moment();
        const startDate = moment().subtract(7, 'days').startOf('day');
        return this.incidentsService.getIncidentsByFilters({ startDate: startDate.toDate(), endDate: now.toDate() }).pipe(
          map((incidentPagination: IncidentPagination) => metricsIncidentsSuccess({ payload: incidentPagination })),
          catchError((error: Error) => of(metricsIncidentsFail(error)))
        );
      })
    )
  );

  fetchHighlightGridIncidents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HIGHLIGHT_GRID_INCIDENTS_INIT),
      withLatestFrom(
        this.store.select(selectSettingsDateRangeStart),
        this.store.select(selectSettingsDateRangeEnd),
        this.store.select(selectSettingsHighlightCameraFilter)
      ),
      mergeMap((data) =>
        this.incidentsService.getIncidentsByFilters({ startDate: data[1], endDate: data[2] }, data[3]).pipe(
          map((incidentPagination: IncidentPagination) => highlightGridIncidentsSuccess({ payload: incidentPagination })),
          catchError((error: Error) => of(highlightGridIncidentsFail(error)))
        )
      )
    )
  );

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
