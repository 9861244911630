import { createAction, props } from '@ngrx/store';

import { Client, ClientPagination } from '@clients/models/client.models';

const CLIENTS_PREFIX = '[CLIENTS]';

export const CLIENTS_FETCH_INIT = `${CLIENTS_PREFIX} Fetch Init`;
export const CLIENTS_FETCH_SUCCESS = `${CLIENTS_PREFIX} Fetch Success`;
export const CLIENTS_FETCH_FAIL = `${CLIENTS_PREFIX} Fetch Fail`;

export const CLIENT_DELETE_INIT = `${CLIENTS_PREFIX} Delete Init`;
export const CLIENT_DELETE_SUCCESS = `${CLIENTS_PREFIX} Delete Success`;
export const CLIENT_DELETE_FAIL = `${CLIENTS_PREFIX} Delete Fail`;

export const CLIENT_CREATE_INIT = `${CLIENTS_PREFIX} Create Init`;
export const CLIENT_CREATE_SUCCESS = `${CLIENTS_PREFIX} Create Success`;
export const CLIENT_CREATE_FAIL = `${CLIENTS_PREFIX} Create Fail`;

export const CLIENT_UPDATE_INIT = `${CLIENTS_PREFIX} Update Init`;
export const CLIENT_UPDATE_SUCCESS = `${CLIENTS_PREFIX} Update Success`;
export const CLIENT_UPDATE_FAIL = `${CLIENTS_PREFIX} Update Fail`;

export const clientsFetchInit = createAction(
  CLIENTS_FETCH_INIT,
  props<{ payload: { pageLimit: number; pageOffset: number } }>()
);
export const clientsFetchSuccess = createAction(CLIENTS_FETCH_SUCCESS, props<{ payload: ClientPagination }>());
export const clientsFetchFail = createAction(CLIENTS_FETCH_FAIL, props<Error>());

export const clientDeleteInit = createAction(CLIENT_DELETE_INIT, props<{ payload: { client: Client } }>());
export const clientDeleteSuccess = createAction(CLIENT_DELETE_SUCCESS, props<any>());
export const clientDeleteFail = createAction(CLIENT_DELETE_FAIL, props<Error>());

export const clientCreateInit = createAction(CLIENT_CREATE_INIT, props<{ payload: { client: Client } }>());
export const clientCreateSuccess = createAction(CLIENT_CREATE_SUCCESS, props<any>());
export const clientCreateFail = createAction(CLIENT_CREATE_FAIL, props<Error>());

export const clientUpdateInit = createAction(CLIENT_UPDATE_INIT, props<{ payload: { client: Client } }>());
export const clientUpdateSuccess = createAction(CLIENT_UPDATE_SUCCESS, props<any>());
export const clientUpdateFail = createAction(CLIENT_UPDATE_FAIL, props<Error>());
