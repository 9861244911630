import * as d3 from 'd3';
import * as moment from 'moment';
import 'moment-timezone';

import { CAMERA_STATES_ALLOWING_DELETION } from '@cameras/constants/cameras.constants';
import { Camera } from '@cameras/models/cameras/cameras.models';
import { Client } from '@clients/models/client.models';
import { Anomaly } from '@core/models/anomaly/anomaly.model';
import { UNCATEGORISED_INCIDENT_CATEGORY_NAME } from '@incidents/constants/incidents.constants';
import { Incident, IncidentCategory } from '@incidents/models/incident.models';

// the quantity of cameras and anomaly count to return
export const CAMERA_LIST_LIMIT = 5;

const SECONDS_IN_AN_HOUR = 60 * 60;
const SECONDS_IN_A_WEEK = 7 * 24 * SECONDS_IN_AN_HOUR;
const SECONDS_IN_A_DAY = 24 * SECONDS_IN_AN_HOUR;

export class AnomalyMetrics {
  // Title
  dailyTotalAnomalyDuration = 0;
  cameraEventCounterDailyMetric: CameraEventMetrics = new CameraEventMetrics();
  cameraEventCounterHourlyMetric: CameraEventMetrics = new CameraEventMetrics();
  // Camera List of hightest events
  weeklyTopTotalCameraAnomalyCount: CameraEventCount[] = [];
  // Events
  dailyAverageAnomalies = 0;
  dailyAverageAnomalyRate = 0;
  weeklyAnomalyCount = 0;
  weeklyAverageAnomalyDuration = 0;
  weeklyAverageCameraAnomalyCount = 0;
}

export class IncidentMetrics {
  // Incident Reporting
  weeklyTotalIncidentCount = 0;
  weeklyCategoryUsageCount = 0;
  weeklyMostUsedCategory = '';
  weeklyHighestIncidentCamera = '';
}

export class CameraEventCount {
  cameraName = '';
  eventCount = 0;
}

export class EventMetrics {
  date = '';
  eventCount = 0;
  eventDuration = 0;
  eventDailyRate = 0;
}
export class CameraEventIndividualMetrics {
  cameraId = 0;
  cameraName = '';
  eventCount = {total: 0};
  eventDuration = {total: 0};
  eventDailyRate = {total: 0};
  dailyEventMetrics: EventMetrics[] = [];
}
export class CameraEventMetrics {
  cameras: CameraEventIndividualMetrics[] = [];
  dateRange = [];
  eventCount = {minValue: 0, maxValue: 0};
  eventDuration = {minValue: 0, maxValue: 0};
  eventDailyRate = {minValue: 0, maxValue: 0};
}

export class Metrics {
  anomalyMetrics: AnomalyMetrics;
  incidentMetrics: IncidentMetrics;
  // Health
  currentCameraCount = 0;
  currentSiteCount = 0;
  currentCamerasOffline: Camera[] = [];
  totalCameraDuration = 0;
}

export const anomalyMetricCalculations = (
  anomalies: Anomaly[],
  cameras: Camera[],
  minDate: moment.Moment,
  maxDate: moment.Moment,
): AnomalyMetrics => {
  const result = new AnomalyMetrics();

  if (!anomalies || !anomalies.length) {
    return result;
  }

  const now = moment();

  // calculate anomaly durations in seconds based on the start and end timestamps (in seconds)
  const anomalyDurations = [];
  anomalies.forEach((anomaly) => {
    const started = moment(anomaly.start);
    const ended = moment(anomaly.end);

    anomalyDurations.push({
      cameraId: anomaly.camera,
      cameraName: anomaly.cameraName,
      duration: ended.diff(started) / 1000,
      created: anomaly.start,
    });
  });
  // Rollup daily hours and map to an array
  const anomalyStatsByDate = Array.from(
    d3.sort(
      d3.rollup(
        anomalyDurations,
        v => ({
          dailyDuration: d3.sum(v, e => e.duration),
          anomalyCount: v.length,
        }),
        d => moment(d.created).format('YYYY-MM-DD')
      ),
      ([date,]) => -date
    ), ([date, data]) => ({
      date,
      dailyDuration: data.dailyDuration,
      anomalyCount: data.anomalyCount
    })
  );
  // calculate the total daily anomaly duration (in hours)
  const todayAnomalyStats = anomalyStatsByDate.find(v => v.date === now.format('YYYY-MM-DD'));
  result.dailyTotalAnomalyDuration = todayAnomalyStats
    ? parseFloat((todayAnomalyStats.dailyDuration / (SECONDS_IN_AN_HOUR)).toFixed(2))
    : 0;
  // daily average event count, must calculate average manually, as anomalyStatsByDate doesn't guarantee 7 days of data
  result.dailyAverageAnomalies = parseFloat((d3.sum(anomalyStatsByDate, v => v.anomalyCount) / 7).toFixed(2));

  // enumerate each date in the date range
  const dateRange = enumerateDaysBetweenDates(minDate, maxDate, 'YYYY-MM-DD', 'day');

  if (cameras.length) {
    // calculate daily anomaly rate, total duration of all anomalies / 24hours (in percent) / N'O cameras / N'O days
    result.dailyAverageAnomalyRate = parseFloat(
      (d3.sum(anomalyStatsByDate, v => v.dailyDuration) /
      (cameras.length * dateRange.length * SECONDS_IN_A_DAY) * 100
    ).toFixed(2));
  }

  // total anomaly count
  result.weeklyAnomalyCount = anomalies.length;
  // average duration
  result.weeklyAverageAnomalyDuration = parseFloat(d3.mean(anomalyDurations, v => v.duration).toFixed(2));

  result.cameraEventCounterDailyMetric = cameraEventCounterMetricCalculations(
    anomalies,
    cameras,
    minDate,
    maxDate,
    'YYYY-MM-DD',
    'day'
  );

  result.cameraEventCounterHourlyMetric = cameraEventCounterMetricCalculations(
    anomalies,
    cameras,
    minDate,
    maxDate,
    'YYYY-MM-DD HH:ss',
    'hour'
  );

  // Rollup anomalies by camera ids, sum the events for each, and sort by descending event count
  const camerasByAnomalyCount = d3.sort(
    d3.rollup(
      anomalies,
      v => ({
        cameraName: v[0].cameraName,
        eventCount: v.length
      }),
      d => d.camera
    ),
    ([, data]) => -data.eventCount
  );
  // map camerasByAnomalyCount to an array, and limit list to be shown
  result.weeklyTopTotalCameraAnomalyCount = Array.from(camerasByAnomalyCount, ([, data]) => ({
    cameraName: data.cameraName,
    eventCount: data.eventCount
  })).slice(0, CAMERA_LIST_LIMIT);
  // average events per camera
  result.weeklyAverageCameraAnomalyCount = parseFloat(d3.mean(camerasByAnomalyCount, ([, data]) => data.eventCount).toFixed(2));

  return result;
};


export const cameraEventCounterMetricCalculations = (
  anomalies: Anomaly[],
  cameras: Camera[],
  minDate: moment.Moment,
  maxDate:  moment.Moment,
  dateFormat='YYYY-MM-DD',
  incrementUnit: moment.unitOfTime.DurationConstructor='day',
  timezone = moment.tz.guess(),
): CameraEventMetrics => {
  let result: CameraEventMetrics = null;

  const groupedCameras = d3.group(
    anomalies,
    d => d.camera, // camera grouping with ID
    d => moment(d.start).tz(timezone).startOf(incrementUnit) // creation date
  );
  const hierarchy = d3.hierarchy(groupedCameras);
  const cameraDailyEventsCounter: CameraEventIndividualMetrics[] = Array.from(
    hierarchy.children,
    (camera) => ({
      cameraId: camera.data[0],
      cameraName: camera.children[0].data[1][0].cameraName,
      eventCount: {
        total: d3.sum(camera.children, dailyAnomalies => dailyAnomalies.children.length),
      },
      eventDuration: {total: 0},
      eventDailyRate: {total: 0},
      dailyEventMetrics: Array.from(
        camera.children,
        (dailyAnomalies,) => ({
          date: dailyAnomalies.data[0].tz(timezone).format(dateFormat),
          eventCount: dailyAnomalies.children.length,
          eventDuration: d3.sum(dailyAnomalies.children, anomaly => {
            const anomalyData = (anomaly.data as any);
            const started = moment(anomalyData.start);
            const ended = moment(anomalyData.end);
            return ended.diff(started);
          }),
          eventDailyRate: 0,
        })
      )
    })
  );

  // go back and fill in additional fields
  cameraDailyEventsCounter.forEach(camera => {
    camera.dailyEventMetrics.forEach(dailyAnomalies => {
      dailyAnomalies.eventDailyRate = +(moment.duration(dailyAnomalies.eventDuration).as('seconds') / SECONDS_IN_A_DAY * 100).toFixed(2);
    });
    camera.eventDuration.total = d3.sum(camera.dailyEventMetrics, dailyAnomalies => dailyAnomalies.eventDuration);
    camera.eventDailyRate.total = d3.sum(camera.dailyEventMetrics, dailyAnomalies => dailyAnomalies.eventDailyRate);
  });

  // check for any cameras with no anomalies, and append to list
  cameras.forEach((camera) => {
    const foundCamera = cameraDailyEventsCounter.findIndex(
      sortedCameraDailyEvents => sortedCameraDailyEvents.cameraId === camera.id
    );
    if (foundCamera === -1) {
      cameraDailyEventsCounter.push({
        cameraId: camera.id,
        cameraName: camera.name,
        eventCount: {total: 0},
        eventDuration: {total: 0},
        eventDailyRate: {total: 0},
        dailyEventMetrics: []
      });
    }
  });

  result = {
    cameras: cameraDailyEventsCounter,
    dateRange: enumerateDaysBetweenDates(minDate, maxDate, dateFormat, incrementUnit),
    eventCount: {
      minValue: d3.min(cameraDailyEventsCounter, camera =>  d3.min(camera.dailyEventMetrics, d => d.eventCount)),
      maxValue: d3.max(cameraDailyEventsCounter, camera =>  d3.max(camera.dailyEventMetrics, d => d.eventCount)),
    },
    eventDuration: {
      minValue: d3.min(cameraDailyEventsCounter, camera =>  d3.min(camera.dailyEventMetrics, d => d.eventDuration)),
      maxValue: d3.max(cameraDailyEventsCounter, camera =>  d3.max(camera.dailyEventMetrics, d => d.eventDuration)),
    },
    eventDailyRate: {
      minValue: d3.min(cameraDailyEventsCounter, camera =>  d3.min(camera.dailyEventMetrics, d => d.eventDailyRate)),
      maxValue: d3.max(cameraDailyEventsCounter, camera =>  d3.max(camera.dailyEventMetrics, d => d.eventDailyRate)),
    },
  };

  return result;
};


export const incidentMetricCalculations = (
  incidents: Incident[],
  anomalies: Anomaly[],
  categories: IncidentCategory[]
): IncidentMetrics => {
  const result = new IncidentMetrics();

  if (!incidents || !incidents.length) {
    return result;
  }

  // filter categories by removing UNCATEGORISED_INCIDENT_CATEGORY_NAME
  const filteredCategories = [...categories].filter((i) => i.name !== UNCATEGORISED_INCIDENT_CATEGORY_NAME);
  // filter UNCATEGORISED_INCIDENT_CATEGORY_NAME to extract category ID
  const uncategorisedCategory = [...categories].find((i) => i.name === UNCATEGORISED_INCIDENT_CATEGORY_NAME);

  // Incident count
  result.weeklyTotalIncidentCount = incidents.length;
  // Count all categories from incidents
  const usedCategoriesCount = incidents.reduce((acc, d) => {
    d.categories.forEach(n => acc[n] = (acc[n] !== undefined) ? acc[n] + 1 : 0);
    return acc;
  }, {});

  // filter category count by removing any referring to uncategorised
  if (uncategorisedCategory) {
    delete usedCategoriesCount[uncategorisedCategory.id];
  }

  const usedCategoryIds = Object.keys(usedCategoriesCount);
  // count the total number of unique categories used across incidents
  result.weeklyCategoryUsageCount = usedCategoryIds.length ? usedCategoryIds.length : 0;
  // sort categories used for incidents by count descending
  const sortedUsedCategoryIdCount = d3.sort(usedCategoryIds, (categoryId) => -usedCategoriesCount[categoryId]);
  // match most used category ID to category name
  result.weeklyMostUsedCategory = '';
  if (sortedUsedCategoryIdCount.length && filteredCategories && filteredCategories.length) {
    const mostUsedCategory = filteredCategories.find(category => category.id === parseInt(sortedUsedCategoryIdCount[0], 10) );
    result.weeklyMostUsedCategory = mostUsedCategory ? mostUsedCategory.name : '';
  }

  // Match incidents with anomalies for camera information
  const matchedIncidents = [];
  incidents.forEach(incident => {
    let matchedAnomaly = null;
    if (anomalies && anomalies.length) {
      matchedAnomaly = anomalies.find(anomaly => anomaly.id === incident.anomaly);
    }
    if (matchedAnomaly) {
      matchedIncidents.push({
        incidentId: incident.id,
        cameraName: matchedAnomaly.cameraName,
        cameraId: matchedAnomaly.camera,
      });
    }
  });
  // Group by camera names and sort by incident count
  result.weeklyHighestIncidentCamera = '';
  if (matchedIncidents.length) {
    const incidentStatsByCamera = d3.sort(
      d3.rollup(
        matchedIncidents,
        v => ({
          cameraName: v[0].cameraName,
          incidentCount: v.length
        }),
        d => d.cameraId
      ),
      ([, data]) => -data.incidentCount
    );
    // map camera names to array and extract the first of the set
    result.weeklyHighestIncidentCamera = Array.from(incidentStatsByCamera, ([, data]) => (data.cameraName))[0];
  }

  return result;
};

/** Enumerate an array of dates from startDate to endDate */
const enumerateDaysBetweenDates = (
  startDate,
  endDate,
  dateFormat='YYYY-MM-DD',
  incrementUnit: moment.unitOfTime.DurationConstructor='days'
) => {
  const currDate = moment(startDate).startOf(incrementUnit);
  const lastDate = moment(endDate).startOf(incrementUnit);
  const dates = [];
  if (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format(dateFormat));//.toDate());
  }

  while(currDate.add(1, incrementUnit).diff(lastDate) <= 0) {
      dates.push(currDate.clone().format(dateFormat));//.toDate());
  }
  return dates;
};

export const metricsSerializer = (
  cameras: Camera[],
  clients: Client[],
  anomalies: Anomaly[],
  incidents: Incident[],
  categories: IncidentCategory[],
  minDate: moment.Moment,
  maxDate: moment.Moment,
): Metrics => {
  // camera count
  const currentCameraCount = (cameras && cameras.length) ? cameras.length : 0;

  // count client sites
  const currentSiteCount = d3.sum(clients, client => client.sites.length);

  // filter cameras to camera not in the started state
  const currentCamerasOffline: Camera[] = cameras.filter(
    (camera) => CAMERA_STATES_ALLOWING_DELETION.indexOf(camera.status) !== -1
  );

  // the total camera duration since each cameras last updated timestamp
  const cameraDuration = d3.sum(cameras, camera => {
    // ignore any cameras currently offline
    if ((CAMERA_STATES_ALLOWING_DELETION.indexOf(camera.status) !== -1) || (Object.keys(camera).indexOf('started') === -1)) {
      return 0;
    }
    const lastUpdated = moment(camera.started);
    return moment.duration(moment().diff(lastUpdated)).asHours();
  });
  const totalCameraDuration = +cameraDuration.toFixed();

  // Anomaly calculations
  const anomalyMetrics = anomalyMetricCalculations(anomalies, cameras, minDate, maxDate);

  // Incident calculations
  const incidentMetrics = incidentMetricCalculations(incidents, anomalies, categories);

  return {
    anomalyMetrics,
    incidentMetrics,
    currentCameraCount,
    currentSiteCount,
    currentCamerasOffline,
    totalCameraDuration,
  };
};
