import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '@app/store';
import {
  AUTH_ROUTE_LOGIN,
  AUTH_ROUTE_REFRESH,
  AUTH_TOKEN_KEY,
  LOCAL_STORAGE_SCHEMA_VERSION,
  AUTH_ROUTE_TEST
} from '@auth/constants/auth.constants';
import { AccessToken, TokenContent, tokenSerializer } from '@auth/models/auth.models';
import { JwtService } from '@auth/services/jwt.service';
import { logout, setRoles, setUser } from '@auth/store/auth.actions';
import {
  HOMEPAGE as HOMEPAGE_ROUTE,
  AUTH as AUTH_ROUTE,
  EVENTSTREAM_FLAG,
  LOCAL_STORAGE_EVENTSTREAM_OVERRIDE
} from '@core/constants/app.constants';
import { liveAnomalyAllConnectionsInit, liveAnomalyCloseAllConnectionsInit } from '@core/store/live-anomalies/anomalies.actions';
import { ServerAuthData } from '@server/models/server.model';
import { ServerService } from '@server/services/server.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private eventStreamRevertOverride: string;
  private get authBaseUrl(): string {
    return `${this.serverService.currentServer.api}${AUTH_ROUTE}/`;
  }

  private eventstreamFlag = EVENTSTREAM_FLAG;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private jwtService: JwtService,
    private serverService: ServerService,
    private router: Router
  ) {
    this.eventStreamRevertOverride = localStorage.getItem(
      LOCAL_STORAGE_EVENTSTREAM_OVERRIDE
    );
  }

  login(email: string, password: string, baseUrl?: string): Observable<AccessToken> {
    return this.http
      .post<AccessToken>(`${baseUrl}${AUTH_ROUTE}/${AUTH_ROUTE_LOGIN}/`, { email, password })
      .pipe(
        tap((response) => {
          if (
            baseUrl === this.serverService.currentServer.api &&
            response &&
            response.refresh &&
            response.access
          ) {
            this.serverService.tempSetServerData(response, baseUrl);
          }
        })
      )
      .pipe(catchError((error) => throwError(error)));
  }

  logout(): void {
    this.store.dispatch(setUser({ payload: { user: null } }));
    this.store.dispatch(logout());
    localStorage.removeItem(AUTH_TOKEN_KEY);
    this.clearServersData();
    this.closeEventStreams();
  }

  testApiAccess(baseUrl: string): Observable<any> {
    return this.http
      .get<any>(`${baseUrl}${AUTH_ROUTE}/${AUTH_ROUTE_TEST}/`)
      .pipe(catchError((error) => throwError(error)));
  }

  public openEventStreams(): void {
    this.store.dispatch(liveAnomalyAllConnectionsInit());
  }

  public closeEventStreams(): void {
    this.store.dispatch(liveAnomalyCloseAllConnectionsInit());
  }

  public async loginAll(email: string, password: string) {
    let token: AccessToken;
    const servers = this.serverService.storedServers.value;

    if (!servers.length) {
      return;
    }
    const testServerApiObservables = [];
    if (this.eventstreamFlag && !this.eventStreamRevertOverride) {
      for (const server of servers) {
        testServerApiObservables.push(this.testApiAccess(server.api));
      }
    }
    else {
      testServerApiObservables.push(EMPTY);
    }

    return new Promise(resolve => {
      this.login(email, password, servers[0].api).pipe(
      take(1),
        switchMap(loginToken => {
          token = loginToken;
          return forkJoin(testServerApiObservables);
        }),
        catchError((err) => {
          this.clearServersData();
          this.logout();
          return throwError(err);
        }),
        finalize(() => {
          // Get the redirect state upon login
          const redirect = window.history.state?.redirect;
          if (redirect) {
            this.router.navigateByUrl(redirect);
          }
          else {
            this.router.navigate([HOMEPAGE_ROUTE]);
          }
          resolve(true);
        })
      )
      .subscribe({
        error: (err) => {
          console.log(err);
          resolve(false);
        },
        complete: () => {
          this.clearServersData();
          this.setSession(token);
          this.serverService.setServerTokens(token);
          this.openEventStreams();
        }
      });
    });
  }

  init(): void {
    this.isAuthenticated().subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        return;
      }
      this.setSession(this.getToken());
      this.openEventStreams();
    });
  }

  getToken(): AccessToken | null {
    try {
      return JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
    } catch (error) {
      return null;
    }
  }

  getValidToken(url = null): Observable<AccessToken | null> {
    try {
      let token: AccessToken | ServerAuthData = null;

      // if a request url is provided, verify the url against the server data
      if (url) {
        if (url.indexOf(`${AUTH_ROUTE}/${AUTH_ROUTE_TEST}/`) !== -1) {
          return of(this.serverService.tempGetServerData[0]);
        }
        else {
          token = this.getServerData.find((server) => url.indexOf(server.baseUrl) !== -1);
        }
      }
      else {
        token = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
      }

      // If no token, then return null
      if (!token) {
        return of(null);
      }

      if (this.tokenExpired(token)) {
        return this.refreshAccessToken(token);
      } else {
        return of(token);
      }

    } catch (error) {
      return of(null);
    }
  }

  isAuthenticated(): Observable<boolean> {
    return this.getValidToken().pipe(
      map((isTokenValid) =>
        (isTokenValid !== null) &&
        (!this.refreshTokenExpired(isTokenValid)) &&
        (this.serverService.allServersAuthenticated())
      )
    );
  }

  resetPassword(password: string): Observable<AccessToken> {
    return this.http
      .put<AccessToken>(`${this.authBaseUrl}reset-password/`, { password })
      .pipe(catchError((error) => throwError(error)));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<AccessToken> {
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/naming-convention
        .put<AccessToken>(`${this.authBaseUrl}change-password/`, { old_password: oldPassword, new_password: newPassword })
        .pipe(catchError((error) => throwError(error)))
    );
  }

  refreshAccessToken(token: AccessToken | ServerAuthData, url = null): Observable<AccessToken> {
    if (url) {
      return this.http
        .post<AccessToken>(`${(token as ServerAuthData).baseUrl}${AUTH_ROUTE}/${AUTH_ROUTE_REFRESH}/`, token)
        .pipe(tap((response) => this.setSession(response, (token as ServerAuthData).baseUrl)))
        .pipe(catchError((error) => throwError(error)));
    }
    else {
      return this.http
        .post<AccessToken>(`${this.authBaseUrl}${AUTH_ROUTE_REFRESH}/`, token)
        .pipe(tap((response) => this.setSession(response)))
        .pipe(catchError((error) => throwError(error)));
    }
  }

  checkLocalStorageSchemaVersion(localStorageSchemaVersion: string): boolean {
    const storedLocalStorageSchemaVersion = this.getLocalStorageSchemaVersion();
    if (storedLocalStorageSchemaVersion) {
      const localStorageSchemasEqual = localStorageSchemaVersion === storedLocalStorageSchemaVersion;
      if (!localStorageSchemasEqual) {
        this.emptyLocalStorage();
      }
      return localStorageSchemasEqual;
    } else {
      this.setLocalStorageSchemaVersion(localStorageSchemaVersion);
      return true;
    }
  }

  private setSession(token: AccessToken, baseUrl = null): void {
    if (baseUrl) {
      this.setServerToken(token, baseUrl);
    }
    else {
      this.store.dispatch(setUser({ payload: { user: this.getTokenData(token).user } }));
      this.store.dispatch(setRoles({ payload: { roles: this.getTokenData(token).roles } }));
      this.setToken(token);
      this.serverService.setServerTokens(token);
    }
  }

  private tokenExpired(token: AccessToken | ServerAuthData | null): boolean {
    return token ? this.jwtService.isExpired(token.access) : false;
  }

  private refreshTokenExpired(token: AccessToken | null): boolean {
    return token ? this.jwtService.isExpired(token.refresh) : false;
  }

  private setToken(token: AccessToken): void {
    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token));
  }

  private getTokenData(token: AccessToken): TokenContent {
    return tokenSerializer(this.jwtService.decodeToken(token.access));
  }

  private setLocalStorageSchemaVersion(localStorageSchemaVersion: string): void {
    localStorage.setItem(LOCAL_STORAGE_SCHEMA_VERSION, localStorageSchemaVersion);
  }

  private getLocalStorageSchemaVersion(): string {
    return localStorage.getItem(LOCAL_STORAGE_SCHEMA_VERSION);
  }

  private emptyLocalStorage(): void {
    localStorage.clear();
    this.serverService.resetDefaultServers();
  }

  private setServerToken(token: AccessToken, baseUrl: string): void {
    this.serverService.setServerToken(token, baseUrl);
  }

  private get getServerData(): ServerAuthData[] {
    return this.serverService.getServerData;
  }

  private clearServersData(): void {
    this.serverService.clearServersData();
  }
}
