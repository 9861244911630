import { createReducer, on } from '@ngrx/store';

import { Incident } from '@incidents/models/incident.models';
import {
  incidentCreateFail,
  incidentCreateInit,
  incidentCreateSuccess,
  incidentDeleteFail,
  incidentDeleteInit,
  incidentDeleteSuccess,
  incidentFetchFail,
  incidentFetchInit,
  incidentFetchSuccess,
  metricsIncidentsFail,
  metricsIncidentsInit,
  metricsIncidentsSuccess,
  highlightGridIncidentsFail,
  highlightGridIncidentsInit,
  highlightGridIncidentsSuccess,
  incidentsFetchAllFail,
  incidentsFetchAllInit,
  incidentsFetchAllSuccess,
  incidentUpdateFail,
  incidentUpdateInit,
  incidentUpdateSuccess,
} from '@incidents/store/incidents.actions';
import * as moment from 'moment';

export interface IncidentsState {
  entities: Array<Incident>;
  metricIncidents: Array<Incident>;
  highlightGridIncidents: Array<Incident>;
  count: number;
  success: boolean;
  loading: boolean;
  fail: boolean;
}

export const initialIncidentsState: IncidentsState = {
  entities: [],
  metricIncidents: [],
  highlightGridIncidents: [],
  count: 0,
  success: false,
  loading: false,
  fail: false,
};

export const incidentsReducer = createReducer(
  initialIncidentsState,

  // On fetch all
  on(incidentsFetchAllInit, metricsIncidentsInit, highlightGridIncidentsInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(incidentsFetchAllSuccess, (state, { payload }) => {
    const existingIds = state.entities.map((i) => i.id);
    const incidents = [...state.entities, ...payload.entities.filter((e) => !existingIds.includes(e.id))];

    const sortedIncidents = incidents.slice().sort(
      (incidentA, incidentB) => moment(incidentA.started) > moment(incidentB.started) ? -1 : 1);

    return {
      ...state,
      entities: sortedIncidents,
      count: payload.count,
      loading: false,
    };
  }),
  on(
    incidentsFetchAllFail,
    incidentFetchFail,
    incidentCreateFail,
    incidentUpdateFail,
    metricsIncidentsFail,
    highlightGridIncidentsFail,
    (state) => ({
      ...state,
      loading: false,
      fail: true,
    })
  ),
  on(metricsIncidentsSuccess, (state, { payload }) => ({
      ...state,
      metricIncidents: payload.entities,
      loading: false,
  })),
  on(highlightGridIncidentsSuccess, (state, { payload }) => ({
    ...state,
    highlightGridIncidents: payload.entities,
    loading: false,
  })),

  // On fetch single
  on(incidentFetchInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(incidentFetchSuccess, (state, { payload }) => {
    const existingIds = state.entities.map((i) => i.id);
    const incidents = existingIds.includes(payload.id) ? [...state.entities] : [...state.entities, payload];

    const sortedIncidents = incidents.slice().sort(
      (incidentA, incidentB) => moment(incidentA.started) > moment(incidentB.started) ? -1 : 1);

    return {
      ...state,
      entities: sortedIncidents,
      loading: false,
    };
  }),

  // On create incident
  on(incidentCreateInit, (state, { payload }) => ({
    ...state,
    loading: true,
  })),
  on(incidentCreateSuccess, (state, { payload }) => ({
    ...state,
    entities: [...state.entities, payload],
    loading: false,
  })),

  // On update incident
  on(incidentUpdateInit, (state, { payload }) => ({
    ...state,
    loading: true,
  })),
  on(incidentUpdateSuccess, (state, { payload }) => {
    const incidents = state.entities.map((incident) =>
      incident.id === payload.id
        ? {
            ...incident,
            ...payload,
          }
        : incident
    );

    const sortedIncidents = incidents.slice().sort(
      (incidentA, incidentB) => moment(incidentA.started) > moment(incidentB.started) ? -1 : 1);

    return {
      ...state,
      entities: [...sortedIncidents],
      loading: false,
    };
  }),

  // On delete incident
  on(incidentDeleteInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(incidentDeleteSuccess, (state, { payload }) => ({
      ...state,
      entities: [...state.entities.filter((f) => f.id !== payload.id)],
      loading: false,
      success: true,
    })),
  on(incidentDeleteFail, (state) => ({
      ...state,
      loading: false,
      fail: true,
      success: false,
    }))
);
