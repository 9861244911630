import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CLIENTS_STORE_ID } from '@clients/constants/clients.constants';
import { ClientsState } from '@clients/store/clients.reducers';

const selectPaginatedClients = createFeatureSelector<ClientsState>(CLIENTS_STORE_ID);
export const selectPaginatedClientsCount = createSelector(selectPaginatedClients, (store) => store.count);
export const selectPaginatedClientsEntities = createSelector(selectPaginatedClients, (store) => store.entities);
export const selectPaginatedClientsLoading = createSelector(selectPaginatedClients, (store) => store.loading);
export const selectPaginatedClientsFail = createSelector(selectPaginatedClients, (store) => store.fail);
