import { createAction, props } from '@ngrx/store';
import { Incident, IncidentPagination } from '@incidents/models/incident.models';

const INCIDENT_PREFIX = '[INCIDENT]';
const METRICS_INCIDENT_PREFIX = '[INCIDENT METRICS PREFIX]';
const HIGHLIGHT_GRID_PREFIX = '[INCIDENT HIGHLIGHT GRID PREFIX]';

// Fetch metric incidents
export const METRICS_INCIDENTS_INIT = `${METRICS_INCIDENT_PREFIX} Fetch metric incidents Init`;
export const METRICS_INCIDENTS_SUCCESS = `${METRICS_INCIDENT_PREFIX} Fetch metric incidents Success`;
export const METRICS_INCIDENTS_FAIL = `${METRICS_INCIDENT_PREFIX} Fetch metric incidents Fail`;

// Fetch highlight incidents
export const HIGHLIGHT_GRID_INCIDENTS_INIT = `${HIGHLIGHT_GRID_PREFIX} Fetch metric incidents Init`;
export const HIGHLIGHT_GRID_INCIDENTS_SUCCESS = `${HIGHLIGHT_GRID_PREFIX} Fetch metric incidents Success`;
export const HIGHLIGHT_GRID_INCIDENTS_FAIL = `${HIGHLIGHT_GRID_PREFIX} Fetch metric incidents Fail`;

// Fetch all incidents
export const INCIDENTS_FETCH_ALL_INIT = `${INCIDENT_PREFIX} Fetch all incidents Init`;
export const INCIDENTS_FETCH_ALL_SUCCESS = `${INCIDENT_PREFIX} Fetch all incidents Success`;
export const INCIDENTS_FETCH_ALL_FAIL = `${INCIDENT_PREFIX} Fetch all incidents Fail`;

// Fetch one incident
export const INCIDENT_FETCH_INIT = `${INCIDENT_PREFIX} Fetch one incident Init`;
export const INCIDENT_FETCH_SUCCESS = `${INCIDENT_PREFIX} Fetch one incident Success`;
export const INCIDENT_FETCH_FAIL = `${INCIDENT_PREFIX} Fetch one incident Fail`;

// Create new incident
export const INCIDENT_CREATE_INIT = `${INCIDENT_PREFIX} Create incident Init`;
export const INCIDENT_CREATE_SUCCESS = `${INCIDENT_PREFIX} Create incident Success`;
export const INCIDENT_CREATE_FAIL = `${INCIDENT_PREFIX} Create incident Fail`;

// Update existing incident
export const INCIDENT_UPDATE_INIT = `${INCIDENT_PREFIX} Update incident Init`;
export const INCIDENT_UPDATE_SUCCESS = `${INCIDENT_PREFIX} Update incident Success`;
export const INCIDENT_UPDATE_FAIL = `${INCIDENT_PREFIX} Update incident Fail`;

// Delete existing incident
export const INCIDENT_DELETE_INIT = `${INCIDENT_PREFIX} Delete incident Init`;
export const INCIDENT_DELETE_SUCCESS = `${INCIDENT_PREFIX} Delete incident Success`;
export const INCIDENT_DELETE_FAIL = `${INCIDENT_PREFIX} Delete incident Fail`;

// Fetch metric incidents
export const metricsIncidentsInit = createAction(METRICS_INCIDENTS_INIT);
export const metricsIncidentsSuccess = createAction(
  METRICS_INCIDENTS_SUCCESS,
  props<{ payload: IncidentPagination }>()
);
export const metricsIncidentsFail = createAction(METRICS_INCIDENTS_FAIL, props<Error>());

// Fetch highlight incidents
export const highlightGridIncidentsInit = createAction(HIGHLIGHT_GRID_INCIDENTS_INIT);
export const highlightGridIncidentsSuccess = createAction(
  HIGHLIGHT_GRID_INCIDENTS_SUCCESS,
  props<{ payload: IncidentPagination }>()
);
export const highlightGridIncidentsFail = createAction(HIGHLIGHT_GRID_INCIDENTS_FAIL, props<Error>());

// Fetch all incidents
export const incidentsFetchAllInit = createAction(INCIDENTS_FETCH_ALL_INIT);
export const incidentsFetchAllSuccess = createAction(
  INCIDENTS_FETCH_ALL_SUCCESS,
  props<{ payload: IncidentPagination }>()
);
export const incidentsFetchAllFail = createAction(INCIDENTS_FETCH_ALL_FAIL, props<Error>());

// Fetch one incident
export const incidentFetchInit = createAction(INCIDENT_FETCH_INIT, props<{ id: number }>());
export const incidentFetchSuccess = createAction(INCIDENT_FETCH_SUCCESS, props<{ payload: Incident }>());
export const incidentFetchFail = createAction(INCIDENT_FETCH_FAIL, props<Error>());

// Create new incident
export const incidentCreateInit = createAction(INCIDENT_CREATE_INIT, props<{ payload: Incident }>());
export const incidentCreateSuccess = createAction(INCIDENT_CREATE_SUCCESS, props<{ payload: Incident }>());
export const incidentCreateFail = createAction(INCIDENT_CREATE_FAIL, props<Error>());

// Update incident
export const incidentUpdateInit = createAction(INCIDENT_UPDATE_INIT, props<{ payload: Incident }>());
export const incidentUpdateSuccess = createAction(INCIDENT_UPDATE_SUCCESS, props<{ payload: Incident }>());
export const incidentUpdateFail = createAction(INCIDENT_UPDATE_FAIL, props<Error>());

// Delete incident
export const incidentDeleteInit = createAction(INCIDENT_DELETE_INIT, props<{ payload: Incident }>());
export const incidentDeleteSuccess = createAction(INCIDENT_DELETE_SUCCESS, props<{ payload: Incident }>());
export const incidentDeleteFail = createAction(INCIDENT_DELETE_FAIL, props<Error>());
