export const HOMEPAGE = '';
export const CAMERAS = 'cameras';
export const HIGHLIGHT_GRID = 'highlight-grid';
export const LIVE_WALL = 'live-wall';
export const INCIDENTS = 'incidents';
export const ANALYTICS = 'analytics';
export const AUTH = 'auth';
export const USERS = 'users';
export const CLIENTS = 'clients';
export const SERVER = 'servers';

// Logging toggle
export const LOCAL_STORAGE_EXTEND_LOGGING = 'icetana-logging';

// Camera feature state key
export const REDUCER_NOTIFICATION_ID = 'notifications';
export const REDUCER_USER_SETTINGS_ID = 'userSettings';
export const REDUCER_UI_ELEMENTS_ID = 'uiElements';

// Web Socket and EventStream
export const RETRY_DELAY = 5000;
export const SSE_STREAM_ROUTE = 'eventstream';
export const API_REGEX = new RegExp(/\/\/([^/]*)\/api/);
export const SERVER_REGEX = new RegExp(/\/\/([^\/]*)\//);
export const EVENTSTREAM_FLAG = true;
export const LOCAL_STORAGE_EVENTSTREAM_OVERRIDE = 'icetana-eventstream-revert';
export const TEST_EVENTSTREAM_TIMEOUT = 3000;
export const EARLIEST_LIVE_EVENT_REQUEST_INIT = 24;
export const SSE_RETRY_ERROR_COUNT_LIMIT = 2; // simple error counter

// Anomaly Diagnostics
export const ANOMALY_DIAGNOSTICS_PATH = 'logs/icetana_algorithm';
export const ANOMALY_DIAGNOSTICS_RETRIES = 3;
export const ANOMALY_DIAGNOSTICS_RETRY_DELAY = 1000;
export const FEATURE_FLAG_ANOMALY_DIAGNOSTICS = 'event-diagnostics';

// Pagination
export const DEFAULT_PAGE_LIMIT = 40;
export const DEFAULT_PAGE_OFFSET = 0;

// Togglable Features
export const FEATURE_FLAG_NX_WITNESS = 'nx-witness';
export const NX_WITNESS_RTSP_REGEX = new RegExp(/rtsp:\/\/(.*):7001\/(.*)/); //rtsp://servername:7001/
