/* eslint-disable @typescript-eslint/naming-convention */
export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  forceResetPassword: boolean;
  role: Role;
}

export interface UserAPI {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  force_reset_password: boolean;
  role: Role;
}

export const userSerializer = (data: UserAPI): User => {
  const { first_name, last_name, force_reset_password, ...other } = data;
  return {
    firstName: first_name,
    lastName: last_name,
    forceResetPassword: force_reset_password,
    ...other,
  };
};

export const userDeserializer = (data: User): UserAPI => {
  const { firstName, lastName, forceResetPassword, ...other } = data;
  return {
    first_name: firstName,
    last_name: lastName,
    force_reset_password: forceResetPassword,
    ...other,
  };
};

export interface AccessToken {
  refresh: string;
  access: string;
}

type TokenType = 'access' | 'refresh';

export interface TokenContent {
  exp: number;
  jti: string;
  tokenType: TokenType;
  user: User;
  userId: number;
  roles: Role[];
}

export interface TokenContentAPI {
  exp: number;
  jti: string;
  token_type: TokenType;
  user: UserAPI;
  user_id: number;
  roles: Role[];
}

export const tokenSerializer = (rawTokenData: TokenContentAPI): TokenContent => {
  // const data: TokenContentAPI = jwt_decode(token.access);
  const { token_type, user, user_id, ...other } = rawTokenData;
  return {
    tokenType: token_type,
    user: userSerializer(user),
    userId: user_id,
    ...other,
  };
};

export interface Role {
  id: number;
  order: number;
  name: string;
  description: string;
}
