/* eslint-disable @typescript-eslint/naming-convention */
export enum StatusIndicator {
  OFF = 'OFF',
  WARN = 'WARN',
  OK = 'OK',
  NONE = 'NONE',
}

export enum HLSVideoStreamStatus {
  OK = 'OK',
  READY = 'ready',
  STARTED = 'started',
  STOPPED = 'stopped',
}
