import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpResponseData } from '@core/interceptors/http-response';

import {
  Camera,
  cameraSerializer,
  CameraAPI,
  cameraDeserializer,
  CameraPagination,
  CameraAPIPagination,
  cameraPaginationSerializer,
} from '@cameras/models/cameras/cameras.models';
import { DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_OFFSET, SERVER_REGEX } from '@core/constants/app.constants';
import { Anomaly, AnomalyAPI, anomalySerializer } from '@core/models/anomaly/anomaly.model';
import { ServerService } from '@server/services/server.service';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  private get cameraBaseUrl(): string {
    return `${this.serverService.currentServer.api}cameras/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  fetchAll(pageLimit: number, pageOffset: number): Observable<CameraPagination> {
    return this.http
      .get<CameraAPIPagination>(`${this.cameraBaseUrl}?limit=${pageLimit}&offset=${pageOffset}`)
      .pipe(map((response) => cameraPaginationSerializer(response) as CameraPagination))
      .pipe(catchError((error) => throwError(error)));
  }

  fetchAllUnpaginated(): Observable<CameraPagination> {
    return this.http
      .get<CameraAPIPagination>(`${this.cameraBaseUrl}?limit=10000`)
      .pipe(map((response) => cameraPaginationSerializer(response) as CameraPagination))
      .pipe(catchError((error) => throwError(error)));
  }

  fetchCamera(id: number): Observable<Camera> {
    const endpoint = `${this.cameraBaseUrl}${id}/`;

    return this.http
      .get<CameraAPI>(endpoint)
      .pipe(map((camera) => cameraSerializer(camera) as Camera))
      .pipe(catchError((error) => throwError(error)));
  }

  createCamera(cameraPayload: Camera): Observable<Camera> {
    return this.http
      .post<CameraAPI>(this.cameraBaseUrl, cameraDeserializer(cameraPayload))
      .pipe(map((camera) => cameraSerializer(camera) as Camera))
      .pipe(catchError((error) => throwError(error)));
  }

  updateCamera(cameraPayload: Camera): Observable<Camera> {
    const { id, ...payload } = cameraPayload;
    const endpoint = `${this.cameraBaseUrl}${id}/`;

    return this.http
      .patch<CameraAPI>(endpoint, cameraDeserializer(payload))
      .pipe(map((camera) => cameraSerializer(camera) as Camera))
      .pipe(catchError((error) => throwError(error)));
  }

  deleteCamera(camera: Camera): Observable<Camera> {
    const endpoint = `${this.cameraBaseUrl}${camera.id}/`;

    return this.http
      .delete<any>(endpoint)
      .pipe(map(() => camera))
      .pipe(catchError((error) => throwError(error)));
  }

  startCamera(cameraPayload: Camera): Observable<Camera> {
    const endpoint = `${this.cameraBaseUrl}${cameraPayload.id}/start/`;

    return this.http
      .post<CameraAPI>(endpoint, null)
      .pipe(map((session) => cameraSerializer(session)))
      .pipe(catchError((error) => throwError(error)));
  }

  stopCamera(cameraPayload: Camera): Observable<any> {
    const endpoint = `${this.cameraBaseUrl}${cameraPayload.id}/stop/`;

    // return this.http
    //   .post<CameraAPI>(endpoint, null)
    //   .pipe(map((camera) => cameraSerializer(camera) as Camera))
    //   .pipe(catchError((error) => throwError(error)));
    return this.http.post(endpoint, null).pipe((res) => res);
  }

  fetchCameraAnomalies(
    cameraId: number,
    pageLimit: number = DEFAULT_PAGE_LIMIT,
    pageOffset: number = DEFAULT_PAGE_OFFSET
  ): Observable<Anomaly[]> {
    const endpoint = `${this.cameraBaseUrl}${cameraId}/anomalies/?limit=${pageLimit}&offset=${pageOffset}`;

    return this.http
      .get<HttpResponseData<AnomalyAPI[]>>(endpoint)
      .pipe(map((anomalies) => anomalies.results))
      .pipe(map((anomalies) => anomalies.map((anomaly) => anomalySerializer(anomaly, this.extractServerBaseUri(endpoint)) as Anomaly)))
      .pipe(catchError((error) => throwError(error)));
  }

  extractServerBaseUri(url: string): string {
    const serverProtocol = url.split('//')[0];
    const regexMatch = url.match(SERVER_REGEX);
    return `${serverProtocol}//${regexMatch[1]}`;
  }
}
