/* eslint-disable @typescript-eslint/naming-convention */
import { Zone, Client, Site } from '@clients/models/client.models';

export enum CameraStatus {
  INITIALIZING = 'INITIALIZING',
  STARTING = 'STARTING',
  STARTED = 'STARTED',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  FAILED = 'FAILED',
}

export class Camera {
  id?: number;
  guid?: string;
  name: string;
  uri: string;
  hlsUri?: string;
  description?: string;
  username?: string;
  password?: string;
  started?: string;
  stopped?: string;
  created?: string;
  updated?: string;
  editable?: boolean;
  status?: CameraStatus;
  reasonOfFailure?: string;
  site?: number;
  zones?: Zone[];
  timezone?: string;
}

export class CameraAPI {
  id: number;
  guid: string;
  name: string;
  description: string;
  uri: string;
  hls_uri?: string;
  username?: string;
  password?: string;
  started?: string;
  stopped?: string;
  created?: string;
  updated?: string;
  editable?: boolean;
  status?: CameraStatus;
  reason_of_failure?: string;
  site?: number;
  zones?: Zone[];
  timezone?: string;
}

export class CameraTableRow extends Camera {
  siteName?: string;
}

export class CameraPagination {
  count: number;
  entities: Camera[];
}

export class CameraAPIPagination {
  count: number;
  results: CameraAPI[];
}

export const cameraSerializer = ({
  uri,
  hls_uri,
  reason_of_failure,
  ...object
}: CameraAPI) =>
  ({
    uri,
    hlsUri: hls_uri,
    reasonOfFailure: reason_of_failure,
    ...object,
  } as Camera);

export const cameraDeserializer = ({
  uri,
  hlsUri,
  reasonOfFailure,
  ...object
}: Camera) =>
  ({
    uri,
    hls_uri: hlsUri,
    reason_of_failure: reasonOfFailure,
    ...object,
  } as CameraAPI);

export const cameraPaginationSerializer = ({ count, results }: CameraAPIPagination) => ({
  count,
  entities: results ? results.map((camera) => cameraSerializer(camera)) : null,
});

export const cameraTableSerializer = (cameras: Camera[], clients: Client[] ) => {
  // extract site list from all clients
  let siteList: Site[] = [];
  clients.forEach((client) => {
    siteList = siteList.concat(client.sites);
  });

  const cameraTableBuffer: CameraTableRow[] = [];
  cameras.forEach((camera) => {
    let matchedSiteObj = null;
    if (camera.site) {
      matchedSiteObj = siteList.find((site) => (site.id === camera.site));
    }
    cameraTableBuffer.push({
      ...camera,
      siteName: matchedSiteObj ? matchedSiteObj.name : null
    });
  });
  return cameraTableBuffer;
};

export const cameraTableRowDeserializer = ({
  siteName,
  ...object
}: CameraTableRow) =>
  ({
    ...object,
  } as Camera);
