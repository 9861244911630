/* eslint-disable @typescript-eslint/naming-convention */

export const ANOMALY_MODELS = {
  LOF: {
    label: 'Unusual Location',
    icon: 'my_location',
  },
  UnusualCount: {
    label: 'Unusual Count',
    icon: 'library_add',
  },
  UnusualTOD: {
    label: 'Unusual Time of Day',
    icon: 'schedule',
  },
  AspectRatioFilter: {
    label: 'Trip and Fall',
    icon: 'info',
  },
};

export const ANOMALY_LABELS = {
  person: {
    label: 'Person',
    icon: 'directions_walk',
    key: 'person',
    hidden: false,
    parentLabel: null,
  },
  bicycle: {
    label: 'Bike',
    icon: 'directions_bike',
    key: 'bicycle',
    hidden: false,
    parentLabel: null,
  },
  motor_vehicle: {
    label: 'Vehicle',
    icon: 'commute',
    key: 'motor_vehicle',
    hidden: false,
    parentLabel: null,
  },
  car: {
    label: 'Car',
    icon: 'directions_car',
    key: 'car',
    hidden: true,
    parentLabel: 'motor_vehicle',
  },
  truck: {
    label: 'Truck',
    icon: 'local_shipping',
    key: 'truck',
    hidden: true,
    parentLabel: 'motor_vehicle',
  },
  bus: {
    label: 'Bus',
    icon: 'directions_bus',
    key: 'bus',
    hidden: true,
    parentLabel: 'motor_vehicle',
  },
  motorcycle: {
    label: 'Motorcycle',
    icon: 'sports_motorsports',
    key: 'motorcycle',
    hidden: true,
    parentLabel: 'motor_vehicle',
  },
};

export const GET_ANOMALY_LABEL = (anomaly: any): {
  label: string;
  icon: string;
  key: string;
  hidden: boolean;
  parentLabel: string;
} => {
  let anomalyLabel = '';
  if (anomaly.anomalyDiagnostic?.usedCategoryManager) {
    anomalyLabel = anomaly.anomalyDiagnostic?.categoryLabel || '';
  }
  else {
    anomalyLabel = anomaly.anomalyDiagnostic?.classLabel || '';
  }

  return ANOMALY_LABELS[anomalyLabel];
};
