import { createFeatureSelector, createSelector } from '@ngrx/store';

import { REDUCER_CAMERAS_ID } from '@cameras/constants/cameras.constants';
import { CameraState } from '@cameras/store/cameras/cameras.reducer';

export const selectCameras = createFeatureSelector<CameraState>(REDUCER_CAMERAS_ID);
export const selectCamerasCount = createSelector(selectCameras, (cameras) => cameras.count);
export const selectCamerasPageParams= createSelector(selectCameras, (cameras) => ({
  pageLimit: cameras.cameraPageLimit, pageOffset: cameras.cameraPageOffset
}));
export const selectCamerasEntities = createSelector(selectCameras, (cameras) => cameras.entities);
export const selectCamerasLoading = createSelector(selectCameras, (cameras) => cameras.loading);
export const selectCamerasFail = createSelector(selectCameras, (cameras) => cameras.fail);

export const selectCamera = (id: number) => createSelector(selectCameras, (cameras) => cameras.selected);
export const selectCameraByGUID = (guid: string) =>
  createSelector(selectCamerasEntities, (cameras) => cameras.find((c) => c.guid === guid));

export const cameraSelectors = {
  selectCamera,
};
