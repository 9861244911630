import { createAction, props } from '@ngrx/store';

const LIVE_ANOMALY_PREFIX = '[LIVE ANOMALY]';

// Live Anomalies
export const LIVE_ANOMALY_ALL_CONNECTIONS_INIT = `${LIVE_ANOMALY_PREFIX} All connections Init`;
export const LIVE_ANOMALY_ALL_CONNECTIONS_FINISHED = `${LIVE_ANOMALY_PREFIX} All connections Finished`;
export const LIVE_ANOMALY_CLOSE_ALL_CONNECTIONS_INIT = `${LIVE_ANOMALY_PREFIX} Close All connections Init`;
export const LIVE_ANOMALY_CLOSE_ALL_CONNECTIONS_FINISHED = `${LIVE_ANOMALY_PREFIX} Close All connections Finished`;
export const LIVE_ANOMALY_CONNECTION_INIT = `${LIVE_ANOMALY_PREFIX} Connection Init`;
export const LIVE_ANOMALY_CONNECTION_SUCCESS = `${LIVE_ANOMALY_PREFIX} Connection Success`;
export const LIVE_ANOMALY_CONNECTION_FAIL = `${LIVE_ANOMALY_PREFIX} Connection Fail`;
export const LIVE_ANOMALY_CLOSE_CONNECTION_INIT = `${LIVE_ANOMALY_PREFIX} Close connection Init`;
export const LIVE_ANOMALY_CLOSE_CONNECTION_SUCCESS = `${LIVE_ANOMALY_PREFIX} Close connection Success`;
export const LIVE_ANOMALY_RECEIVE_TTL_MESSAGE = `${LIVE_ANOMALY_PREFIX} Message receive TTL`;

// Live Anomalies create all connections
export const liveAnomalyAllConnectionsInit = createAction(LIVE_ANOMALY_ALL_CONNECTIONS_INIT);
export const liveAnomalyAllConnectionsFinished = createAction(LIVE_ANOMALY_ALL_CONNECTIONS_FINISHED);
// Live Anomalies close all connections
export const liveAnomalyCloseAllConnectionsInit = createAction(LIVE_ANOMALY_CLOSE_ALL_CONNECTIONS_INIT);
export const liveAnomalyCloseAllConnectionsFinished = createAction(LIVE_ANOMALY_CLOSE_ALL_CONNECTIONS_FINISHED);
// Live Anomalies create individual connections
export const liveAnomalyConnectionInit = createAction(LIVE_ANOMALY_CONNECTION_INIT, props<{ payload: { url: string } }>());
export const liveAnomalyConnectionSuccess = createAction(
  LIVE_ANOMALY_CONNECTION_SUCCESS, props<{ payload: { url: string } }>()
);
export const liveAnomalyConnectionFail = createAction(LIVE_ANOMALY_CONNECTION_FAIL, props<{ payload: { url: string; error: Error } }>());
// Live Anomalies close individual connections
export const liveAnomalyCloseConnectionInit = createAction(LIVE_ANOMALY_CLOSE_CONNECTION_INIT, props<{ payload: { url: string } }>());
export const liveAnomalyCloseConnectionSuccess = createAction(LIVE_ANOMALY_CLOSE_CONNECTION_SUCCESS, props<{ payload: { url: string } }>());
// Live Anomalies update eventstream TTL values
export const liveAnomalyReceiveMessage = createAction(
  LIVE_ANOMALY_RECEIVE_TTL_MESSAGE, props<{ payload: { url: string } }>()
);
