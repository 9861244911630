/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';

import { createEffect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { ClientService } from '@clients/services/client.service';
import { ClientPagination, Client } from '@clients/models/client.models';

import {
  CLIENTS_FETCH_INIT,
  CLIENT_DELETE_INIT,
  clientsFetchFail,
  clientsFetchSuccess,
  clientDeleteSuccess,
  CLIENT_CREATE_INIT,
  clientCreateSuccess,
  CLIENT_UPDATE_INIT,
  clientUpdateSuccess,
} from '@clients/store/clients.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

interface FetchClientsAction {
  readonly type: string;
  payload: {
    pageLimit: number;
    pageOffset: number;
  };
}

interface CUDClientAction {
  readonly type: string;
  payload: {
    client: Client;
  };
}

@Injectable()
export class ClientsEffects {
  constructor(private actions$: Actions, private clientService: ClientService, private snackBar: MatSnackBar) {}

  fetchClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLIENTS_FETCH_INIT),
      mergeMap((action: FetchClientsAction) =>
        this.clientService.fetchAll(action.payload.pageLimit, action.payload.pageOffset).pipe(
          map((clientPagination: ClientPagination) => clientsFetchSuccess({ payload: clientPagination })),
          catchError((error: Error) => of(clientsFetchFail(error)))
        )
      )
    )
  );

  deleteClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLIENT_DELETE_INIT),
      mergeMap((action: CUDClientAction) =>
        this.clientService.delete(action.payload.client).pipe(
          map((client: Client) => {
            this.snackBarMessages(`Client ${client.name} deleted`, 'snackbar-success-background');
            return clientDeleteSuccess({ payload: { client } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(`Failed: ${eMessage ? eMessage : 'Client Delete'}`, 'snackbar-error-background');
            return of(clientsFetchFail(error));
          })
        )
      )
    )
  );

  createClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLIENT_CREATE_INIT),
      mergeMap((action: CUDClientAction) =>
        this.clientService.create(action.payload.client).pipe(
          map((client: Client) => {
            this.snackBarMessages(`Client ${client.name} created`, 'snackbar-success-background');
            return clientCreateSuccess({ payload: { client } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(`Failed: ${eMessage ? eMessage : 'Client create'}`, 'snackbar-error-background');
            return of(clientsFetchFail(error));
          })
        )
      )
    )
  );

  updateClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLIENT_UPDATE_INIT),
      mergeMap((action: CUDClientAction) =>
        this.clientService.update(action.payload.client).pipe(
          map((client: Client) => {
            this.snackBarMessages(`Client ${client.name} updated`, 'snackbar-success-background');
            return clientUpdateSuccess({ payload: { client } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(`Failed: ${eMessage ? eMessage : 'Client update'}`, 'snackbar-error-background');
            return of(clientsFetchFail(error));
          })
        )
      )
    )
  );

  private errorMessage(error: Error): string {
    try {
      return JSON.stringify((error as HttpErrorResponse).error);
    } catch {
      return null;
    }
  }

  private snackBarMessages(message: string, type: string): void {
    this.snackBar.open(message, null, {
      duration: 5000,
      panelClass: [type],
    });
  }
}
