import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Feature } from '@permissions/models/permissions.models';
import { PermissionsService } from '@permissions/services/permissions.service';

@Directive({ selector: '[icetanaHasAccessTo]' })
export class HasAccessToDirective {
  @Input() set icetanaHasAccessTo(feature: Feature) {
    if (this.permissionsService.hasPermissionFor(feature)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService
  ) {}
}
