import { createReducer, on } from '@ngrx/store';

import { IncidentStatus } from '@incidents/models/incident.models';
import { statusesFetchAllFail, statusesFetchAllInit, statusesFetchAllSuccess } from '@incidents/store/statuses.actions';

export interface IncidentStatusesState {
  entities: Array<IncidentStatus>;
  success: boolean;
  loading: boolean;
  fail: boolean;
}

export const initialStatusesState: IncidentStatusesState = {
  entities: null,
  success: false,
  loading: false,
  fail: false,
};

export const incidentStatusesReducer = createReducer(
  initialStatusesState,

  // On fetch all
  on(statusesFetchAllInit, (state) => ({
      ...state,
      loading: true,
    })),
  on(statusesFetchAllSuccess, (state, { payload }) => ({
      ...state,
      entities: payload,
      loading: false,
    })),
  on(statusesFetchAllFail, (state) => ({
      ...state,
      loading: false,
      fail: true,
    }))
);
