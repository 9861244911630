import { AccessLevel, ProtectedFeature } from '@permissions/models/permissions.models';

export const protectedClients: ProtectedFeature[] = [
  {
    feature: 'create_client',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'update_client',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
  {
    feature: 'delete_client',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'list_clients',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
];
