import { createAction, props } from '@ngrx/store';

import { User, Role } from '@auth/models/auth.models';

const AUTH_PREFIX = '[AUTH]';

export const AUTH_SET_USER = `${AUTH_PREFIX} Set authenticated user`;
export const AUTH_SET_ROLES = `${AUTH_PREFIX} Set roles list`;
export const AUTH_LOGOUT = `${AUTH_PREFIX} Logout`;

export const setUser = createAction(AUTH_SET_USER, props<{ payload: { user: User } }>());
export const setRoles = createAction(AUTH_SET_ROLES, props<{ payload: { roles: Role[] } }>());
export const logout = createAction(AUTH_LOGOUT);
