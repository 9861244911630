<!-- Filter -->
<mat-form-field *ngIf="showFilter" [style.visibility]="dataRendering ? 'hidden': 'visible'">
  <mat-label>Filter</mat-label>
  <input autocomplete="off" matInput (keyup)="applyFilter($event)" #input />
</mat-form-field>

<div>
  <!-- Table -->
  <table
    mat-table matSort multiTemplateDataRows
    [dataSource]="dataSource"
    [style.visibility]="dataRendering ? 'hidden': 'visible'"
  >
    <ng-container matColumnDef="{{ column.name }}" *ngFor="let column of tableColumns; let i = index">
      <ng-container *ngIf="column.type === 'string'; else unSortableHeader">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ column.name }}">{{ column.displayName }}</th>
      </ng-container>

      <!-- Un-Sortable header section, TODO: This can be improved further-->
      <ng-template #unSortableHeader>
        <th mat-header-cell *matHeaderCellDef>
          <ng-container *ngIf="column.displayName; else headerTemplate">
            {{ column.displayName }}
          </ng-container>
          <ng-template #headerTemplate>
            <ng-container *ngTemplateOutlet="headerTemplateRef[column.type]"></ng-container>
          </ng-template>
        </th>
      </ng-template>

      <ng-container *ngIf="column.type === 'number'; else actionsTemplate">
        <td mat-cell *matCellDef="let element">
          <span [matTooltip]="element[column.name]">{{ element[column.name] }}</span>
        </td>
      </ng-container>

      <ng-container *ngIf="column.type === 'string'; else actionsTemplate">
        <td mat-cell *matCellDef="let element">
          <span [matTooltip]="element[column.name]">{{ element[column.name] || '-' | slice: 0:30 }}</span>
        </td>
      </ng-container>

      <!-- actions -->
      <ng-template #actionsTemplate>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngTemplateOutlet="customTemplateRef[column.type]; context: { data: element }"></ng-container>
        </td>
      </ng-template>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" *ngIf="expandContentTemplateRef">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div
          class="element-detail"
          *ngIf="element === expandedElement"
          [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
        >
          <ng-container *ngTemplateOutlet="expandContentTemplateRef; context: { data: element }"></ng-container>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column for Anomalies -->
    <ng-container matColumnDef="expandedAnomal">
      <td mat-cell *matCellDef="let cell" [attr.colspan]="displayedColumns.length">
        <div *ngIf="cell.guid === expandedAnomalies?.camera?.guid">
          <ng-container *ngTemplateOutlet="expandedAnomalies?.template; context: { data: cell }"></ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
      (click)="onRowClick(expandedElement)"
    ></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedAnomal']" class="detail-row"></tr>
    <tr class="mat-row italic-font" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data to show.
      </td>
    </tr>
  </table>

  <!-- Pagination -->
  <mat-paginator
    *ngIf="paginationEnabled"
    [style.visibility]="dataRendering ? 'hidden': 'visible'"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [length]="paginatorPageLength"
    (page)="handlePageEvent($event)"
    [pageIndex]="pageIndex"
  ></mat-paginator>
</div>
