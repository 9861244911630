import { createReducer, on } from '@ngrx/store';

import { IncidentCategory } from '@incidents/models/incident.models';
import {
  categoryCreateFail,
  categoryCreateInit,
  categoryCreateSuccess,
  categoryDeleteFail,
  categoryDeleteInit,
  categoryDeleteSuccess,
  categoryFetchFail,
  categoryFetchInit,
  categoryFetchSuccess,
  categoriesFetchAllFail,
  categoriesFetchAllInit,
  categoriesFetchAllSuccess,
  categoryUpdateFail,
  categoryUpdateInit,
  categoryUpdateSuccess,
} from '@incidents/store/categories.actions';

export interface IncidentCategoriesState {
  entities: Array<IncidentCategory>;
  success: boolean;
  loading: boolean;
  fail: boolean;
}

export const initialCategoriesState: IncidentCategoriesState = {
  entities: null,
  success: false,
  loading: false,
  fail: false,
};

export const incidentCategoriesReducer = createReducer(
  initialCategoriesState,

  // On fetch all
  on(categoriesFetchAllInit, (state) => ({
      ...state,
      loading: true,
    })),
  on(categoriesFetchAllSuccess, (state, { payload }) => ({
      ...state,
      entities: payload,
      loading: false,
    })),
  on(categoriesFetchAllFail, categoryFetchFail, categoryCreateFail, categoryUpdateFail, (state) => ({
      ...state,
      loading: false,
      fail: true,
    })),

  // On fetch single
  on(categoryFetchInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(categoryFetchSuccess, (state, { payload }) => ({
    ...state,
    entities: [...state.entities, payload],
    loading: false,
  })),

  // On create category
  on(categoryCreateInit, (state, { payload }) => ({
    ...state,
    loading: true,
  })),
  on(categoryCreateSuccess, (state, { payload }) => ({
    ...state,
    entities: [...state.entities, payload],
    loading: false,
  })),

  // On update category
  on(categoryUpdateInit, (state, { payload }) => ({
    ...state,
    loading: true,
  })),
  on(categoryUpdateSuccess, (state, { payload }) => {
    const categories = state.entities.map((category) =>
      category.id === payload.id
        ? {
            ...category,
            ...payload,
          }
        : category
    );

    return {
      ...state,
      entities: [...categories],
      loading: false,
    };
  }),

  // On delete category
  on(categoryDeleteInit, (state) => ({
    ...state,
  })),
  on(categoryDeleteSuccess, (state, { payload }) => ({
      ...state,
      entities: [...state.entities.filter((f) => f.id !== payload.id)],
      loading: false,
      success: true,
    })),
  on(categoryDeleteFail, (state) => ({
      ...state,
      loading: false,
      fail: true,
      success: false,
    }))
);
