/* eslint-disable @typescript-eslint/naming-convention */
// Highlight Grid feature module routes
export const HIGHLIGHT_GRID_HOMEPAGE = '';

export const HIGHLIGHT_GRID_DURATION = 24;
export const DEFAULT_GRID_SIZE = 9;
export const DEFAULT_GRID_WIDTH = 3;
export const DEFAULT_GRID_HEIGHT = 3;
export const MAXIMUM_GRID_WIDTH = 10;
export const MAXIMUM_GRID_HEIGHT = 10;

export interface Playlist {
  content: string[];
  anomalyIdList: number[];
  cameraNames: string[];
  position: number;
  finished: number;
}

export interface Speed {
  options: string[];
  selected: number;
}

/** This are standard playback speed configuration Used for highlight grid */
export enum PlaybackSpeedOption {
  Normal = 1.0,
  x2 = 2.0,
  x4 = 4.0,
  x6 = 6.0,
}

/** This indicate different states of the highlight grid */
export enum HighlightGridPlaybackState {
  play,
  playing,
  played,
  pause,
  paused,
  replay,
}

export interface TitleSortControls {
  name: 'ALPHA' | 'ID';
  direction: 'ASC' | 'DESC';
  active: boolean;
}

export const titleSortIconNames = {
  ALPHA: 'sort_by_alpha',
  ID: 'sort',
};

export interface CameraListItem {
  id?: number;
  name: string;
  uri?: string;
  description?: string;
  enabled: boolean;
}

export interface DateRangeRadio {
  id: string;
  name: string;
  checked: boolean;
}

export interface HighlightItem {
  id?: number;
  name: string;
  dateTime: string;
}

export const toolbarTitleParameters = {
  camerasList: [
    { name: 'ALPHA', direction: 'ASC', active: true },
    { name: 'ID', direction: 'ASC', active: false },
  ],
  highlightsList: [
    { name: 'ALPHA', direction: 'ASC', active: true },
    { name: 'ID', direction: 'ASC', active: false },
  ],
};

export const availableDateRanges = [
  { id: '1-hr', name: 'Last hour', checked: true },
  { id: 'today', name: 'Today', checked: false },
  { id: '24-hrs', name: 'Last 24 hours', checked: false },
  { id: '7-days', name: 'Last 7 days', checked: false },
  { id: 'custom', name: 'Custom range', checked: false },
];

export const availableIncidentFilters = [
  { id: '', name: 'Show All', checked: true },
  { id: 'without', name: 'Without Incidents', checked: false },
  { id: 'with', name: 'With Incidents', checked: false },
  { id: 'category', name: 'By Category', checked: false },
];
