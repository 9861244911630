/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AccessToken } from '@auth/models/auth.models';
import {
  LIVE_PREVIEW_DEFAULT_SERVER,
  LIVE_PREVIEW_DOMAIN,
  SERVERS_AUTH_DATA_STORAGE_KEY,
  SERVER_STORAGE_KEY,
} from '@server/constants/server.constants';
import { Server, ServerAuthData } from '@server/models/server.model';

@Injectable({
  providedIn: 'root',
})
export class ServerService {
  private servers: Server[] = [];
  public currentServers = new BehaviorSubject<Server[]>([]);
  private serversAuthData = [];

  public get currentServer(): Server {
    return this.currentServers.value[0];
  }

  constructor() {
    this.checkStorage();
  }

  public get storedServers(): BehaviorSubject<Server[]> {
    return this.currentServers;
  }

  private update(): void {
    this.currentServers.next(this.servers);
    localStorage.setItem(SERVER_STORAGE_KEY, JSON.stringify(this.servers));
  }

  public set newServer(value: Server) {
    const currentServerValue = this.currentServers.value[0];

    if (!value.api) {
      value.api = currentServerValue.api;
    }

    if (!value.websocket) {
      value.websocket = currentServerValue.websocket;
    }

    const unchangedBaseUrl: boolean = currentServerValue?.api === value.api;
    const unchangedWSUrl: boolean = currentServerValue?.websocket === value.websocket;
    if (unchangedBaseUrl && unchangedWSUrl) {
      return;
    }

    this.servers.push(value);
    this.update();
  }

  private setDefaults(): void {
    const localDomain = window.location.host;
    const domainName = localDomain.indexOf(LIVE_PREVIEW_DOMAIN) !== -1 ? LIVE_PREVIEW_DEFAULT_SERVER : localDomain;
    const defaultBaseUrl = `https://${domainName}/api/`;
    const defaultWsBaseUrl = `wss://${domainName}/ws/`;
    this.newServer = { api: defaultBaseUrl, websocket: defaultWsBaseUrl };
  }

  private checkStorage(): void {
    const previousServers: Server[] = JSON.parse(localStorage.getItem(SERVER_STORAGE_KEY));

    if (previousServers) {
      this.servers = previousServers;
      this.update();
    } else {
      this.setDefaults();
    }
  }

  public clearServers(): void {
    this.servers = [];
    this.update();
    localStorage.removeItem(SERVER_STORAGE_KEY);
  }

  public resetDefaultServers(): void {
    this.clearServers();
    this.setDefaults();
    this.update();
  }

  public setServerTokens(token: AccessToken): void {
    this.servers.forEach((server) => {
      this.setServerToken(token, server.api);
    });
  }

  public setServerToken(token: AccessToken, baseUrl: string): void {
    const data: ServerAuthData = { access: token.access, refresh: token.refresh, baseUrl };
    // check if baseUrl already exists in list
    const foundServer = this.serversAuthData.find((server) => server.baseUrl === baseUrl);
    if (foundServer) {
      this.serversAuthData.upsert({baseUrl}, data);
    }
    else {
      this.serversAuthData.push(data);
    }
    this.updateServersData();
  }

  private updateServersData(): void {
    localStorage.setItem(SERVERS_AUTH_DATA_STORAGE_KEY, JSON.stringify(this.serversAuthData));
  }

  public clearServersData(): void {
    this.serversAuthData = [];
    localStorage.removeItem(SERVERS_AUTH_DATA_STORAGE_KEY);
  }

  public allServersAuthenticated(): boolean {
    const serversList = JSON.parse(localStorage.getItem(SERVER_STORAGE_KEY));
    const serverTokens = JSON.parse(localStorage.getItem(SERVERS_AUTH_DATA_STORAGE_KEY));
    return serversList?.length === serverTokens?.length;
  }

  public get getServerData(): ServerAuthData[] {
    this.serversAuthData = JSON.parse(localStorage.getItem(SERVERS_AUTH_DATA_STORAGE_KEY)) || [];
    return this.serversAuthData;
  }

  // When testing a login
  // This will temporarily (without using localstorage) store the token
  public tempSetServerData(token: AccessToken, baseUrl: string): void {
    this.serversAuthData = [{
      baseUrl,
      ...token
    }];
  }

  public get tempGetServerData(): ServerAuthData[] {
    return this.serversAuthData;
  }
}
