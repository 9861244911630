import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState } from '@app/store';
import {
  AUTH_ROUTE_LOGOUT,
  AUTH_ROUTE_CHANGE_PASSWORD,
  ASSISTANCE_EMAIL,
  ASSISTANCE_EMAIL_DEFAULT_SUBJECT,
} from '@auth/constants/auth.constants';
import { User } from '@auth/models/auth.models';
import { AuthService } from '@auth/services/auth.service';
import { CLIENTS_ROUTE_CLIENTS_LIST } from '@clients/constants/clients.constants';
import { AUTH, CLIENTS, USERS, SERVER_REGEX } from '@core/constants/app.constants';
import { AboutDialogComponent } from '@core/dialogs/about-dialog/about-dialog.component';
import { LiveAnomalyState, LiveAnomalyServer } from '@core/store/live-anomalies/anomalies.reducers';
import { selectAnomalyConnectionState } from '@core/store/live-anomalies/anomalies.selectors';
import { Server } from '@server/models/server.model';
import { ServerService } from '@server/services/server.service';
import { EventStreamState } from '@shared/constants/event-stream-state.constant';
import { USERS_ROUTE_USERS_LIST } from '@users/constants/users.constants';


@Component({
  selector: 'icetana-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  user$: Observable<User>;
  server: Server;
  public assistanceEmailLink: string;
  public eventStreamServers: LiveAnomalyState;
  public serverHealthIndicator: boolean;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private serverService: ServerService,
    public dialog: MatDialog,
  ) {
    this.user$ = this.store.pipe(select((state) => state.auth.user));
    this.authService.init();
  }

  ngOnInit(): void {
    this.initServer();
    this.assistanceEmailLink = `mailto:${ASSISTANCE_EMAIL}?subject=${ASSISTANCE_EMAIL_DEFAULT_SUBJECT}`;

    this.store.pipe(
      select(selectAnomalyConnectionState),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(eventStreamServers => {
      this.serverHealthIndicator = false;
      let serverHealthCounter = 0;
      for (const [_, serverState] of eventStreamServers.servers) {
        if (this.serverHealth(serverState)) {
          serverHealthCounter++;
        }
      }
      this.serverHealthIndicator = serverHealthCounter === this.serverService.storedServers.value.length;

      this.eventStreamServers = eventStreamServers;
    });
  }

  extractServerName(url: string): string {
    return url.match(SERVER_REGEX)[1];
  }

  serverHealth(serverState: LiveAnomalyServer): boolean {
    return (serverState.state === EventStreamState.OPEN && !serverState.fail && serverState.success);
  }

  serverTooltip(serverState: LiveAnomalyServer): string {
    const parseTimestamp = serverState.lastHeartBeat ? moment.unix(serverState.lastHeartBeat / 1000).format('YYYY-MM-DD HH:mm:ss') : '';
    return serverState.lastHeartBeat ? `Last Updated: ${parseTimestamp}` : '';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  initServer(): void {
    this.server = this.serverService.currentServer;
  }

  openAboutDialog(): void {
    this.dialog.open(AboutDialogComponent, {
      data: {},
      width: '400px',
    });
  }

  get logoutPath(): string {
    return `${AUTH}/${AUTH_ROUTE_LOGOUT}`;
  }

  get usersListPath(): string {
    return `${USERS}/${USERS_ROUTE_USERS_LIST}`;
  }

  get clientsListPath(): string {
    return `${CLIENTS}/${CLIENTS_ROUTE_CLIENTS_LIST}`;
  }

  get changePasswordPath(): string {
    return `${AUTH}/${AUTH_ROUTE_CHANGE_PASSWORD}`;
  }
}
