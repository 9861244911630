import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserSettingsState } from '@core/store/user-settings/user-settings.reducers';
import { REDUCER_USER_SETTINGS_ID } from '@core/constants/app.constants';

export const selectSettings = createFeatureSelector<UserSettingsState>(REDUCER_USER_SETTINGS_ID);

export const selectSettingsDateRanges = createSelector(selectSettings, (settings) => settings.dateRanges);
export const selectSettingsDateRangeStart = createSelector(selectSettings, (settings) => settings.dateRangeStart);
export const selectSettingsDateRangeEnd = createSelector(selectSettings, (settings) => settings.dateRangeEnd);
export const selectSettingsHighlightCameraFilter = createSelector(selectSettings, (settings) => settings.highlightCameraFilter);
export const selectSettingsIncidentFilters = createSelector(selectSettings, (settings) => settings.incidentFilter);
export const selectSettingsIncidentCategoryFilters = createSelector(selectSettings, (settings) => settings.incidentFilterCategories);
export const selectSettingsAnomalyModelFilters = createSelector(selectSettings, (settings) => settings.anomalyModelFilters);
export const selectSettingsAnomalyLabelFilters = createSelector(selectSettings, (settings) => settings.anomalyLabelFilters);
