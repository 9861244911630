import { DEFAULT_GRID_WIDTH, DEFAULT_GRID_HEIGHT } from '@live-wall/constants/live-wall.constants';
import { LiveWallTile } from '@core/models/anomaly/anomaly.model';
import { createReducer, on } from '@ngrx/store';

import {
  liveAnomalyStartPlayingOnLiveWallInit,
  liveAnomalyStartPlayingOnLiveWallSuccess,
  liveAnomalyStartPlayingOnLiveWallFail,
  liveAnomalyStopPlayingOnLiveWallInit,
  liveAnomalyStopPlayingOnLiveWallSuccess,
  liveAnomalyStopPlayingOnLiveWallFail,
  liveWallUpdateGridDimensionsInit,
  liveWallUpdateGridDimensionsSuccess,
  liveWallUpdateGridDimensionsFail,
  liveWallFetchGridDimensionsInit,
  liveWallFetchGridDimensionsSuccess,
  liveWallFetchGridDimensionsFail,
  clearLiveWall,
} from '@core/store/livewall/livewall.actions';

import { getOldestAnomalyTile } from '@core/store/livewall/livewall.utils';

export interface LiveWallState {
  gridWidth: number;
  gridHeight: number;
  tiles: LiveWallTile;
  success: boolean;
  loading: boolean;
  fail: boolean;
}

export const initialLiveWallState: LiveWallState = {
  gridWidth: DEFAULT_GRID_WIDTH,
  gridHeight: DEFAULT_GRID_HEIGHT,
  tiles: {},
  success: false,
  loading: false,
  fail: false,
};

export const liveWallReducer = createReducer(
  initialLiveWallState,
  // Start Playing Live Anomaly
  on(liveAnomalyStartPlayingOnLiveWallInit, (state) => ({
      ...state,
      loading: true,
    })),
  on(liveAnomalyStartPlayingOnLiveWallSuccess, (state, { payload }) => {
    const tiles = { ...state.tiles };
    const gridSize = state.gridWidth * state.gridHeight;

    let freeIndex = -1;
    let existingIndex = -1;
    for (let tileIndex = 0; tileIndex < gridSize; tileIndex++) {
      // If the current tile is empty, we save the index and end the loop.
      if (tiles[tileIndex] === undefined) {
        freeIndex = tileIndex;
        break;
      }
      // Otherwise, if the tiles is not empty, and it belongs to the same camera, we save the index.
      else if (tiles[tileIndex]?.body?.cameraGuid === payload?.body?.cameraGuid) {
        existingIndex = tileIndex;
      }
    }

    let index = -1;
    if (existingIndex !== -1) {
      if (tiles[existingIndex]?.body.start < payload?.body.start) {
        console.log(
          'The existing anomaly for the camera ' +
            `(Camera GUID: ${tiles[existingIndex]?.body.cameraGuid}, ` +
            `Start:${tiles[existingIndex]?.body.start}, ` +
            `End:${tiles[existingIndex]?.body.end}) ` +
            'has been replaced by a new anomaly for the camera ' +
            `(Camera GUID: ${payload?.body.cameraGuid}, ` +
            `Start:${payload?.body.start}, ` +
            `End: ${payload?.body.end}).`
        );
        index = existingIndex;
      } else {
        console.log(
          'The new anomaly for the camera ' +
            `(Camera GUID: ${payload?.body.cameraGuid}, ` +
            `Start:${payload?.body.start}, ` +
            `End: ${payload?.body.end}) ` +
            'has been discarded due to being older than the current anomaly for the camera ' +
            `(Camera GUID: ${tiles[existingIndex]?.body.cameraGuid}, ` +
            `Start:${tiles[existingIndex]?.body.start}, ` +
            `End:${tiles[existingIndex]?.body.end}).`
        );
      }
    } else if (freeIndex === -1) {
      // If we can't find any free tiles, we replace the oldest anomaly tile first.
      index = getOldestAnomalyTile(tiles, gridSize);
    } else {
      // Otherwise, we start playing in the first available free anomaly tile.
      index = freeIndex;
    }

    if (index !== -1) {
      tiles[index] = payload;
    }

    return {
      ...state,
      loading: false,
      success: true,
      tiles,
    };
  }),
  on(liveAnomalyStartPlayingOnLiveWallFail, (state, error: Error) => ({
    ...state,
    loading: false,
    fail: true,
  })),
  // Stop Playing Live Anomaly
  on(liveAnomalyStopPlayingOnLiveWallInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(liveAnomalyStopPlayingOnLiveWallSuccess, (state, { payload }) => {
    const tiles = { ...state.tiles };
    const gridSize = state.gridWidth * state.gridHeight;

    for (let i = 0; i < gridSize; i++) {
      if (tiles[i] !== undefined && tiles[i].body.anomalyGuid === payload.body.anomalyGuid) {
        delete tiles[i];
      }
    }

    return {
      ...state,
      loading: false,
      success: true,
      tiles,
    };
  }),
  on(liveAnomalyStopPlayingOnLiveWallFail, (state, error: Error) => ({
    ...state,
    loading: false,
    fail: true,
  })),

  on(clearLiveWall, (state, { serverUrl }) => {
    const tiles = { ...state.tiles };
    const gridSize = state.gridWidth * state.gridHeight;

    for (let i = 0; i < gridSize; i++) {
      if (serverUrl) {
        if (tiles[i] && tiles[i].body.anomalyDiagnostic?.diagnosticsPath.indexOf(serverUrl) !== -1) {
          delete tiles[i];
        }
      }
      else {
        delete tiles[i];
      }
    }

    return {
      ...state,
      loading: false,
      success: true,
      tiles,
    };
  }),

  on(liveWallFetchGridDimensionsInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(liveWallFetchGridDimensionsSuccess, (state, { payload }) => ({
    ...state,
    gridWidth: payload.livewall_columns,
    gridHeight: payload.livewall_rows,
    loading: false,
  })),
  on(liveWallFetchGridDimensionsFail, (state, error: Error) => ({
    ...state,
    gridWidth: DEFAULT_GRID_WIDTH,
    gridHeight: DEFAULT_GRID_HEIGHT,
    loading: false,
    fail: true,
  })),
  on(liveWallUpdateGridDimensionsInit, (state, { payload }) => ({
    ...state,
    gridWidth: payload.livewall_columns,
    gridHeight: payload.livewall_rows,
    loading: true,
  })),
  on(liveWallUpdateGridDimensionsSuccess, (state, { payload }) => ({
    ...state,
    gridWidth: payload.livewall_columns,
    gridHeight: payload.livewall_rows,
    loading: false,
  })),
  on(liveWallUpdateGridDimensionsFail, (state, error: Error) => ({
    ...state,
    loading: false,
    fail: true,
  }))
);
