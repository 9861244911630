import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IncidentEmitterService {
  incidentDialog = new EventEmitter();

  constructor() {}

  onRightClick(): void {
    this.incidentDialog.emit();
  }
}
