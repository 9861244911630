/* eslint-disable @typescript-eslint/naming-convention */
import { User } from '@auth/models/auth.models';

export interface IncidentReporterAPI {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
}

export interface IncidentReporter {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
}

export interface IncidentPagination {
  count: number;
  entities: Array<Incident>;
}

export interface IncidentCSVRow {
  incidentId?: number;
  cameraName?: string;
  cameraGuid?: string;
  videoUrl?: string;
  notes: string;
  anomalyId?: number;
  categories: Array<string>;
  reporter?: string;
  started?: string;
  ended?: string;
  updated?: string;
}

export interface IncidentAPIPagination {
  count: number;
  results: Array<IncidentAPI>;
}

export interface IncidentAPI {
  id?: number;
  subject: string;
  body: string;
  anomaly?: number;
  categories: Array<number>;
  reporter?: IncidentReporterAPI;
  started?: string;
  ended?: string;
  status: number;
  created?: string;
  updated?: string;
  video_url?: string;
  camera_name?: string;
  camera_guid?: string;
  timezone?: string;
}

export interface Incident {
  id?: number;
  subject: string;
  body: string;
  anomaly?: number;
  categories: Array<number>;
  reporter?: IncidentReporter;
  started?: string;
  ended?: string;
  status: number;
  created?: string;
  updated?: string;
  videoUrl?: string;
  cameraName?: string;
  cameraGuid?: string;
  timezone?: string;
}

export interface IncidentStatusPagination {
  count: number;
  entities: Array<IncidentStatus>;
}

export interface IncidentStatusAPIPagination {
  count: number;
  results: Array<IncidentStatusAPI>;
}

export interface IncidentStatusAPI {
  id?: number;
  name: string;
  description?: string;
  order: number;
  created: string;
  updated?: string;
  default: boolean;
}

export interface IncidentStatus {
  id?: number;
  name: string;
  description?: string;
  order: number;
  created: string;
  updated?: string;
  default: boolean;
}

export interface IncidentCategoryAPIPagination {
  count: number;
  results: Array<IncidentCategoryAPI>;
}

export interface IncidentCategoryPagination {
  count: number;
  entities: Array<IncidentCategory>;
}

export interface IncidentCategoryAPI {
  id?: number;
  parent: number;
  name: string;
  description?: string;
  order: number;
  created: string;
  updated: string;
  default?: boolean;
}

export interface IncidentCategoryTree {
  id?: number;
  name: string;
  description?: string;
  created: string;
  updated: string;
  children: Array<IncidentCategory> | null;
}

export interface IncidentCategory {
  id?: number;
  parent: number;
  name: string;
  description?: string;
  order: number;
  created: string;
  updated: string;
  default?: boolean;
}

/**
 * Recursive tree builder
 */
export const incidentCategoryTreeBuilder = (data: Array<IncidentCategory>): IncidentCategoryTree => {
  if (!data?.length) {
    return null;
  }
  const nest = (items: Array<IncidentCategory>, id = null) =>
    items
      .filter((item) => item.parent === id)
      .map((item) => ({
        id: item.id,
        description: item.description,
        name: item.name,
        created: item.created,
        updated: item.updated,
        children: nest(items, item.id),
      }));

  return nest(data);
};

/**
 * Recursive tree flattener
 */
export const incidentCategoryTreeFlattener = (data: IncidentCategoryTree): Array<IncidentCategory> => {
  const flatten = (children, getChildren: any, level: number, parent: number | null) =>
    Array.prototype.concat.apply(
      children.map((incident: IncidentCategoryTree, i) => ({
        ...incident,
        level: level || 1,
        parent: parent || null,
        order: i,
        default: false,
      })),
      children.map((incident: IncidentCategoryTree) =>
        flatten(getChildren(incident) || [], getChildren, (level || 1) + 1, incident.id)
      )
    );

  const extractChildren = (x) => x.children;

  return flatten(extractChildren({ children: data }), extractChildren, 0, null).map((x) => delete x.children && x);
};

export const incidentCategorySerializer = (data: IncidentCategoryAPI): IncidentCategory => ({
    ...data,
  });

export const incidentCategoryDeserializer = (data: IncidentCategory): IncidentCategoryAPI => ({
    ...data,
  });

export const incidentStatusSerializer = (data: IncidentStatusAPI): IncidentStatus => ({
    ...data,
  });

export const incidentStatusDeserializer = (data: IncidentStatus): IncidentStatusAPI => ({
    ...data,
  });

export const incidentSerializer = (data: IncidentAPI): Incident => ({
    id: data.id,
    subject: data.subject,
    body: data.body,
    anomaly: data.anomaly,
    categories: data.categories,
    started: data.started,
    ended: data.ended,
    status: data.status,
    created: data.created,
    updated: data.updated,
    videoUrl: data.video_url,
    cameraName: data.camera_name,
    cameraGuid: data.camera_guid,
    reporter: {
      ...data.reporter,
      firstName: data.reporter.first_name,
      lastName: data.reporter.last_name,
      fullName: data.reporter.full_name,
    },
    timezone: data.timezone,
  });

export const incidentDeserializer = (data: Incident): IncidentAPI => ({
    id: data.id,
    subject: data.subject,
    body: data.body,
    anomaly: data.anomaly,
    categories: data.categories,
    started: data.started,
    ended: data.ended,
    status: data.status,
    created: data.created,
    updated: data.updated,
    video_url: data.videoUrl,
    camera_name: data.cameraName,
    camera_guid: data.cameraGuid,
    reporter: {
      ...data.reporter,
      first_name: data.reporter.firstName,
      last_name: data.reporter.lastName,
      full_name: data.reporter.fullName,
    },
  });

export const incidentPaginationSerializer = (data: IncidentAPIPagination): IncidentPagination => ({
    ...data,
    entities: data.results.map((i) => incidentSerializer(i)),
  });

export const incidentStatusPaginationSerializer = (data: IncidentStatusAPIPagination): IncidentStatusPagination => ({
    ...data,
    entities: data.results.map((i) => incidentStatusSerializer(i)),
  });

export const incidentCategoryPaginationSerializer = (
    data: IncidentCategoryAPIPagination
  ): IncidentCategoryPagination => ({
    ...data,
    entities: data.results.map((i) => incidentCategorySerializer(i)),
  });
