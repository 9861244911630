import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@shared/shared.module';
import { ServerComponent } from '@server/components/server.component';

const routes: Routes = [
  {
    path: '',
    component: ServerComponent,
  },
];

@NgModule({
  declarations: [ServerComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(routes)],
})
export class ServerModule {}
