/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { EVENTSTREAM_FLAG } from '@core/constants/app.constants';
import { LiveAnomalyWebSocketService } from '@anomalies/services/live-anomaly-websocket/live-anomaly-web-socket.service';
import { LiveAnomalySseService } from '@anomalies/services/live-anomaly-sse/live-anomaly-sse.service';
import {
  LIVE_ANOMALY_CLOSE_ALL_CONNECTIONS_INIT,
  LIVE_ANOMALY_ALL_CONNECTIONS_INIT,
} from '@core/store/live-anomalies/anomalies.actions';

@Injectable()
export class LiveAnomalyEffects {
  private eventstreamFlag = EVENTSTREAM_FLAG;

  constructor(
    private actions$: Actions,
    private anomalyWebSocketService: LiveAnomalyWebSocketService,
    private anomalyEventStreamService: LiveAnomalySseService,
  ) {}

  connect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LIVE_ANOMALY_ALL_CONNECTIONS_INIT),
        tap(() => {
          if (this.eventstreamFlag) {
            this.anomalyEventStreamService.init();
          }
          else {
            this.anomalyWebSocketService.init();
          }
        })
      ),
    { dispatch: false }
  );

  close$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LIVE_ANOMALY_CLOSE_ALL_CONNECTIONS_INIT),
        tap(() => {
          if (this.eventstreamFlag) {
            this.anomalyEventStreamService.close();
          }
          else {
            this.anomalyWebSocketService.close();
          }
        })
      ),
    { dispatch: false }
  );
}
