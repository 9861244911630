// Camera feature state key
export const REDUCER_INCIDENTS_ID = 'incidents';

// Camera feature module routes
export const INCIDENTS_LIST = '';
export const INCIDENTS_CATEGORIES = 'categories';

// Default Category Name
export const DEFAULT_INCIDENT_STATUS_NAME = 'Open';

// Uncategorised Status Name
export const UNCATEGORISED_INCIDENT_CATEGORY_NAME = 'Uncategorised';

// Default Status Name
export const DEFAULT_INCIDENT_CATEGORY_NAME = UNCATEGORISED_INCIDENT_CATEGORY_NAME;

// Default Incident Subject
export const DEFAULT_INCIDENT_SUBJECT = 'New Incident';

// Default Incident Body
export const DEFAULT_INCIDENT_BODY = 'A quick saved incident';
