import { createAction, props } from '@ngrx/store';

import { Camera, CameraPagination } from '@cameras/models/cameras/cameras.models';

const CAMERAS_PREFIX = '[CAMERAS]';
const CAMERA_PREFIX = '[CAMERA]';

export const CAMERAS_FETCH_INIT = `${CAMERAS_PREFIX} Fetch Init`;
export const CAMERAS_FETCH_SUCCESS = `${CAMERAS_PREFIX} Fetch Success`;
export const CAMERAS_FETCH_FAIL = `${CAMERAS_PREFIX} Fetch Fail`;

export const CAMERAS_FETCH_UNPAGINATED_INIT = `${CAMERAS_PREFIX} Fetch Unpaginated Init`;
export const CAMERAS_FETCH_UNPAGINATED_SUCCESS = `${CAMERAS_PREFIX} Fetch Unpaginated Success`;
export const CAMERAS_FETCH_UNPAGINATED_FAIL = `${CAMERAS_PREFIX} Fetch Unpaginated Fail`;

export const CAMERA_FETCH_INIT = `${CAMERA_PREFIX} Fetch Init`;
export const CAMERA_FETCH_SUCCESS = `${CAMERA_PREFIX} Fetch Success`;
export const CAMERA_FETCH_FAIL = `${CAMERA_PREFIX} Fetch Fail`;

export const CAMERA_CREATE_INIT = `${CAMERA_PREFIX} Create Camera Init`;
export const CAMERA_CREATE_SUCCESS = `${CAMERA_PREFIX} Create Camera Success`;
export const CAMERA_CREATE_FAIL = `${CAMERA_PREFIX} Create Camera Fail`;

export const CAMERA_UPDATE_INIT = `${CAMERA_PREFIX} Update Init`;
export const CAMERA_UPDATE_SUCCESS = `${CAMERA_PREFIX} Update Success`;
export const CAMERA_UPDATE_FAIL = `${CAMERA_PREFIX} Update Fail`;

export const CAMERA_DELETE_INIT = `${CAMERA_PREFIX} Delete Init`;
export const CAMERA_DELETE_SUCCESS = `${CAMERA_PREFIX} Delete Success`;
export const CAMERA_DELETE_FAIL = `${CAMERA_PREFIX} Delete Fail`;

export const CAMERA_START_PIPELINE_SESSION_INIT = `${CAMERA_PREFIX} Start Camera Pipeline Session Action Init`;
export const CAMERA_START_PIPELINE_SESSION_SUCCESS = `${CAMERA_PREFIX} Start Camera Pipeline Session Action Success`;
export const CAMERA_START_PIPELINE_SESSION_FAIL = `${CAMERA_PREFIX} Start Camera Pipeline Session Action Fail`;

export const CAMERA_STOP_PIPELINE_SESSION_INIT = `${CAMERA_PREFIX} Stop Camera Pipeline Session Action Init`;
export const CAMERA_STOP_PIPELINE_SESSION_SUCCESS = `${CAMERA_PREFIX} Stop Camera Pipeline Session Action Success`;
export const CAMERA_STOP_PIPELINE_SESSION_FAIL = `${CAMERA_PREFIX} Stop Camera Pipeline Session Action Fail`;

export const camerasFetchUnpaginatedInit = createAction(CAMERAS_FETCH_UNPAGINATED_INIT);
export const camerasFetchUnpaginatedSuccess = createAction(
  CAMERAS_FETCH_UNPAGINATED_SUCCESS,
  props<{ payload: CameraPagination }>()
);
export const camerasFetchUnpaginatedFail = createAction(CAMERAS_FETCH_UNPAGINATED_FAIL, props<Error>());

export const camerasFetchInit = createAction(
  CAMERAS_FETCH_INIT,
  props<{ payload: { pageLimit: number; pageOffset: number } }>()
);
export const camerasFetchSuccess = createAction(CAMERAS_FETCH_SUCCESS, props<{ payload: CameraPagination }>());
export const camerasFetchFail = createAction(CAMERAS_FETCH_FAIL, props<Error>());

export const cameraFetchInit = createAction(CAMERA_FETCH_INIT, props<{ id: number }>());
export const cameraFetchSuccess = createAction(CAMERA_FETCH_SUCCESS, props<{ payload: { camera: Camera } }>());
export const cameraFetchFail = createAction(CAMERA_FETCH_FAIL, props<Error>());

export const cameraCreateInit = createAction(CAMERA_CREATE_INIT, props<{ camera: Camera }>());
export const cameraCreateSuccess = createAction(CAMERA_CREATE_SUCCESS, props<{ payload: { camera: Camera } }>());
export const cameraCreateFail = createAction(CAMERA_CREATE_FAIL, props<Error>());

export const cameraUpdateInit = createAction(CAMERA_UPDATE_INIT, props<{ camera: Camera }>());
export const cameraUpdateSuccess = createAction(CAMERA_UPDATE_SUCCESS, props<{ payload: { camera: Camera } }>());
export const cameraUpdateFail = createAction(CAMERA_UPDATE_FAIL, props<Error>());

export const cameraDeleteInit = createAction(CAMERA_DELETE_INIT, props<{ camera: Camera }>());
export const cameraDeleteSuccess = createAction(CAMERA_DELETE_SUCCESS, props<{ payload: { camera: Camera } }>());
export const cameraDeleteFail = createAction(CAMERA_DELETE_FAIL, props<Error>());

export const cameraStartPipelineSessionInit = createAction(
  CAMERA_START_PIPELINE_SESSION_INIT,
  props<{ payload: { camera: Camera } }>()
);
export const cameraStartPipelineSessionSuccess = createAction(
  CAMERA_START_PIPELINE_SESSION_SUCCESS,
  props<{ payload: { camera: Camera } }>()
);
export const cameraStartPipelineSessionFail = createAction(
  CAMERA_START_PIPELINE_SESSION_FAIL,
  props<{ payload: { error: Error; camera: Camera } }>()
);

export const cameraStopPipelineSessionInit = createAction(
  CAMERA_STOP_PIPELINE_SESSION_INIT,
  props<{ payload: { camera: Camera } }>()
);
export const cameraStopPipelineSessionSuccess = createAction(
  CAMERA_STOP_PIPELINE_SESSION_SUCCESS,
  props<{ payload: { camera: Camera } }>()
);
export const cameraStopPipelineSessionFail = createAction(
  CAMERA_STOP_PIPELINE_SESSION_FAIL,
  props<{ payload: { error: Error; camera: Camera } }>()
);
