/* eslint-disable @typescript-eslint/naming-convention */
export interface Client {
  id?: number;
  name: string;
  timezone?: string;
  sites: Site[];
}

export interface Site {
  id?: number;
  name: string;
  timezone?: string;
  zones: Zone[];
  cameraCount?: number;
}

export interface ClientPagination {
  count: number;
  next: string | null;
  prev: string | null;
  results: Client[];
}

export interface Zone {
  id?: number;
  name: string;
}

export interface ClientAPI {
  id?: number;
  name: string;
  timezone?: string;
  sites: SiteAPI[];
}

export interface SiteAPI {
  id?: number;
  name: string;
  timezone?: string;
  zones: Zone[];
  camera_count?: number;
}

export interface ClientAPIPagination {
  count: number;
  next: string | null;
  prev: string | null;
  results: ClientAPI[];
}

export const clientPaginationSerializer = ({ count, next, prev, results }: ClientAPIPagination) => ({
  count,
  next,
  prev,
  results: results ? results.map((client) => clientSerializer(client)) : null,
});

export const clientSerializer = ({
  id,
  name,
  timezone,
  sites,
}: ClientAPI) =>
  ({
    id,
    name,
    timezone,
    sites: sites ? sites.map((site) => siteSerializer(site)) : [],
  } as Client);

export const clientDeserializer = ({
  id,
  name,
  timezone,
  sites,
}: Client) =>
  ({
    id,
    name,
    timezone,
    sites: sites ? sites.map((site) => siteDeserializer(site)) : [],
  } as ClientAPI);

export const siteSerializer = ({
  id,
  name,
  timezone,
  zones,
  camera_count,
}: SiteAPI) =>
  ({
    id,
    name,
    timezone,
    zones,
    cameraCount: camera_count,
  } as Site);

export const siteDeserializer = ({
  id,
  name,
  timezone,
  zones,
  cameraCount,
}: Site) =>
  ({
    id,
    name,
    timezone,
    zones,
    camera_count: cameraCount,
  } as SiteAPI);
