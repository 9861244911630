import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private helper = new JwtHelperService();

  constructor() {}

  decodeToken(rawToken: string): any {
    return this.helper.decodeToken(rawToken);
  }

  tokenExpirationDate(rawToken: string): any {
    return this.helper.getTokenExpirationDate(rawToken);
  }

  isExpired(rawToken: string): boolean {
    return this.helper.isTokenExpired(rawToken);
  }
}
