<!-- Full screen player dialog template -->
<mat-card class="mat-elevation-z0 icetana-modal-incident-create">
  <div class="close top-right" (click)="onCloseClick()">
    <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
  </div>

  <mat-card-header>
    <mat-card-title>Create Incident</mat-card-title>
  </mat-card-header>

  <mat-card-content fxLayout="row" fxLayoutAlign="space-between stretch" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="36px" fxLayoutGap.sm="20px" fxLayoutGap.xs="20px">
    <mat-spinner *ngIf="metaLoading || (isLoading$ | async)"></mat-spinner>
    <div fxFlex="50" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px" class="icetana-modal-incident-container-left">
      <div class="modal-content">
        <div class="modal-form" fxLayout="column" fxLayoutAlign="start stretch" fxLayout.sm="row" fxLayout.xs="row" fxLayoutGap="10px">
          <div class="inputGroup" *ngIf="!metaLoading &&  (isLoading$ | async) === false" [formGroup]="incidentReportForm">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Category<sup>*</sup></mat-label>
              <mat-select matInput formControlName="categories"  panelClass="category-panel-class" class="category-select">
                <mat-option *ngFor="let category of $any(availableCategories$) | async" [value]="category.id">
                  {{ category.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Notes</mat-label>
              <textarea matInput rows="6" formControlName="body"></textarea>
            </mat-form-field>
            <div class="flex-full">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Reported by</mat-label>
                <input matInput [readonly]="true" [disabled]="true" [value]="currentUser"/>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between center" class="icetana-modal-incident-container-right">
      <div class="video" fxFlexFill>
        <icetana-vjs-player
          *ngIf="data.anomaly.videoUrl && !data.anomaly.videoLive"
          [options]="{
            autoplay: true,
            fluid: true,
            controls: true,
            loop: true,
            preload: 'metadata',
            sources: [{ src: data.anomaly.videoUrl, type: 'video/mp4' }]
          }"
          #video
        ></icetana-vjs-player>
        <icetana-vjs-player
          *ngIf="data.anomaly.videoUrl && data.anomaly.videoLive"
          [options]="{
            autoplay: true,
            fluid: true,
            controls: true,
            loop: true,
            preload: 'metadata',
            sources: [{ src: data.anomaly.videoUrl, type: 'application/x-mpegURL' }]
          }"
          #video
        ></icetana-vjs-player>
        <div class="modal-form" fxLayout="row wrap" fxLayoutAlign="center center" fxLayout.sm="row" fxLayout.xs="row" fxLayoutGap="10px">
          <div class="details-chip" *ngIf="data.anomaly.startTime">
            <div class="details-value details-value-datetime">
              <div>
                <div class="details-value-date">
                  <div class="details-label">Start</div>
                  <span>{{ parseDate(data.anomaly.startTime) }} {{ parseTime(data.anomaly.startTime) }}</span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="details-chip" *ngIf="data.anomaly.endTime">
            <div class="details-value details-value-datetime">
              <div>
                <div class="details-value-date">
                  <div class="details-label">End</div>
                  <span>{{ parseDate(data.anomaly.endTime) }} {{ parseTime(data.anomaly.endTime) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>

  <mat-card-footer>
    <section>
      <div class="footer-button">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!incidentReportForm.valid || metaLoading || (isLoading$ | async)"
          (click)="onSave()"
        >
          Save
        </button>
        <button mat-raised-button (click)="onCloseClick()">
          Cancel
        </button>
      </div>
    </section>
  </mat-card-footer>
</mat-card>
