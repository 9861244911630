import { ServerService } from '@server/services/server.service';

export class EndPoint {
  public backend: any;

  constructor(
    public path: string,
    public implemented: boolean = false,
    public fakeData: any = null,
    public done?: (request: any, response: any) => void
  ) {}

  toURL(): string {
    return this.backend.baseURL + this.path + '/';
  }
}

export class Backend {
  paths = {};

  constructor(paths: EndPoint[], private serverService: ServerService) {
    let baseURL = `${this.serverService.currentServer?.api}`;

    if (baseURL.slice(-1) !== '/') {
      baseURL += '/';
    }

    for (const path of paths) {
      path.backend = this;
      this.paths[path.path] = path;
      this.paths['/' + path.path + '/'] = path;
    }
  }
}
