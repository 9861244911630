import { createAction, props } from '@ngrx/store';

import { UserPagination, CreateUpdateUser } from '@users/models/user.models';

const USERS_PREFIX = '[USERS]';

export const USERS_FETCH_INIT = `${USERS_PREFIX} Fetch Init`;
export const USERS_FETCH_SUCCESS = `${USERS_PREFIX} Fetch Success`;
export const USERS_FETCH_FAIL = `${USERS_PREFIX} Fetch Fail`;

export const USER_DELETE_INIT = `${USERS_PREFIX} Delete Init`;
export const USER_DELETE_SUCCESS = `${USERS_PREFIX} Delete Success`;
export const USER_DELETE_FAIL = `${USERS_PREFIX} Delete Fail`;

export const USER_CREATE_INIT = `${USERS_PREFIX} Create Init`;
export const USER_CREATE_SUCCESS = `${USERS_PREFIX} Create Success`;
export const USER_CREATE_FAIL = `${USERS_PREFIX} Create Fail`;

export const USER_UPDATE_INIT = `${USERS_PREFIX} Update Init`;
export const USER_UPDATE_SUCCESS = `${USERS_PREFIX} Update Success`;
export const USER_UPDATE_FAIL = `${USERS_PREFIX} Update Fail`;

export const usersFetchInit = createAction(
  USERS_FETCH_INIT,
  props<{ payload: { pageLimit: number; pageOffset: number } }>()
);
export const usersFetchSuccess = createAction(USERS_FETCH_SUCCESS, props<{ payload: UserPagination }>());
export const usersFetchFail = createAction(USERS_FETCH_FAIL, props<Error>());

export const userDeleteInit = createAction(USER_DELETE_INIT, props<{ payload: { user: CreateUpdateUser } }>());
export const userDeleteSuccess = createAction(USER_DELETE_SUCCESS, props<any>());
export const userDeleteFail = createAction(USER_DELETE_FAIL, props<Error>());

export const userCreateInit = createAction(USER_CREATE_INIT, props<{ payload: { user: CreateUpdateUser } }>());
export const userCreateSuccess = createAction(USER_CREATE_SUCCESS, props<any>());
export const userCreateFail = createAction(USER_CREATE_FAIL, props<Error>());

export const userUpdateInit = createAction(USER_UPDATE_INIT, props<{ payload: { user: CreateUpdateUser } }>());
export const userUpdateSuccess = createAction(USER_UPDATE_SUCCESS, props<any>());
export const userUpdateFail = createAction(USER_UPDATE_FAIL, props<Error>());
