/* eslint-disable @typescript-eslint/member-ordering */
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { delay, retryWhen, take, takeUntil } from 'rxjs/operators';
import videojs, { VideoJsPlayerOptions } from 'video.js';

import { ANOMALY_DIAGNOSTICS_RETRIES, ANOMALY_DIAGNOSTICS_RETRY_DELAY } from '@core/constants/app.constants';

@Component({
  selector: 'icetana-vjs-player',
  templateUrl: './vjs-player.component.html',
  styleUrls: ['./vjs-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VjsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('video', { static: true }) video: ElementRef;

  @Input() videoTitleLeft?: string;
  @Input() videoTitleRight?: string;
  @Input() videoBottomLeft?: string;
  @Input() videoBottomRight?: string;
  @Input() overlayPath?: string;
  @Input() uri: string;
  @Input() anomalyId?: number;
  @Input() incidentExists?: boolean;
  @Input() options: VideoJsPlayerOptions = {
    preload: 'metadata',
    controls: true,
    autoplay: false,
    fluid: true,
    liveui: true,
    techOrder: ['html5', 'flash'],
    html5: {
      nativeVideoTracks: false,
      nativeAudioTracks: false,
      nativeTextTracks: false,
      vhs: {
        maxPlaylistRetries: 5,
        withCredentials: false,
        overrideNative: true,
        debug: false,
      },
    },
  };
  @Input() poster = 'assets/images/icetana-logo-mono-white.svg';
  @Input() deleteFlag?: boolean;
  @Input() anomalyModel?: {
    label: string;
    icon: string;
  };
  @Input() anomalyLabel?: {
    label: string;
    icon: string;
  };

  player: videojs.Player;
  overlayHeight: string;
  overlayWidth: string;
  resizeObserver: ResizeObserver;
  showOverlay = false;
  loadedAlready = false;
  private ngUnsubscribe = new Subject<void>();

  constructor(private http: HttpClient) {}

  /*
  Overlay image requires sizing to the video element
  Which depends on the width and the height of the video tile (the elements container)
  As this dictates which dimension is the limiting factor
  Then using this dimension to determine the other dimension based on the video elements
  aspect ratio
   */
  setOverlaySizing(): void {
    if (!this.player || !this.player.tech()) {
      return;
    }

    const vidWidth = (this.player.tech().el() as any).videoWidth;
    const vidHeight = (this.player.tech().el() as any).videoHeight;
    const vidAspectRatio = vidWidth / vidHeight;

    const actualWidth = this.player.currentDimensions().width;
    const actualHeight = this.player.currentDimensions().height;
    const actualAspectRatio = actualWidth / actualHeight;

    if (!vidAspectRatio || !actualAspectRatio) {
      return;
    }

    if (vidAspectRatio > actualAspectRatio) {
      this.overlayWidth = '100%';
      this.overlayHeight = `${((actualWidth * vidHeight) / vidWidth).toString()}px`;
    } else {
      this.overlayWidth = `${((actualHeight * vidWidth) / vidHeight).toString()}px`;
      this.overlayHeight = '100%';
    }
  }

  generateOverlayUrl(path): string {
    return `url(${path})`;
  }

  setOverlayId(): string {
    return `${this.video.nativeElement.playerId}_overlay`;
  }

  ngOnInit(): void {
    // instantiate Video.js
    this.player = videojs(this.video.nativeElement, this.options, () => null);

    // Upon canplaythrough set the overlay sizing
    this.player.on('canplaythrough', () => {
      this.setOverlaySizing();

      if (this.overlayPath && !this.loadedAlready) {
        this.loadedAlready = true;
        // test overlay image, if error then retry
        this.http
          .get(this.overlayPath, { responseType: 'blob' })
          .pipe(
            retryWhen((errors) =>
              errors.pipe(delay(ANOMALY_DIAGNOSTICS_RETRY_DELAY), take(ANOMALY_DIAGNOSTICS_RETRIES))
            ),
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe({
            next: (req) => {
              this.showOverlay = true;
            },
            error: () => {
              this.showOverlay = false;
            },
          });
      }
    });
    // bind the overlay sizing function to any resize events
    this.resizeObserver = new ResizeObserver((entries) => {
      this.setOverlaySizing();
    });
    this.resizeObserver.observe(this.video.nativeElement);

    this.applyVideoSrc();
  }

  ngOnDestroy(): void {
    // unsubscribe on closure
    this.resizeObserver.unobserve(this.video.nativeElement);

    // destroy player
    if (this.player) {
      this.player.dispose();
    }

    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private applyVideoSrc(): void {
    if (this.uri && this.player !== undefined) {
      let type = 'video/mp4';
      if (this.uri.endsWith('.m3u8')) {
        type = 'application/x-mpegURL';
      }

      this.player.src({ type, src: this.uri });
    }
  }

  public addAnomalyId(id: number): void {
    this.anomalyId = id;
  }

  public resetPlayer(): void {
    this.anomalyId = null;
  }

  public anomalyTooltip(): string {
    if (!this.anomalyModel || !this.anomalyLabel) {
      return null;
    }

    const eventStateString = this.deleteFlag ? ' (Finished)' : '';
    if (this.anomalyModel.label && this.anomalyLabel.label) {
      return `${this.anomalyModel.label} - ${this.anomalyLabel.label}${eventStateString}`;
    } else if (this.anomalyModel.label) {
      return `${this.anomalyModel.label}${eventStateString}`;
    } else {
      return `${this.anomalyLabel.label}${eventStateString}`;
    }
  }
}
