/* eslint-disable @typescript-eslint/member-ordering */
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Server } from '@server/models/server.model';
import { ServerService } from '@server/services/server.service';
import { of, Observable } from 'rxjs';
import { tap, mergeMap, delay } from 'rxjs/operators';

import * as url from 'url';
import { EndPoint } from '@shared/models/backend';
import { backend } from '@shared/backendless/backend';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  private get backendUrl(): string {
    return this.serverService.currentServer.api;
  }

  constructor(private serverService: ServerService) {}

  // manually change this to true to switch to offline fake data
  allowFakeResponse = false;
  // only if fake responses are allowed above, select whether
  // fake data is preferred even if the component is implemented.
  // disable this to use the real backend together with the fake data.
  // currently, we enable preferOffline mode only if environment is
  // switched to offline mode, where backendUrl is empty.
  preferOffline = false;

  getFakeResponse(request, component): HttpResponse<any> {
    // Need to check request.method if the same end point supports
    // multiple methods.

    let fakeData: any = component.fakeData;

    if (typeof fakeData === 'function') {
      console.log(`invoking function to get fake data for ${component.path}`);
      fakeData = fakeData(request);
    }

    console.log(`using fake data for ${component.path} of type ` + typeof fakeData);

    if (!(fakeData instanceof HttpResponse)) {
      fakeData = new HttpResponse({ status: 200, body: fakeData });
    }
    return fakeData;
  }

  getFakeComponent(request): EndPoint | null {
    if (!this.allowFakeResponse) {
      return null;
    }

    const parsedUrl = url.parse(request.url);
    if (!parsedUrl.pathname) {
      return null;
    }
    let path = parsedUrl.pathname;

    if (path.startsWith('/api/')) {
      path = path.substring(4);
    }

    let component = backend.paths[path];

    if (component === undefined) {
      console.log(`Checking if a path begins with '${path}'`);
      for (const e in backend.paths) {
        if (path.startsWith(e)) {
          component = backend.paths[e];
          break;
        }
      }
    }

    if (component === undefined) {
      console.log(`endpoint ${path} is not in app.backend`);
      return null;
    }

    if (!this.preferOffline && component.implemented) {
      console.log(`implemented endpoint ${path} is preferred`);
      return null;
    }

    if (!component.fakeData) {
      console.log(`no fake data for ${path}`);
      return null;
    }

    return component;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // wrap in delayed observable to simulate server api call
    return of(null).pipe(
      mergeMap(() => {
        const component = this.getFakeComponent(request);

        if (component) {
          const response = this.getFakeResponse(request, component);
          return of<HttpEvent<any>>(response)
            .pipe(delay(500))
            .pipe(
              tap(() => {
                if (component.done) {
                  component.done(request, response);
                }
              })
            );
        }

        // pass through any requests not handled above
        return next.handle(request);
      })
    );
  }
}

export const fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true,
};
