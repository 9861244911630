/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { CategoriesService } from '@incidents/services/categories.service';

import {
  categoriesFetchAllSuccess,
  CATEGORIES_FETCH_ALL_INIT,
  categoriesFetchAllFail,
  CATEGORY_UPDATE_INIT,
  categoryUpdateSuccess,
  categoryUpdateFail,
  CATEGORY_CREATE_INIT,
  categoryCreateSuccess,
  categoryCreateFail,
  CATEGORY_DELETE_INIT,
  categoryDeleteFail,
  categoryDeleteSuccess,
  CATEGORY_FETCH_INIT,
  categoryFetchSuccess,
  categoryFetchFail,
} from '@incidents/store/categories.actions';

import { IncidentCategory, IncidentCategoryPagination, IncidentCategoryTree } from '@incidents/models/incident.models';

interface CategoryAction {
  readonly type: string;
  id?: number;
  payload?: IncidentCategory;
}

@Injectable()
export class IncidentCategoriesEffects implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private actions$: Actions, private categoriesService: CategoriesService) {}

  fetchAllCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CATEGORIES_FETCH_ALL_INIT),
      mergeMap(() =>
        this.categoriesService.fetchAllCategories().pipe(
          map((categories: IncidentCategoryPagination) => categoriesFetchAllSuccess({ payload: categories.entities })),
          catchError((error: Error) => of(categoriesFetchAllFail(error)))
        )
      )
    )
  );

  // fetchCategory$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CATEGORY_FETCH_INIT),
  //     mergeMap((action: CategoryAction) =>
  //       this.categoriesService.fetchCategory(action.id).pipe(
  //         map((category: IncidentCategory) => {
  //           return categoryFetchSuccess({ payload: category });
  //         }),
  //         catchError((error: Error) => {
  //           return of(categoryFetchFail(error));
  //         })
  //       )
  //     )
  //   )
  // );
  //
  // updateCategory$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CATEGORY_UPDATE_INIT),
  //     mergeMap((action: CategoryAction) =>
  //       this.categoriesService.updateCategory({ ...action.payload }).pipe(
  //         map(() => {
  //           return categoryUpdateSuccess({ payload: action.payload });
  //         }),
  //         catchError((error: Error) => {
  //           return of(categoryUpdateFail(error));
  //         })
  //       )
  //     )
  //   )
  // );
  //
  // createCategory$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CATEGORY_CREATE_INIT),
  //     mergeMap((action: CategoryAction) => {
  //       return this.categoriesService.createCategory({ ...action.payload }).pipe(
  //         map(() => {
  //           return categoryCreateSuccess({ payload: action.payload });
  //         }),
  //         catchError((error: Error) => {
  //           return of(categoryCreateFail(error));
  //         })
  //       );
  //     })
  //   )
  // );
  //
  // deleteCategory$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(CATEGORY_DELETE_INIT),
  //     mergeMap((action: CategoryAction) =>
  //       this.categoriesService.deleteCategory({ ...action.payload }).pipe(
  //         map(() => {
  //           return categoryDeleteSuccess({ payload: action.payload });
  //         }),
  //         catchError((error: Error) => {
  //           return of(categoryDeleteFail(error));
  //         })
  //       )
  //     )
  //   )
  // );

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
