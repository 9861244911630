import { createAction, props } from '@ngrx/store';
import { IncidentCategory } from '@incidents/models/incident.models';

const CATEGORY_PREFIX = '[CATEGORY]';

// Fetch all categories
export const CATEGORIES_FETCH_ALL_INIT = `${CATEGORY_PREFIX} Fetch all categories Init`;
export const CATEGORIES_FETCH_ALL_SUCCESS = `${CATEGORY_PREFIX} Fetch all categories Success`;
export const CATEGORIES_FETCH_ALL_FAIL = `${CATEGORY_PREFIX} Fetch all categories Fail`;

// Fetch one category
export const CATEGORY_FETCH_INIT = `${CATEGORY_PREFIX} Fetch one category Init`;
export const CATEGORY_FETCH_SUCCESS = `${CATEGORY_PREFIX} Fetch one category Success`;
export const CATEGORY_FETCH_FAIL = `${CATEGORY_PREFIX} Fetch one category Fail`;

// Create new category
export const CATEGORY_CREATE_INIT = `${CATEGORY_PREFIX} Create category Init`;
export const CATEGORY_CREATE_SUCCESS = `${CATEGORY_PREFIX} Create category Success`;
export const CATEGORY_CREATE_FAIL = `${CATEGORY_PREFIX} Create category Fail`;

// Update existing category
export const CATEGORY_UPDATE_INIT = `${CATEGORY_PREFIX} Update category Init`;
export const CATEGORY_UPDATE_SUCCESS = `${CATEGORY_PREFIX} Update category Success`;
export const CATEGORY_UPDATE_FAIL = `${CATEGORY_PREFIX} Update category Fail`;

// Delete category
export const CATEGORY_DELETE_INIT = `${CATEGORY_PREFIX} Delete category Init`;
export const CATEGORY_DELETE_SUCCESS = `${CATEGORY_PREFIX} Delete category Success`;
export const CATEGORY_DELETE_FAIL = `${CATEGORY_PREFIX} Delete category Fail`;

// Fetch all categories
export const categoriesFetchAllInit = createAction(CATEGORIES_FETCH_ALL_INIT);
export const categoriesFetchAllSuccess = createAction(
  CATEGORIES_FETCH_ALL_SUCCESS,
  props<{ payload: Array<IncidentCategory> }>()
);
export const categoriesFetchAllFail = createAction(CATEGORIES_FETCH_ALL_FAIL, props<Error>());

// Fetch one category
export const categoryFetchInit = createAction(CATEGORY_FETCH_INIT, props<{ id: number }>());
export const categoryFetchSuccess = createAction(CATEGORY_FETCH_SUCCESS, props<{ payload: IncidentCategory }>());
export const categoryFetchFail = createAction(CATEGORY_FETCH_FAIL, props<Error>());

// Create new category
export const categoryCreateInit = createAction(CATEGORY_CREATE_INIT, props<{ payload: IncidentCategory }>());
export const categoryCreateSuccess = createAction(CATEGORY_CREATE_SUCCESS, props<{ payload: IncidentCategory }>());
export const categoryCreateFail = createAction(CATEGORY_CREATE_FAIL, props<Error>());

// Update category
export const categoryUpdateInit = createAction(CATEGORY_UPDATE_INIT, props<{ payload: IncidentCategory }>());
export const categoryUpdateSuccess = createAction(CATEGORY_UPDATE_SUCCESS, props<{ payload: IncidentCategory }>());
export const categoryUpdateFail = createAction(CATEGORY_UPDATE_FAIL, props<Error>());

// Delete category
export const categoryDeleteInit = createAction(CATEGORY_DELETE_INIT, props<{ payload: IncidentCategory }>());
export const categoryDeleteSuccess = createAction(CATEGORY_DELETE_SUCCESS, props<{ payload: IncidentCategory }>());
export const categoryDeleteFail = createAction(CATEGORY_DELETE_FAIL, props<Error>());
