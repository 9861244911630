/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, Actions, ofType, act } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';

import { CameraService } from '@cameras/services/camera/camera.service';

import { Camera, CameraPagination } from '@cameras/models/cameras/cameras.models';
import {
  CAMERAS_FETCH_INIT,
  CAMERA_FETCH_INIT,
  camerasFetchSuccess,
  camerasFetchFail,
  cameraFetchSuccess,
  cameraFetchFail,
  CAMERA_UPDATE_INIT,
  cameraUpdateSuccess,
  cameraUpdateFail,
  CAMERA_CREATE_INIT,
  cameraCreateSuccess,
  cameraCreateFail,
  CAMERA_START_PIPELINE_SESSION_INIT,
  cameraStartPipelineSessionSuccess,
  cameraStartPipelineSessionFail,
  CAMERA_STOP_PIPELINE_SESSION_INIT,
  cameraStopPipelineSessionSuccess,
  cameraStopPipelineSessionFail,
  CAMERA_DELETE_INIT,
  cameraDeleteSuccess,
  cameraDeleteFail,
  CAMERAS_FETCH_UNPAGINATED_INIT,
  camerasFetchUnpaginatedSuccess,
  camerasFetchUnpaginatedFail,
} from '@cameras/store/cameras/cameras.actions';
import { fetchCameraAnomaliesSuccess, FETCH_CAMERA_ANOMALIES_INIT } from '@core/store/anomalies/anomalies.actions';
import { Anomaly } from '@core/models/anomaly/anomaly.model';

interface CameraAction {
  readonly type: string;
  id?: number;
  camera?: Camera;
}

export interface CameraSessionAction {
  readonly type: string;
  payload: {
    camera?: Camera;
  };
}

export interface CameraAnomalyAction {
  readonly type: string;
  payload: {
    cameraId: number;
    pageLimit: number;
    pageOffset: number;
  };
}

@Injectable()
export class CamerasEffects {
  constructor(private actions$: Actions, private cameraService: CameraService, private snackBar: MatSnackBar) {}

  fetchCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERAS_FETCH_INIT),
      mergeMap((action: CameraAnomalyAction) =>
        this.cameraService.fetchAll(action.payload.pageLimit, action.payload.pageOffset).pipe(
          map((cameraPagination: CameraPagination) => camerasFetchSuccess({ payload: cameraPagination })),
          catchError((error: Error) => of(camerasFetchFail(error)))
        )
      )
    )
  );

  fetchCamerasUnpaginated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERAS_FETCH_UNPAGINATED_INIT),
      mergeMap(() =>
        this.cameraService.fetchAllUnpaginated().pipe(
          map((cameraPagination: CameraPagination) => camerasFetchUnpaginatedSuccess({ payload: cameraPagination })),
          catchError((error: Error) => {
            console.warn(error);
            return of(camerasFetchUnpaginatedFail(error));
          })
        )
      )
    )
  );

  fetchCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERA_FETCH_INIT),
      mergeMap((action: CameraAction) =>
        this.cameraService.fetchCamera(action.id).pipe(
          map((camera: Camera) => cameraFetchSuccess({ payload: { camera } })),
          catchError((error: Error) => of(cameraFetchFail(error)))
        )
      )
    )
  );

  createCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERA_CREATE_INIT),
      mergeMap((action: CameraAction) =>
        this.cameraService.createCamera(action.camera).pipe(
          map((camera: Camera) => {
            this.snackBarMessages('Camera created', 'snackbar-success-background');
            return cameraCreateSuccess({ payload: { camera } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(`Failed: ${eMessage ? eMessage : 'Camera Create'}`, 'snackbar-error-background');
            return of(cameraCreateFail(error));
          })
        )
      )
    )
  );

  updateCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERA_UPDATE_INIT),
      mergeMap((action: CameraAction) =>
        this.cameraService.updateCamera(action.camera).pipe(
          map((camera: Camera) => {
            this.snackBarMessages('Camera Updated', 'snackbar-success-background');
            return cameraUpdateSuccess({ payload: { camera } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(`Failed: ${eMessage ? eMessage : 'Camera Update'}`, 'snackbar-error-background');
            return of(cameraUpdateFail(error));
          })
        )
      )
    )
  );

  deleteCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERA_DELETE_INIT),
      mergeMap((action: CameraAction) =>
        this.cameraService.deleteCamera(action.camera).pipe(
          map((camera: Camera) => {
            this.snackBarMessages(`Camera ${camera.name} deleted`, 'snackbar-success-background');
            return cameraDeleteSuccess({ payload: { camera } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(`Failed: ${eMessage ? eMessage : 'Camera Delete'}`, 'snackbar-error-background');
            return of(cameraDeleteFail(error));
          })
        )
      )
    )
  );

  startCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERA_START_PIPELINE_SESSION_INIT),
      mergeMap(({ payload }: CameraSessionAction) =>
        this.cameraService.startCamera(payload.camera).pipe(
          map((camera: Camera) => {
            this.snackBarMessages(`Started ${payload.camera.name}`, 'snackbar-success-background');
            return cameraStartPipelineSessionSuccess({
              payload: {
                camera: {
                  ...payload.camera,
                  status: camera.status,
                  reasonOfFailure: camera.reasonOfFailure,
                },
              },
            });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(
              `Failed: ${eMessage ? eMessage : 'Camera pipeline action'}`,
              'snackbar-error-background'
            );
            return of(cameraStartPipelineSessionFail({ payload: { error, camera: payload.camera } }));
          })
        )
      )
    )
  );

  stopCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CAMERA_STOP_PIPELINE_SESSION_INIT),
      mergeMap(({ payload }: CameraSessionAction) =>
        this.cameraService.stopCamera(payload.camera).pipe(
          map((status) => {
            this.snackBarMessages(`Stopped ${payload.camera.name}`, 'snackbar-error-background');
            return cameraStopPipelineSessionSuccess({ payload: { camera: payload.camera } });
          }),
          catchError((error: Error) => {
            const eMessage = this.errorMessage(error);
            this.snackBarMessages(
              `Failed: ${eMessage ? eMessage : 'Camera pipeline action'}`,
              'snackbar-error-background'
            );
            return of(cameraStopPipelineSessionFail({ payload: { error, camera: payload.camera } }));
          })
        )
      )
    )
  );

  fetchCameraAnomalies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FETCH_CAMERA_ANOMALIES_INIT),
      mergeMap((action: CameraAnomalyAction) =>
        this.cameraService
          .fetchCameraAnomalies(action.payload.cameraId, action.payload.pageLimit, action.payload.pageOffset)
          .pipe(
            map((anomalies: Anomaly[]) => fetchCameraAnomaliesSuccess({ cameraId: action.payload.cameraId, anomalies })),
            catchError((error: Error) => of(camerasFetchFail(error)))
          )
      )
    )
  );

  private errorMessage(error: Error): string {
    try {
      return JSON.stringify((error as HttpErrorResponse).error);
    } catch {
      return null;
    }
  }

  private snackBarMessages(message: string, type: string): void {
    this.snackBar.open(message, null, {
      duration: 5000,
      panelClass: [type],
    });
  }
}
