import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Camera } from '@cameras/models/cameras/cameras.models';

@Injectable({
  providedIn: 'root',
})
export class DataTableEmitterService {
  anomaliesListClicked = new EventEmitter();
  anomaliesListClickedS: Subscription;

  constructor() {}

  anomaliesListButtonClicked(camera: Camera, data: any): void {
    this.anomaliesListClicked.emit({ camera, template: data });
  }
}
