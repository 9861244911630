import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FooterIcon } from '@core/models/footer-icon/footer-icon';

@Component({
  selector: 'icetana-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() icons: Array<FooterIcon>;
  @Output() iconClicked = new EventEmitter<FooterIcon>();

  constructor() {}

  ngOnInit(): void {}

  onClick(icon: FooterIcon): void {
    this.iconClicked.next(icon);
  }
}
