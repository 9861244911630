/* eslint-disable @typescript-eslint/naming-convention */
import { GridsterItem } from 'angular-gridster2';

export class Anomaly {
  id: number;
  camera: number;
  cameraName: string;
  cameraGuid?: string;
  hlsUri?: string;
  timezone?: string;
  start: string;
  end: string;
  guid: string;
  videoUrl: string;
  videoFileName: string;
  videoFileExtension: string;
  anomalyDiagnostic?: {
    modelName: string;
    classLabel: string;
    categoryLabel: string;
    usedCategoryManager: boolean;
    diagnosticsPath: string;
  };
  created: string;
  updated: string;
}

export class AnomalyAPI {
  id: number;
  camera: number;
  camera_name: string;
  camera_guid: string;
  hls_uri: string;
  timezone: string;
  start: string;
  end: string;
  guid: string;
  video_url: string;
  video_file_name: string;
  video_file_extension: string;
  class_label?: string;
  category_label?: string;
  diagnostics_path?: string;
  model_name?: string;
  used_category_manager?: boolean;
  created: string;
  updated: string;
}

export class CameraAnomaly {
  [cameraId: number]: Anomaly[];
}

export const anomalySerializer = ({
  camera_name,
  camera_guid,
  hls_uri,
  video_url,
  video_file_name,
  video_file_extension,
  class_label,
  category_label,
  diagnostics_path,
  model_name,
  used_category_manager,
  ...object
}: AnomalyAPI, url?: string) =>
  ({
    cameraName: camera_name,
    cameraGuid: camera_guid,
    hlsUri: hls_uri,
    videoUrl: video_url,
    videoFileName: video_file_name,
    videoFileExtension: video_file_extension,
    anomalyDiagnostic: {
      modelName: model_name,
      classLabel: class_label,
      categoryLabel: category_label,
      usedCategoryManager: used_category_manager,
      diagnosticsPath: url && diagnostics_path
        ? `${url}/${diagnostics_path}`
        : diagnostics_path,
    },
    ...object,
  } as Anomaly);

export const anomalyDeserializer = ({
  cameraName,
  cameraGuid,
  hlsUri,
  videoUrl,
  videoFileName,
  videoFileExtension,
  anomalyDiagnostic,
  ...object
}: Anomaly) =>
  ({
    camera_name: cameraName,
    camera_guid: cameraGuid,
    hls_uri: hlsUri,
    video_url: videoUrl,
    video_file_name: videoFileName,
    video_file_extension: videoFileExtension,
    class_label: anomalyDiagnostic?.classLabel,
    category_label: anomalyDiagnostic?.categoryLabel,
    diagnostics_path: anomalyDiagnostic?.diagnosticsPath,
    model_name: anomalyDiagnostic?.modelName,
    used_category_manager: anomalyDiagnostic?.usedCategoryManager,
    ...object,
  } as AnomalyAPI);

// Live Anomalies
export enum LiveAnomalyStatus {
  Start = 'start',
  Update = 'update',
  End = 'end',
}

export class LiveAnomaly {
  sender: string;
  timestamp: string;
  body: {
    anomalyId?: number;
    status: LiveAnomalyStatus;
    cameraGuid: string;
    cameraName: string;
    anomalyGuid: string;
    hlsUri: string;
    start: number;
    end?: number;
    anomalyDiagnostic?: {
      modelName: string;
      classLabel: string;
      categoryLabel: string;
      usedCategoryManager: boolean;
      diagnosticsPath: string;
    };
    timezone?: string;
  };
}


export interface LiveAnomalyTile extends GridsterItem {
  liveAnomaly: LiveAnomaly;
  priority: number;
  deleteFlag: boolean;
  pinned: boolean;
}

export class LiveAnomalyAPI {
  sender: string;
  timestamp: string;
  body: {
    anomaly_id?: number;
    status: LiveAnomalyStatus;
    camera_guid: string;
    camera_name: string;
    anomaly_guid: string;
    hls_uri: string;
    start: number;
    end?: number;
    anomaly_diagnostic?: {
      model_name: string;
      class_label: string;
      category_label: string;
      used_category_manager: boolean;
      diagnostics_path: string;
    };
    timezone?: string;
  };
}

export const liveAnomalySerializer = ({ body, ...object }: LiveAnomalyAPI, url?: string) =>
  ({
    body: {
      anomalyId: body.anomaly_id,
      cameraGuid: body.camera_guid,
      cameraName: body.camera_name,
      anomalyGuid: body.anomaly_guid,
      hlsUri: body.hls_uri,
      anomalyDiagnostic: {
        modelName: body.anomaly_diagnostic?.model_name,
        classLabel: body.anomaly_diagnostic?.class_label,
        categoryLabel: body.anomaly_diagnostic?.category_label,
        usedCategoryManager: body.anomaly_diagnostic?.used_category_manager,
        diagnosticsPath: url && body.anomaly_diagnostic?.diagnostics_path
          ? `${url}/${body.anomaly_diagnostic?.diagnostics_path}`
          : body.anomaly_diagnostic?.diagnostics_path,
      },
      ...body,
    },
    ...object,
  } as LiveAnomaly);

export const liveAnomalyDeserializer = ({ body, ...object }: LiveAnomaly) =>
  ({
    body: {
      anomaly_id: body.anomalyId,
      camera_guid: body.cameraGuid,
      camera_name: body.cameraName,
      anomaly_guid: body.anomalyGuid,
      hls_uri: body.hlsUri,
      ...body,
    },
    ...object,
  } as LiveAnomalyAPI);

export class LiveWallTile {
  [index: string]: LiveAnomaly;
}

export class AnomalyDetail {
  id: number;
  startTime?: string;
  endTime?: string;
  videoUrl?: string;
  videoLive? = false;
  timezone?: string;
}
