import { createReducer, on } from '@ngrx/store';

import { HighlightGridPlaybackState, PlaybackSpeedOption } from '@highlight-grid/constants/highlight-grid.constants';
import { Anomaly } from '@core/models/anomaly/anomaly.model';

import { EventStreamState } from '@shared/constants/event-stream-state.constant';
import {
  metricsAnomaliesInit,
  metricsAnomaliesSuccess,
  metricsAnomaliesFail,
  highlightGridAnomaliesInit,
  highlightGridAnomaliesSuccess,
  highlightGridAnomaliesFail,
  highlightGridSpeedConfig,
  highlightGridPlaybackState,
  fetchCameraAnomaliesFail,
  fetchCameraAnomaliesInit,
  fetchCameraAnomaliesSuccess,
} from '@core/store/anomalies/anomalies.actions';

export interface AnomalyState {
  state: EventStreamState;
  anomaly: Anomaly;
  metricAnomalies: Anomaly[];
  anomaliesHighlight: Anomaly[];
  anomaliesHighlightPlaybackState: HighlightGridPlaybackState;
  anomaliesHighlightPlaybackSpeed: number;
  entities: { [cameraId: number]: Anomaly[] };
  success: boolean;
  loading: boolean;
  fail: boolean;
}

export const initialAnomalyState: AnomalyState = {
  state: EventStreamState.CLOSED,
  anomaly: null,
  metricAnomalies: null,
  anomaliesHighlight: null,
  anomaliesHighlightPlaybackSpeed: Number(PlaybackSpeedOption.Normal),
  anomaliesHighlightPlaybackState: HighlightGridPlaybackState.playing,
  entities: {},
  success: false,
  loading: false,
  fail: false,
};

export const anomalyReducer = createReducer(
  initialAnomalyState,
  on(
    fetchCameraAnomaliesFail,
    metricsAnomaliesFail,
    highlightGridAnomaliesFail,
    (state) => ({
      ...state,
      loading: false,
      fail: true,
    })
  ),
  // Fetch Camera Anomalies
  on(fetchCameraAnomaliesInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(fetchCameraAnomaliesSuccess, (state, payload) => {
    let cameraAnomalies = [];
    if (state.entities[payload.cameraId]) {
      cameraAnomalies = [...state.entities[payload.cameraId]];
    }
    const anomalyIds = new Set(cameraAnomalies.map((item) => item.id));
    cameraAnomalies = [...cameraAnomalies, ...payload.anomalies.filter((anomaly) => !anomalyIds.has(anomaly.id))];

    return {
      ...state,
      loading: false,
      success: true,
      entities: {
        ...state.entities,
        [payload.cameraId]: cameraAnomalies,
      },
      state: EventStreamState.CLOSED,
    };
  }),

  // Highlight Grid Anomalies
  on(highlightGridAnomaliesInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(highlightGridAnomaliesSuccess, (state, { payload }) => ({
    ...state,
    anomaliesHighlight: payload.anomalies,
    loading: false,
    success: true,
  })),
  // anomalies highlight grid speed configuration reducer
  on(highlightGridSpeedConfig, (state, { payload }) => ({
    ...state,
    anomaliesHighlightPlaybackSpeed: payload.speed,
  })),
  // anomalies highlight grid playback state reducer
  on(highlightGridPlaybackState, (state, { payload }) => ({
    ...state,
    anomaliesHighlightPlaybackState: payload.playbackState,
  })),
  // Metrics Anomalies
  on(metricsAnomaliesInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(metricsAnomaliesSuccess, (state, { payload }) => ({
    ...state,
    metricAnomalies: payload.anomalies,
    loading: false,
    success: true,
  }))
);
