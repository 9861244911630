import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@environments/environment';
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ServerModule } from '@server/server.module';
import { CoreModule } from '@core/core.module';
import { AppComponent } from '@app/app.component';
import { reducers, metaReducers } from '@app/store';

import { AboutDialogComponent } from '@core/dialogs/about-dialog/about-dialog.component';
import { AnomalyEffects } from '@core/store/anomalies/anomalies.effects';
import { LiveAnomalyEffects } from '@core/store/live-anomalies/anomalies.effects';
import { LiveWallEffects } from '@core/store/livewall/livewall.effects';
import { IncidentsEffects } from '@incidents/store/incidents.effects';
import { IncidentCategoriesEffects } from '@incidents/store/categories.effects';
import { IncidentStatusesEffects } from '@incidents/store/statuses.effects';
import { PermissionsModule } from '@permissions/permissions.module';

import { AuthService } from '@auth/services/auth.service';
import { AUTH_ALLOWED_DOMAINS, AUTH_DISALLOWED_ROUTES } from '@auth/constants/auth.constants';
import { CamerasEffects } from '@cameras/store/cameras/cameras.effects';

const jwtOptionsFactory = (authService: AuthService) => {
  const allowedDomains = AUTH_ALLOWED_DOMAINS;
  const disallowedRoutes = AUTH_DISALLOWED_ROUTES;
  return {
    tokenGetter: (request) => authService.getValidToken(request.url).toPromise().then((token) => token?.access || ''),
    allowedDomains,
    disallowedRoutes,
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AboutDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    ServerModule,
    PermissionsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AnomalyEffects,
      LiveAnomalyEffects,
      LiveWallEffects,
      IncidentsEffects,
      IncidentCategoriesEffects,
      IncidentStatusesEffects,
      CamerasEffects,
    ]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
