import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  IncidentCategory,
  IncidentCategoryAPI,
  IncidentCategoryAPIPagination,
  incidentCategoryDeserializer,
  IncidentCategoryPagination,
  incidentCategoryPaginationSerializer,
  incidentCategorySerializer,
} from '@incidents/models/incident.models';
import { mockIncidentCategories } from '@incidents/constants/mock.values';
import { ServerService } from '@server/services/server.service';
import { Server } from '@server/models/server.model';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  private get categoriesBaseUrl(): string {
    return `${this.serverService.currentServer.api}incidents/categories/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  public fetchMockCategories(): Observable<Array<IncidentCategoryAPI>> {
    return new Observable((o) => {
      o.next(mockIncidentCategories.map((r) => incidentCategorySerializer(r)));
      o.complete();
    });
  }

  public fetchAllCategories(): Observable<IncidentCategoryPagination> {
    return this.http
      .get<IncidentCategoryAPIPagination>(this.categoriesBaseUrl)
      .pipe(map((response) => incidentCategoryPaginationSerializer(response)))
      .pipe(catchError((error) => throwError(error)));
  }

  public fetchCategory(id: number): Observable<IncidentCategory> {
    const endpoint = `${this.categoriesBaseUrl}${id}/`;

    return this.http
      .get<IncidentCategoryAPI>(endpoint)
      .pipe(map((category) => incidentCategorySerializer(category)))
      .pipe(catchError((error) => throwError(error)));
  }

  public updateCategory(data: IncidentCategory): Observable<IncidentCategoryAPI> {
    const { id, ...payload } = data;
    const endpoint = `${this.categoriesBaseUrl}${id}/`;

    return this.http
      .patch<IncidentCategory>(endpoint, { ...payload })
      .pipe(map((category) => incidentCategoryDeserializer(category)))
      .pipe(catchError((error) => throwError(error)));
  }

  public createCategory(data: IncidentCategory): Observable<IncidentCategoryAPI> {
    const { id, ...payload } = data;

    return this.http
      .post<IncidentCategory>(this.categoriesBaseUrl, { ...payload })
      .pipe(map((category) => incidentCategoryDeserializer(category)))
      .pipe(catchError((error) => throwError(error)));
  }

  deleteCategory(category: IncidentCategory): Observable<IncidentCategory> {
    const endpoint = `${this.categoriesBaseUrl}${category.id}/`;

    return this.http
      .delete<any>(endpoint)
      .pipe(map(() => category))
      .pipe(catchError((error) => throwError(error)));
  }
}
