import { createReducer } from '@ngrx/store';

import { ProtectedFeature } from '@permissions/models/permissions.models';

import { protectedCameras } from '@permissions/store/features/cameras';
import { protectedClients } from '@permissions/store/features/clients';
import { protectedSites } from '@permissions/store/features/sites';
import { protectedUsers } from '@permissions/store/features/users';
import { protectedLivewall } from '@permissions/store/features/livewall';
import { protectedHighlights } from '@permissions/store/features/highlights';
import { protectedIncidents } from '@permissions/store/features/incidents';

export interface PermissionsState {
  protectedFeatures: ProtectedFeature[];
}

export const initialPermissionsState: PermissionsState = {
  protectedFeatures: [
    ...protectedCameras,
    ...protectedClients,
    ...protectedSites,
    ...protectedUsers,
    ...protectedLivewall,
    ...protectedHighlights,
    ...protectedIncidents,
  ],
};

export const permissionsReducer = createReducer(initialPermissionsState);
