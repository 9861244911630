/* eslint-disable @typescript-eslint/member-ordering */
import { Component, HostListener, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppState } from '@app/store';
import { highlightGridPlaybackState, highlightGridSpeedConfig } from '@core/store/anomalies/anomalies.actions';
import { HighlightGridPlaybackState } from '@highlight-grid/constants/highlight-grid.constants';
import { highlightGridPlaybackSpeed } from '@core/store/anomalies/anomalies.selectors';
import { GridEmitterService } from '@highlight-grid/services/grid-emitter.service';
import { IncidentEmitterService } from '@highlight-grid/services/incident-emitter.service';

@Component({
  selector: 'icetana-playback-controls',
  templateUrl: './playback-controls.component.html',
  styleUrls: ['./playback-controls.component.scss'],
})
export class PlaybackControlsComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<AppState>,
    private gridEmitterService: GridEmitterService,
    private incidentEmitterService: IncidentEmitterService
  ) {}

  @Output() replayEmitter = new EventEmitter<Event>();

  private ngUnsubscribe = new Subject<void>();

  activeSpeed = 4;

  replayIcon = { label: 'replay', iconClass: 'icon-replay' };

  speedList = [
    /* temporary fix for broken state
    { value: -6, label: '-6x', containerClass: 'rewind-6x', iconClass: 'icon-rewind-3' },
    { value: -2, label: '-2x', containerClass: 'rewind-2x', iconClass: 'icon-rewind-2' },
    { value: -1, label: '-1x', containerClass: 'rewind-1x', iconClass: 'icon-rewind-1' },
     */
    { value: 0, label: '0', containerClass: 'pause', iconClass: 'icon-pause' },
    { value: 1, label: '1x', containerClass: 'play-1x', iconClass: 'icon-play-1' },
    { value: 2, label: '2x', containerClass: 'play-2x', iconClass: 'icon-play-2' },
    { value: 6, label: '6x', containerClass: 'play-6x', iconClass: 'icon-play-3' },
  ];

  pause = this.speedList[0];

  stopIndex = this.speedList.indexOf(this.pause);

  /**
   * Passes replay click to emitter service for intercepting by GridScrollComponent
   *
   * @param event Event
   */
  onReplayClicked(event: Event): void {
    this.gridEmitterService.replayButtonClicked(event);
  }

  onCellRightClicked(): void {
    this.onSpeedChange(this.stopIndex);
  }

  onSpeedChange(speedIndex: number): void {
    const oldSpeed = this.activeSpeed;

    if (oldSpeed !== speedIndex) {
      const playbackState =
        this.stopIndex === speedIndex ? HighlightGridPlaybackState.paused : HighlightGridPlaybackState.playing;
      // console.warn('speed', this.speedList[speedIndex].value);

      this.store.dispatch(highlightGridPlaybackState({ payload: { playbackState } }));
      this.store.dispatch(highlightGridSpeedConfig({ payload: { speed: this.speedList[speedIndex].value } }));

      this.activeSpeed = speedIndex;
    }
  }

  isActiveSpeed(speedIndex: number): string {
    return '';
  }

  onDownloadReelClicked(): void {
    this.gridEmitterService.downloadReelButtonClicked();
  }

  onDownloadZippedClicked(): void {
    this.gridEmitterService.downloadZippedButtonClicked();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(highlightGridPlaybackSpeed))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((speed) => {
        this.activeSpeed = this.speedList.findIndex((e) => e.value === speed);
      });
    this.incidentEmitterService.incidentDialog.subscribe((response) => {
      this.onCellRightClicked();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    /** We don't want the @HostListener to be active when the dialog box is up */
    if (document.getElementsByTagName('icetana-report-dialog').length === 1) {
      return;
    }
    switch (event.code) {
      case 'ArrowLeft':
        if (this.activeSpeed > 0) {
          this.onSpeedChange(this.activeSpeed - 1);
        }
        break;
      case 'ArrowRight':
        if (this.activeSpeed < this.speedList.length - 1) {
          this.onSpeedChange(this.activeSpeed + 1);
        }
        break;
      case 'Space':
        if (this.activeSpeed === this.stopIndex) {
          this.onSpeedChange(this.stopIndex + 1);
        }
        else {
          this.onSpeedChange(this.stopIndex);
        }
        break;
    }
  }
}
