/* eslint-disable @typescript-eslint/naming-convention */
export interface LiveWallSettingsAPI {
  id: number;
  livewall_columns: number;
  livewall_rows: number;
}

/**
 * This interface is a subset of LiveWallSettingsAPI
 */
export interface LiveWallGridSettings {
  livewall_columns: number;
  livewall_rows: number;
}

export const liveWallGridSerializer = (data: LiveWallSettingsAPI) => ({
    ...data[0],
  } as LiveWallGridSettings);

export const liveWallSettingsDeserializer = (payload: LiveWallGridSettings) =>
  ({
    ...payload,
  } as LiveWallSettingsAPI);
