/* eslint-disable @typescript-eslint/naming-convention */
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SSE_STREAM_ROUTE, API_REGEX, AUTH, ANOMALY_DIAGNOSTICS_PATH } from '@core/constants/app.constants';
import { AUTH_ROUTE_LOGIN, AUTH_ROUTE_REFRESH, AUTH_ROUTE_TEST } from '@auth/constants/auth.constants';
import { AuthService } from '@auth/services/auth.service';
import { ServerService } from '@server/services/server.service';

const unauthorizedRoutesNoRefresh = [
  `${AUTH}/${AUTH_ROUTE_LOGIN}`,
  `${AUTH}/${AUTH_ROUTE_REFRESH}`,
  `${AUTH}/${AUTH_ROUTE_TEST}`,
];


@Injectable()
export class HttpGlobalInterceptor implements HttpInterceptor {

  constructor(
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private serverService: ServerService,
  ) {}



  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customRequest = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-CSRFToken': this.getCSRFToken,
      }),
    });

    return next.handle(customRequest).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          const urlInIgnoreInterceptorList = this.ignoreInterceptorList().some((val) => customRequest.url.includes(val));

          // handling 401 responses
          if (error.status === 401) {
            const urlInUnauthorizedRoutesNoRefresh = unauthorizedRoutesNoRefresh.some((val) => customRequest.url.includes(val));
            if (urlInUnauthorizedRoutesNoRefresh) {
              // 401 errors on the refresh URI indicates expired refresh token
              const errorMessage =
                customRequest.url.includes(unauthorizedRoutesNoRefresh[0])
                ? `Failed to login to ${customRequest.url} with provided credentials`
                : `Failed to refresh token at ${customRequest.url}`;
              this.toastPopup(errorMessage, ['snackbar-error-background'], 10000);
              return from(this.expiredRefreshToken()).pipe(switchMap(() => next.handle(customRequest)));
            } else {
              // 401 on any other route indicates an expired access token
              const token = this.authService.getToken();
              return this.authService.refreshAccessToken(token).pipe(switchMap(() => next.handle(customRequest)));
            }
          // otherwise if url is not in the ignore list
          } else if (!urlInIgnoreInterceptorList) {
            const errorMessage =
              error.status !== 0
                ? `${error.statusText} (${error.status}). ${error.message}`
                : `Cannot connect to ${error.url}. A network error occurred.
                This could be a CORS issue or a dropped internet connection. `;

            this.toastPopup(`Oops: ${errorMessage} `, ['snackbar-error-background'], 10000);
          }
        }
        return throwError(error);
      })
    );
  }

  private ignoreInterceptorList(): any {
    let ignoreList = [];
    // eventstreams
    ignoreList = ignoreList.concat(this.serverService.storedServers.value.map(server => {
      const regexMatch = server.api.match(API_REGEX);
      return `${server.api.split('//')[0]}//${regexMatch[1]}/${SSE_STREAM_ROUTE}/`;
    }));
    // anomaly diagnostics paths
    ignoreList = ignoreList.concat(this.serverService.storedServers.value.map(server => {
      const regexMatch = server.api.match(API_REGEX);
      return `${server.api.split('//')[0]}//${regexMatch[1]}/${ANOMALY_DIAGNOSTICS_PATH}/`;
    }));
    // current running anomalies
    ignoreList = ignoreList.concat(['end=&']);

    return ignoreList;
  }

  private toastPopup(message: string, panelClass: string[], duration: number = 5000): void {
    this.snackBar.open(message, null, {duration, panelClass});
  }

  private expiredRefreshToken(): Promise<boolean> {
    this.authService.logout();
    return this.router.navigate([AUTH, AUTH_ROUTE_LOGIN]);
  }

  private getCookie(name: string): string {
    const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }

    return '';
  }

  private get getCSRFToken(): string {
    return this.getCookie('csrftoken');
  }
}
