<div class="video-container">
  <div *ngIf="videoTitleLeft?.length || videoTitleRight?.length" class="video-container__header">
    <div class="video-container__header__left" [title]="videoTitleLeft">
      {{ videoTitleLeft }}
    </div>
    <div class="video-container__header__right" [title]="videoTitleRight">
      {{ videoTitleRight }}
    </div>
  </div>

  <div class="overlay-container" *ngIf="overlayPath && showOverlay" [id]="setOverlayId()">
    <div class="overlay-image" [style.width]="overlayWidth" [style.height]="overlayHeight" [style.background-image]="generateOverlayUrl(overlayPath)"></div>
  </div>

  <video #video class="video-js vjs-big-play-centered" controls muted fluid playsinline>
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a web browser that
      <a href="https://videojs.com/html5-video-support/" rel="noopener noreferrer" target="_blank">supports HTML5 video</a>
    </p>
  </video>

  <div class="bottom-left incident-exists" *ngIf="incidentExists" matTooltip="Anomaly saved as incident" matTooltipPosition="above">
    <mat-icon aria-hidden="false" aria-label="incident saved">star</mat-icon>
  </div>
  <div *ngIf="videoBottomLeft?.length || videoBottomRight?.length || anomalyModel?.icon || anomalyLabel?.icon" class="video-container__footer">
    <div class="video-container__footer__left" [title]="videoBottomLeft">
      {{ videoBottomLeft }}
    </div>
    <div class="video-container__footer__right" [title]="videoBottomRight">
      {{ videoBottomRight }}
      <div [matTooltip]="anomalyTooltip()" title="" [ngClass]="{'event-info-finished': deleteFlag}" class="event-icons">
        <mat-icon *ngIf="anomalyModel?.icon">{{ anomalyModel?.icon }}</mat-icon>
        <mat-icon *ngIf="anomalyLabel?.icon">{{ anomalyLabel?.icon }}</mat-icon>
      </div>
    </div>
  </div>
</div>
