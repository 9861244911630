import { createAction, props } from '@ngrx/store';

import { Anomaly } from '@core/models/anomaly/anomaly.model';
import { HighlightGridPlaybackState } from '@highlight-grid/constants/highlight-grid.constants';

const ANOMALY_PREFIX = '[ANOMALY]';
const METRICS_PREFIX = '[ANOMALIES METRICS PREFIX]';
const HIGHLIGHT_GRID_PREFIX = '[ANOMALIES HIGHLIGHT GRID PREFIX]';

export const METRICS_ANOMALIES_INIT = `${METRICS_PREFIX} Metrics anomalies loading init`;
export const METRICS_ANOMALIES_SUCCESS = `${METRICS_PREFIX} Metrics anomalies load Success`;
export const METRICS_ANOMALIES_FAIL = `${METRICS_PREFIX} Metrics anomalies load Fail`;

export const HIGHLIGHT_GRID_ANOMALIES_INIT = `${HIGHLIGHT_GRID_PREFIX} Highlight grid anomalies loading init`;
export const HIGHLIGHT_GRID_ANOMALIES_SUCCESS = `${HIGHLIGHT_GRID_PREFIX} Highlight grid anomalies load Success`;
export const HIGHLIGHT_GRID_ANOMALIES_FAIL = `${HIGHLIGHT_GRID_PREFIX} Highlight grid anomalies load Fail`;
export const HIGHLIGHT_GRID_ANOMALIES_SPEED_CONFIG = `${HIGHLIGHT_GRID_PREFIX} Highlight grid anomalies playback speed configuration`;
export const HIGHLIGHT_GRID_ANOMALIES_PLAYBACK_STATE = `${HIGHLIGHT_GRID_PREFIX} Highlight grid anomalies playback state configuration`;

// fetch camera anomalies
export const FETCH_CAMERA_ANOMALIES_INIT = `${ANOMALY_PREFIX} FETCH CAMERA ANOMALIES INIT`;
export const FETCH_CAMERA_ANOMALIES_SUCCESS = `${ANOMALY_PREFIX} FETCH CAMERA ANOMALIES SUCCESS`;
export const FETCH_CAMERA_ANOMALIES_FAIL = `${ANOMALY_PREFIX} FETCH CAMERA ANOMALIES FAIL`;

export const metricsAnomaliesInit = createAction(METRICS_ANOMALIES_INIT);
export const metricsAnomaliesSuccess = createAction(
  METRICS_ANOMALIES_SUCCESS,
  props<{ payload: { anomalies: Anomaly[] } }>()
);
export const metricsAnomaliesFail = createAction(METRICS_ANOMALIES_FAIL, props<Error>());

export const highlightGridAnomaliesInit = createAction(HIGHLIGHT_GRID_ANOMALIES_INIT);
export const highlightGridAnomaliesSuccess = createAction(
  HIGHLIGHT_GRID_ANOMALIES_SUCCESS,
  props<{ payload: { anomalies: Anomaly[] } }>()
);
export const highlightGridAnomaliesFail = createAction(HIGHLIGHT_GRID_ANOMALIES_FAIL, props<Error>());

/** To store highlight grid playback speed state */
export const highlightGridSpeedConfig = createAction(
  HIGHLIGHT_GRID_ANOMALIES_SPEED_CONFIG,
  props<{ payload: { speed: number } }>()
);

/** To store highlight grid playback state */
export const highlightGridPlaybackState = createAction(
  HIGHLIGHT_GRID_ANOMALIES_PLAYBACK_STATE,
  props<{ payload: { playbackState: HighlightGridPlaybackState } }>()
);

// fetch camera anomalies
export const fetchCameraAnomaliesInit = createAction(
  FETCH_CAMERA_ANOMALIES_INIT,
  props<{ payload: { cameraId: number; pageLimit: number; pageOffset: number } }>()
);
export const fetchCameraAnomaliesSuccess = createAction(
  FETCH_CAMERA_ANOMALIES_SUCCESS,
  props<{ cameraId: number; anomalies: Anomaly[] }>()
);
export const fetchCameraAnomaliesFail = createAction(FETCH_CAMERA_ANOMALIES_FAIL, props<Error>());
