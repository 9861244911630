import { handleLogin } from '@shared/backendless/fakeUserAuth';
import { handleSettings } from '@shared/backendless/fakeSettings';
import { handleCamera } from '@shared/backendless/fakeCamera';
import { handleCategories, handleStatus } from '@shared/backendless/fakeIncidents';

import { environment } from '@environments/environment.backendless';
import { EndPoint, Backend } from '@shared/models/backend';
import { ServerService } from '@server/services/server.service';

export const backendUrl = environment.baseUrl;

export const backend = new Backend(
  [
    // auth
    new EndPoint('auth/login', true, handleLogin),
    new EndPoint('settings', true, handleSettings),
    new EndPoint('cameras', true, handleCamera),
    new EndPoint('/incidents/categories/', true, handleCategories),
    new EndPoint('/incidents/status/', true, handleStatus),
  ],
  new ServerService()
);
