/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { TitleSortControls, titleSortIconNames } from '@highlight-grid/constants/highlight-grid.constants';

@Component({
  selector: 'icetana-toolbar-title',
  templateUrl: './toolbar-title.component.html',
  styleUrls: ['./toolbar-title.component.scss'],
})
export class ToolbarTitleComponent implements OnInit {
  filterValue: string;

  @Input() titleId: string;
  @Input() iconName: string;
  @Input() titleText: string;
  @Input() filter?: string | undefined;
  @Input() sortIcons?: Array<TitleSortControls> | undefined;

  constructor(private store: Store<AppState>) {}

  getIconClasses(name: string): string {
    return this.sortIcons.find((i) => i.active === true && i.name === name) ? 'active' : '';
  }

  getSortIconName(name: string): string {
    return titleSortIconNames[name];
  }

  onSortChange(name: string): void {
    this.sortIcons.forEach((icon) => {
      icon.active = (icon.name === name);
    });
  }

  ngOnInit(): void {
    // console.warn(this.titleText, this.sortIcons);
  }
}
