<div class="container__scroll container container-md">
  <form [formGroup]="newServerForm">
    <mat-card class="mat-elevation-z0">
      <mat-card-title> New Server </mat-card-title>
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>New Base URL</mat-label>
          <input type="text" matInput formControlName="baseUrl" name="base" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>New Web Socket URL</mat-label>
          <input type="text" matInput formControlName="wsBaseUrl" name="ws" />
        </mat-form-field>
      </mat-card-content>
      <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap>
        <div fxLayout fxLayoutGap="20px">
          <button id="submit" [disabled]="newServerForm.invalid" mat-raised-button color="primary" (click)="changeServer()">
            Add Server
          </button>
          <button id="navigate" [disabled]="!hasServers" mat-raised-button color="primary" (click)="navigate()">
            Go to Login Page
          </button>
        </div>
        <button mat-raised-button color="warn" (click)="clearServers()">Clear List</button>
      </div>
    </mat-card>
  </form>

  <table mat-table aria-label="Servers" [dataSource]="servers" style="width: 100%">
    <ng-container matColumnDef="API">
      <th mat-header-cell *matHeaderCellDef>API</th>
      <td mat-cell *matCellDef="let server">{{ server.api }}</td>
    </ng-container>

    <ng-container matColumnDef="Websocket">
      <th mat-header-cell *matHeaderCellDef>Websocket</th>
      <td mat-cell *matCellDef="let server">{{ server.websocket }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
    <tr mat-row *matRowDef="let row; columns: columnDefs"></tr>
  </table>
</div>
