<div class="container__scroll container container-md home-component">

  <div fxLayout="row" fxLayoutAlign="center stretch" class="margin-top" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <ng-container *ngIf="!isLoading && durationGreaterThanZero(cameraCountdown)">
    <div class="margin-top countdown-timer">
      <h2 class="text-center">Learning scenes...</h2>
    </div>
    <div class="countdown-timer countdown-table" fxLayout="column nowrap" fxLayoutAlign="center center">
      <h2 class="text-center">Events shown in:</h2>
      <table class="text-center metric-title" aria-label="Coundown Timer">
        <thead style="display:none;"><th></th></thead>
        <tbody>
          <tr><td class="countdown-font">{{ convertDuration(this.cameraCountdown) }}</td></tr>
          <tr>
            <td fxLayout="row nowrap" fxLayoutAlign="center center">
              <div class="countdown-units">Hours</div>
              <div class="countdown-units">Minutes</div>
              <div class="countdown-units">Seconds</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>

  <mat-card *ngIf="!isLoading && (serverList?.length > 1 || !cameras || !cameras.length)">
    <ng-container>
      <mat-card-header fxFill>
        <mat-card-title>
          <h1>
            <ng-container> Welcome to icetana </ng-container>
          </h1>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="text-center">
        <ng-container> icetana will process video streams to identify unusual events. </ng-container>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <ng-container>
          <a *icetanaHasAccessTo="'list_clients'" mat-button [routerLink]="['/clients/list']">
            <mat-icon aria-hidden="false" aria-label="client">business</mat-icon>
            ADD A CLIENT
          </a>
          <a *icetanaHasAccessTo="'list_cameras'" mat-button [routerLink]="['/cameras']">
            <mat-icon aria-hidden="false" aria-label="cameras">videocam</mat-icon>
            ADD A CAMERA
          </a>
        </ng-container>
      </mat-card-actions>
    </ng-container>
  </mat-card>

  <mat-card *ngIf="durationLessThanZero(cameraCountdown) && cameras && cameras.length">
    <ng-container *ngIf="serverList?.length === 1">
      <mat-card-content class="text-center metric-title" *ngIf="!isLoading && metrics">
        <h2>icetana has {{ metrics.totalCameraDuration | number: '1.0-0' | valueTextPluraliser:['not yet reviewed any','reviewed # hour of','reviewed # hours of'] }} footage.</h2>
      </mat-card-content>
      <mat-accordion *ngIf="!isLoading">
        <mat-expansion-panel expanded>
          <mat-expansion-panel-header>
            <mat-panel-title class="bold-title">
              Summary
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card-content *ngIf="!isLoading" class="metrics-container" fxLayout="row wrap" fxLayoutAlign="space-between stretch" fxLayout.sm="column" fxLayout.xs="column" >
            <div fxFlex="50" class="metrics-flex-item">
              <h2 class="text-center">Health</h2>
              <h3 class="text-center">Live</h3>
      
              <div class="metric-item-contents">
                <table aria-describedby="health status of cameras and sites">
                  <thead><th></th><th></th></thead>
                  <tbody>
                    <tr>
                      <td>Number of connected cameras</td>
                      <td class="text-center">{{ metrics ? metrics.currentCameraCount : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Number of connected sites</td>
                      <td class="text-center">{{ metrics ? metrics.currentSiteCount : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Number of cameras currently offline</td>
                      <td class="text-center">{{ metrics ? metrics.currentCamerasOffline.length : '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div fxFlex="50" class="metrics-flex-item">
              <h2 class="text-center">Cameras with highest number of events</h2>
              <h3 class="text-center">Last 7 days</h3>
      
              <div class="metric-item-contents">
                <table aria-describedby="highest camera anomalies in the last 7 days">
                  <thead><th></th><th></th></thead>
                  <tbody *ngIf="metrics">
                    <ng-container *ngFor="let camera of metrics.anomalyMetrics.weeklyTopTotalCameraAnomalyCount">
                      <tr>
                        <td class="cell-overflow">{{ camera.cameraName }}</td>
                        <td class="text-center">{{ camera.eventCount }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tfoot *ngIf="!metrics || !metrics.anomalyMetrics.weeklyTopTotalCameraAnomalyCount.length">
                    <tr>
                      <td colspan="2" class="cell-overflow"><em>No events to display</em></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div fxFlex="50" class="metrics-flex-item">
              <h2 class="text-center">Events</h2>
              <h3 class="text-center">Last 7 days</h3>
      
              <div class="metric-item-contents">
                <table aria-describedby="events">
                  <thead><th></th><th></th></thead>
                  <tbody>
                    <tr>
                      <td>Average number of events found per day</td>
                      <td class="text-center">{{ metrics ? metrics.anomalyMetrics.dailyAverageAnomalies : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Average event rate per day</td>
                      <td class="text-center">{{ metrics ? metrics.anomalyMetrics.dailyAverageAnomalyRate + '%' : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Number of events found</td>
                      <td class="text-center">{{ metrics ? metrics.anomalyMetrics.weeklyAnomalyCount : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Average length of events</td>
                      <td class="text-center">{{ metrics ? metrics.anomalyMetrics.weeklyAverageAnomalyDuration + 's' : '-' }}</td>
                    </tr>
                    <tr>
                      <td>Average number of events per camera</td>
                      <td class="text-center">{{ metrics ? metrics.anomalyMetrics.weeklyAverageCameraAnomalyCount : '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div fxFlex="50" class="metrics-flex-item">
              <h2 class="text-center">Incident Reporting</h2>
              <h3 class="text-center">Last 7 days</h3>
      
              <div class="metric-item-contents">
                <table aria-describedby="incident reporting ">
                  <thead><th></th><th></th></thead>
                  <tbody>
                    <tr>
                      <td>Number of incidents saved</td>
                      <td class="text-center">{{ metrics ? metrics.incidentMetrics.weeklyTotalIncidentCount : 0 }}</td>
                    </tr>
                    <tr>
                      <td>Number of categories used</td>
                      <td class="text-center">{{ metrics ? metrics.incidentMetrics.weeklyCategoryUsageCount : 0 }}</td>
                    </tr>
                    <tr>
                      <td>Most used category</td>
                      <td class="text-center" *ngIf="metrics && metrics.incidentMetrics.weeklyMostUsedCategory">{{ metrics.incidentMetrics.weeklyMostUsedCategory }}</td>
                      <td class="text-center" *ngIf="!metrics || !metrics.incidentMetrics.weeklyMostUsedCategory">-</td>
                    </tr>
                    <tr>
                      <td>Camera with the most saved incidents</td>
                      <td class="cell-overflow text-center" *ngIf="metrics && metrics.incidentMetrics.weeklyHighestIncidentCamera">{{ metrics.incidentMetrics.weeklyHighestIncidentCamera }}</td>
                      <td class="cell-overflow text-center" *ngIf="!metrics || !metrics.incidentMetrics.weeklyHighestIncidentCamera">-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-card-content>
        </mat-expansion-panel>
        
      </mat-accordion>
    </ng-container>
  </mat-card>
</div>
