import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  IncidentStatusAPIPagination,
  IncidentStatusPagination,
  incidentStatusPaginationSerializer,
} from '@incidents/models/incident.models';
import { ServerService } from '@server/services/server.service';

@Injectable({
  providedIn: 'root',
})
export class StatusesService {
  private get statusesBaseUrl(): string {
    return `${this.serverService.currentServer.api}incidents/status/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  public fetchAllStatuses(): Observable<IncidentStatusPagination> {
    return this.http
      .get<IncidentStatusAPIPagination>(this.statusesBaseUrl)
      .pipe(map((response) => incidentStatusPaginationSerializer(response)))
      .pipe(catchError((error) => throwError(error)));
  }
}
