<div class="date-range-radio">
  <div class="timezone-label">
    <span>{{ timezone }}</span>
  </div>
  <mat-radio-group aria-label="Select date range" fxLayout="column" class="no-top-margin">
    <mat-radio-button
      *ngFor="let dateRange of dateRanges$ | async"
      [value]="dateRange.id"
      [checked]="dateRange.checked"
      (change)="setDateRange(dateRange.id)"
      name="dateRange"
    >
      {{ dateRange.name }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-form-field appearance="outline">
    <input
      matInput
      [ngxMatDatetimePicker]="startPicker"
      (ngModelChange)="startDateChange($event)"
      placeholder=""
      [(ngModel)]="startDate"
    />
    <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      #startPicker
      [showSpinners]="true"
      [showSeconds]="true"
      [touchUi]="false"
      [enableMeridian]="false"
      [disableMinute]="false"
      [hideTime]="false"
    >
    </ngx-mat-datetime-picker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <input
      matInput
      [ngxMatDatetimePicker]="endPicker"
      (ngModelChange)="endDateChange($event)"
      placeholder=""
      [(ngModel)]="endDate"
    />
    <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      #endPicker
      [showSpinners]="true"
      [showSeconds]="true"
      [touchUi]="false"
      [enableMeridian]="false"
      [disableMinute]="false"
      [hideTime]="false"
    >
    </ngx-mat-datetime-picker>
  </mat-form-field>
</div>
