<div class="container__scroll container container-lg">
  <div class="close top-right" (click)="close()">
      <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
  </div>
  <mat-card class="mat-elevation-z0">
    <mat-card-title>About</mat-card-title>
    <mat-card-content>
        <div class="content" [fxLayout]="'row wrap'" fxLayout.fxFlex.xs="column" [fxLayoutGap]="'0.3em'"
            fxLayoutAlign="space-between">
            <table class="version-table" aria-label="version table">
              <thead>
                <tr>
                  <th>Server</th>
                  <th>Version</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let server$ of serverAppVersions$">
                  <ng-container *ngIf="server$ | async as server; else indicator">
                    <td>{{ server?.serverName }}</td>
                    <td *ngIf="server?.version">{{ server?.version }}</td>
                    <td class="italic-cell" *ngIf="!server?.version">No version information available</td>
                  </ng-container>
                  <ng-template #indicator>
                    <mat-spinner [diameter]="32"></mat-spinner>
                  </ng-template>
                </tr>
              </tbody>
              <ng-container *ngIf="!serverAppVersions$ || serverAppVersions$.length === 0">
                <tfoot>
                  <tr>
                    <td colspan="2">
                      No information to display
                    </td>
                  </tr>
                </tfoot>
              </ng-container>
            </table>
        </div>
    </mat-card-content>
  </mat-card>
</div>
