/* eslint-disable */
export enum CameraStatus {
  INITIALIZING = 'INITIALIZING',
  STARTING = 'STARTING',
  STARTED = 'STARTED',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  FAILED = 'FAILED',
}

const cameraGuid = '11111111-1111-1111-1111-111111111111';

export const camera = {
  id: 1,
  guid: cameraGuid,
  name: 'Camera 01',
  uri: 'file:///base/path/file-name.mp4',
  hlsUri: `https://localhost/streams/${cameraGuid}/stream.m3u8`,
  description: null,
  username: 'admin',
  password: 'password',
  created: '2020-09-18T08:09:23.534309Z',
  updated: '2020-09-18T08:09:23.534354Z',
  editable: true,
  status: CameraStatus.STARTED,
  reasonOfFailure: null,
  site: 1,
  zones: [],
};

export const cameraPagination = {
  count: 1,
  results: [camera],
};

export const handleCamera = (request) => {
  const queryParam = '?limit';
  if (request.url.includes(queryParam)) {
    return cameraPagination;
  }
  return cameraPagination;
};
