/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { delay, map, retryWhen, takeUntil, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { AppState } from '@app/store';
import { RETRY_DELAY, SERVER_REGEX } from '@core/constants/app.constants';
import { LiveAnomaly, liveAnomalySerializer, LiveAnomalyStatus } from '@core/models/anomaly/anomaly.model';
import {
  liveAnomalyAllConnectionsFinished,
  liveAnomalyCloseAllConnectionsFinished,
  liveAnomalyCloseConnectionSuccess,
  liveAnomalyConnectionInit,
  liveAnomalyConnectionSuccess,
  liveAnomalyConnectionFail,
  liveAnomalyReceiveMessage,
} from '@core/store/live-anomalies/anomalies.actions';
import {
  liveAnomalyStartPlayingOnLiveWallInit,
  liveAnomalyStopPlayingOnLiveWallInit,
} from '@core/store/livewall/livewall.actions';
import { ServerService } from '@server/services/server.service';


@Injectable({
  providedIn: 'root',
})
export class LiveAnomalyWebSocketService {
  private socketsMap = new Map<string, WebSocketSubject<any>>();
  ngUnsubscribe = new Subject<void>();

  constructor(private store: Store<AppState>, private serverService: ServerService) {}

  public async init(): Promise<void> {
    const servers = this.serverService.storedServers.value;
    if (!servers.length) {
      return;
    }

    for (const server of servers) {
      const wsUrl = `${server.websocket}live-anomalies/`;
      if (!this.socketsMap.get(wsUrl)) {
        this.socketsMap.set(
          wsUrl,
          await this.register(wsUrl)
        );
      }
    }
    for (const [url, socket] of this.socketsMap) {
      this.store.dispatch(liveAnomalyConnectionInit({ payload: { url } }));
      this.listenToMessage(socket, url);
    }
    this.store.dispatch(liveAnomalyAllConnectionsFinished());
  }

  extractServerBaseUri(url: string): string {
    const serverProtocol = url.split('//')[0];
    const regexMatch = url.match(SERVER_REGEX);
    return `${serverProtocol}//${regexMatch[1]}`;
  }

  async register(url: string): Promise<any> {
    return this.getNewWebSocket(url);
  }

  private getNewWebSocket(url): WebSocketSubject<any> {
    return webSocket({
      url,
      serializer: (msg) => {
        const message = JSON.stringify(msg);
        return message;
      },
      deserializer: (msg) => msg,
      openObserver: {
        next: () => this.store.dispatch(liveAnomalyConnectionSuccess({ payload: { url } })),
        error: (error) => this.store.dispatch(liveAnomalyConnectionFail({ payload: { url, error: new Error(error)} })),
      },
      closeObserver: {
        next: () => this.store.dispatch(liveAnomalyCloseConnectionSuccess({ payload: { url } })),
        error: (error) => this.store.dispatch(liveAnomalyConnectionFail({ payload: { url, error: new Error(error)} })),
      },
    });
  }

  private retry(errors: Observable<any>, url): Observable<any> {
    return errors.pipe(
      tap((err) => {
        console.error('Live Anomalies Websocket error', err);
        this.store.dispatch(liveAnomalyConnectionFail({ payload: { url, error: new Error(err)} }));
      }),
      delay(RETRY_DELAY)
    );
  }

  listenToMessage(socket: WebSocketSubject<any>, url: string): void {
    socket
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event),
        retryWhen((errors) => this.retry(errors, url))
      )
      .subscribe(
        (next: MessageEvent) => {
          const anomaly: LiveAnomaly = liveAnomalySerializer(JSON.parse(next.data), this.extractServerBaseUri(url));

          this.store.dispatch(liveAnomalyReceiveMessage({ payload: { url } }));

          // Start/Stop Playing on the LiveWall
          if (anomaly.body.status === LiveAnomalyStatus.Start) {
            this.store.dispatch(liveAnomalyStartPlayingOnLiveWallInit({ payload: anomaly }));
          } else if (anomaly.body.status === LiveAnomalyStatus.End) {
            this.store.dispatch(liveAnomalyStopPlayingOnLiveWallInit({ payload: anomaly }));
          }
        },
        (error: string) => this.store.dispatch(liveAnomalyConnectionFail({ payload: { url, error: new Error(error)} })),
        () => this.store.dispatch(liveAnomalyCloseConnectionSuccess({ payload: { url } }))
      );
  }

  close(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
    this.socketsMap.clear();

    this.store.dispatch(liveAnomalyCloseAllConnectionsFinished());
  }
}
