import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AUTH_ROUTE_LOGIN } from '@auth/constants/auth.constants';
import { AUTH } from '@core/constants/app.constants';
import { environment } from '@environments/environment';
import { Server } from '@server/models/server.model';
import { AuthService } from '@auth/services/auth.service';
import { ServerService } from '@server/services/server.service';

@Component({
  selector: 'icetana-server-component',
  templateUrl: './server.component.html',
  styleUrls: ['./server.component.scss'],
})
export class ServerComponent implements OnInit {
  selectedServer: Server | null = null;
  newServerForm: FormGroup = null;

  public columnDefs: string[] = ['API', 'Websocket'];

  constructor(private serverService: ServerService, private fb: FormBuilder, private router: Router, private authService: AuthService) {}

  public get servers(): BehaviorSubject<Server[]> {
    return this.serverService.storedServers;
  }

  public get hasServers(): boolean {
    return this.servers.value.length > 0;
  }

  ngOnInit(): void {
    const currentBuild = environment.localStorageSchemaVersion;
    if (environment.production && currentBuild) {
      this.authService.checkLocalStorageSchemaVersion(currentBuild);
    }

    this.selectedServer = this.serverService.currentServer;
    this.initForm();
  }

  initForm(): void {
    this.newServerForm = this.fb.group({
      baseUrl: new FormControl(this.selectedServer?.api, [Validators.required]),
      wsBaseUrl: new FormControl(this.selectedServer?.websocket, [Validators.required]),
    });
  }

  changeServer(): void {
    const newBaseUrl = this.newServerForm.get('baseUrl').value.replace(/\/?$/, '/') || null;
    const newWsUrl = this.newServerForm.get('wsBaseUrl').value.replace(/\/?$/, '/') || null;
    this.serverService.newServer = { api: newBaseUrl, websocket: newWsUrl };
  }

  navigate(): void {
    this.router.navigate([`${AUTH}/${AUTH_ROUTE_LOGIN}`]);
  }

  clearServers(): void {
    this.serverService.clearServers();
  }
}
