import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import * as moment from 'moment';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState } from '@app/store';
import { selectCamerasEntities, selectCamerasLoading } from '@cameras/store/cameras/cameras.selectors';
import { selectPaginatedClientsEntities, selectPaginatedClientsLoading } from '@clients/store/clients.selectors';
import { HIGHLIGHT_GRID } from '@core/constants/app.constants';
import { metricsSerializer, Metrics } from '@core/models/metrics/metrics.model';
import { MetricsRefreshService } from '@core/services/metrics/metrics-refresh.service';
import { metricAnomalies, anomaliesHighlightGridListLoading } from '@core/store/anomalies/anomalies.selectors';
import {
  dateRangeSetChecked,
  dateRangeSetEnd,
  dateRangeSetStart,
  highlightSetCameraFilter,
  highlightSetIncidentFilter,
  highlightSetIncidentFilterCategories,
} from '@core/store/user-settings/user-settings.actions';
import { selectCategoriesEntities, selectCategoriesLoading } from '@incidents/store/categories.selectors';
import { metricIncidents, selectIncidentsLoading } from '@incidents/store/incidents.selectors';
import { PermissionsService } from '@permissions/services/permissions.service';
import { Server } from '@server/models/server.model';
import { ServerService } from '@server/services/server.service';

@Component({
  selector: 'icetana-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  displayedColumns = [];
  dataSource = new TableVirtualScrollDataSource();

  public metrics: Metrics;
  public isLoading: boolean;
  public isLoadingData: boolean;

  public serverList: Server[];
  public serializer = metricsSerializer;
  public dailyEventMetricOptions = [
    {
      label: 'Event Count',
      value: 'eventCount',
    },
    {
      label: 'Event Duration',
      value: 'eventDuration',
    },
    {
      label: '% Daily Rate',
      value: 'eventDailyRate',
    },
  ];
  public dailyEventMetricSelected = this.dailyEventMetricOptions[0].value;

  hourlyMetricsHeaders = [];
  hourlyMetricsArray = [];

  cameras = null;
  cameraCountdown: moment.Duration = moment.duration(0, 'milliseconds');

  private ngUnsubscribe = new Subject<void>();
  private timerUnsubscribe = new Subject<void>(); //specifically for when countdown timer goes less than zero
  private timerStopCondition$ = merge(this.ngUnsubscribe, this.timerUnsubscribe); // if either emit, kill the timer

  constructor(
    private store: Store<AppState>,
    private serverService: ServerService,
    private permissionsService: PermissionsService,
    private router: Router,
    private metricsRefresh: MetricsRefreshService
  ) {}

  tooltipInfo(camera, date): string {
    const returnObj = camera.dailyEventMetrics.find((dailyEventCount) => dailyEventCount.date === date);
    if (!returnObj) {
      return '';
    }
    const eventCount = returnObj.eventCount;
    const eventDuration = this.convertDuration(moment.duration(returnObj.eventDuration));
    const eventDailyRate = `${returnObj.eventDailyRate.toFixed(2)}%`;
    return `Event Count: ${eventCount}
    Event Duration: ${eventDuration}
    % Daily Rate: ${eventDailyRate}`;
  }

  lookupDateMetric(camera, date, metricKey = this.dailyEventMetricSelected): string {
    const returnValue = camera.dailyEventMetrics.find((dailyEventCount) => dailyEventCount.date === date)?.[metricKey];
    if (!returnValue) {
      return '';
    }
    // verify duration formatting required
    if (metricKey === 'eventDuration') {
      return this.convertDuration(moment.duration(returnValue));
    } else if (metricKey === 'eventDailyRate') {
      return `${returnValue.toFixed(2)}%`;
    } else {
      return returnValue;
    }
  }

  convertDuration(duration): string {
    const totalHours = duration.days() * 24 + duration.hours();
    return [
      ('0' + totalHours).slice(-2),
      ('0' + duration.minutes()).slice(-2),
      ('0' + duration.seconds()).slice(-2),
    ].join(':');
  }

  rowCountTotal(camera): string {
    const returnValue = camera[this.dailyEventMetricSelected].total;
    if (!returnValue) {
      return '';
    }
    // verify duration formatting required
    if (this.dailyEventMetricSelected === 'eventDuration') {
      return this.convertDuration(moment.duration(returnValue));
    } else if (this.dailyEventMetricSelected === 'eventDailyRate') {
      const count = this.metrics.anomalyMetrics.cameraEventCounterDailyMetric.dateRange.length;
      return `${(returnValue / count).toFixed(2)}%`;
    } else {
      return returnValue.toString();
    }
  }

  countDailyTotal(date): string {
    let summedCount = 0;
    this.metrics.anomalyMetrics.cameraEventCounterDailyMetric.cameras.forEach((camera) => {
      const eventCount = camera.dailyEventMetrics.find((dailyEventCount) => dailyEventCount.date === date)?.[
        this.dailyEventMetricSelected
      ];
      if (eventCount) {
        summedCount += eventCount;
      }
    });

    // verify duration formatting required
    if (this.dailyEventMetricSelected === 'eventDuration') {
      return this.convertDuration(moment.duration(summedCount));
    } else if (this.dailyEventMetricSelected === 'eventDailyRate') {
      const count = this.metrics.anomalyMetrics.cameraEventCounterDailyMetric.cameras.length;
      return `${(summedCount / count).toFixed(2)}%`;
    } else {
      return summedCount.toString();
    }
  }

  countTotalEvents(): string {
    let summedCount = 0;
    this.metrics.anomalyMetrics.cameraEventCounterDailyMetric.cameras.forEach((camera) => {
      summedCount += camera[this.dailyEventMetricSelected].total;
    });

    if (!summedCount) {
      return '';
    }

    // verify duration formatting required
    if (this.dailyEventMetricSelected === 'eventDuration') {
      return this.convertDuration(moment.duration(summedCount));
    } else if (this.dailyEventMetricSelected === 'eventDailyRate') {
      const rowCount = this.metrics.anomalyMetrics.cameraEventCounterDailyMetric.cameras.length;
      const colCount = this.metrics.anomalyMetrics.cameraEventCounterDailyMetric.dateRange.length;
      return `${(summedCount / rowCount / colCount).toFixed(2)}%`;
    } else {
      return summedCount.toString();
    }
  }

  colorCalculate(camera, date, metricType = 'cameraEventCounterDailyMetric'): string {
    const END_COLOR = {
      red: 4,
      green: 63,
      blue: 168,
    };
    const START_COLOR = {
      red: 100,
      green: 181,
      blue: 246,
    };

    const minValue = this.metrics.anomalyMetrics[metricType][this.dailyEventMetricSelected].minValue;
    const maxValue = this.metrics.anomalyMetrics[metricType][this.dailyEventMetricSelected].maxValue;

    const interpValue = camera.dailyEventMetrics.find((dailyEventCount) => dailyEventCount.date === date)?.[
      this.dailyEventMetricSelected
    ];
    if (!interpValue) {
      return 'unset';
    }

    const percentValue = (1 / (maxValue - minValue)) * (interpValue - minValue);
    const lerp = (x, y, a) => x * (1 - a) + y * a;
    const redInterp = +lerp(START_COLOR.red, END_COLOR.red, percentValue).toFixed();
    const greenIntep = +lerp(START_COLOR.green, END_COLOR.green, percentValue).toFixed();
    const blueInterp = +lerp(START_COLOR.blue, END_COLOR.blue, percentValue).toFixed();

    // eslint-disable-next-line no-bitwise
    const hexString = '#' + ((1 << 24) + (redInterp << 16) + (greenIntep << 8) + blueInterp).toString(16).slice(1);
    return hexString;
  }

  clickEventMetrics(
    camera,
    date,
    dateFormat = 'YYYY-MM-DD',
    incrementUnit: moment.unitOfTime.DurationConstructor = 'day'
  ): void {
    // check that the cell has a value
    if (this.lookupDateMetric(camera, date) === '') {
      return;
    }

    const minDate = moment(date, dateFormat).startOf(incrementUnit);
    const maxDate = moment(minDate).add(1, incrementUnit); //.endOf(incrementUnit);

    // reset other filters
    this.store.dispatch(highlightSetIncidentFilter({ id: '' }));
    this.store.dispatch(highlightSetIncidentFilterCategories({ incidentFilterCategories: [] }));

    this.store.dispatch(highlightSetCameraFilter({ highlightCameraFilter: [camera.cameraId] }));
    this.store.dispatch(dateRangeSetStart({ date: minDate.toDate() }));
    this.store.dispatch(dateRangeSetEnd({ date: maxDate.toDate() }));
    this.store.dispatch(dateRangeSetChecked({ id: 'custom' }));

    this.router.navigate([HIGHLIGHT_GRID]);
  }

  calculateColWidth(inputArray, percentLeft): string {
    return `${(percentLeft / inputArray.length) * 100}%`;
  }

  getDuration(duration: moment.Duration): number {
    return duration.as('milliseconds');
  }

  durationGreaterThanZero(duration: moment.Duration): boolean {
    return (this.getDuration(duration) > 0);
  }

  durationLessThanZero(duration: moment.Duration): boolean {
    return (this.getDuration(duration) < 0);
  }

  calculateCountdown(): void {
    if (!this.cameras || !this.cameras.length) {
      return;
    }

    const earliestCameraCreated = d3.min(this.cameras, (d) => moment((d as any).created));
    const earliestCountdown = moment(earliestCameraCreated).add(1, 'days');
    const countdown = earliestCountdown.diff(moment());
    this.cameraCountdown = moment.duration(countdown);

    if (this.getDuration(this.cameraCountdown) < 0) {
      this.timerUnsubscribe.next(null);
      this.timerUnsubscribe.complete();
    }
  }

  ngOnInit(): void {
    // countdown timer
    interval(1000)
      .pipe(takeUntil(this.timerStopCondition$))
      .subscribe((x) => {
        this.calculateCountdown();
      });

    this.isLoading = true;
    this.isLoadingData = true;

    this.serverList = this.serverService.storedServers.value as Server[];
    if (this.serverList.length > 1 || !this.permissionsService.hasPermissionFor('list_clients')) {
      this.isLoading = false;
    }

    this.metricsRefresh.initMetricsRefreshTimer();

    const maxDate = moment();
    const minDate = moment().subtract(7, 'days').startOf('day');

    // Bind loading flag to all / or any of the loading statuses of data
    combineLatest([
      this.store.pipe(select(selectCamerasLoading), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectPaginatedClientsLoading), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(anomaliesHighlightGridListLoading), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectIncidentsLoading), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectCategoriesLoading), takeUntil(this.ngUnsubscribe)),
    ]).subscribe(([loadingCameras, loadingClients, loadingAnomalies, loadingIncidents, loadingCategories]) => {
      this.isLoadingData =
        loadingCameras || loadingClients || loadingAnomalies || loadingIncidents || loadingCategories;
    });

    // Actual returned data
    combineLatest([
      this.store.pipe(select(selectCamerasEntities), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectPaginatedClientsEntities), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(metricAnomalies), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(metricIncidents), takeUntil(this.ngUnsubscribe)),
      this.store.pipe(select(selectCategoriesEntities), takeUntil(this.ngUnsubscribe)),
    ]).subscribe(([cameras, clients, anomalies, incidents, categories]) => {
      if (cameras && clients && anomalies && incidents && categories && !this.isLoadingData) {
        // save the cameras for use with countdown timer
        this.cameras = cameras;

        this.metrics = this.serializer(cameras, clients, anomalies, incidents, categories, minDate, maxDate);
        this.isLoading = false;
        this.hourlyMetricsHeaders = ['date'];
        this.hourlyMetricsArray = [];

        this.metrics.anomalyMetrics.cameraEventCounterHourlyMetric.cameras.forEach((camera) => {
          this.hourlyMetricsHeaders.push(camera.cameraName);
        });

        this.metrics.anomalyMetrics.cameraEventCounterHourlyMetric.dateRange.forEach((date) => {
          const rowData = {};

          this.metrics.anomalyMetrics.cameraEventCounterHourlyMetric.cameras.forEach((camera) => {
            const foundDate = camera.dailyEventMetrics.find((eventMetrics) => eventMetrics.date === date);
            if (foundDate) {
              const outputMetrics = {};

              this.dailyEventMetricOptions.forEach((metricType) => {
                outputMetrics[metricType.value] = this.lookupDateMetric(camera, date, metricType.value);
              });

              rowData[camera.cameraName] = {
                date,
                ...outputMetrics,
                cameraId: camera.cameraId,
                cameraName: camera.cameraName,
              };
            } else {
              rowData[camera.cameraName] = {
                date,
                cameraId: camera.cameraId,
                cameraName: camera.cameraName,
                eventCount: '',
                eventDailyRate: '',
                eventDuration: '',
              };
            }
          });

          const rowObj = {
            date,
            ...rowData,
          };
          this.hourlyMetricsArray.push(rowObj);
        });

        this.displayedColumns = this.hourlyMetricsHeaders;
        this.dataSource = new TableVirtualScrollDataSource(this.hourlyMetricsArray);
        this.sortColumns();
      }
    });
  }

  sortColumns(): void {
    this.displayedColumns.sort((a, b) => {
      if (a === 'date') {
        return -1;
      } else if (b === 'date') {
        return +1;
      } else {
        return d3.ascending(a, b);
      }
    });
  }

  cursorFunction(camera, date): string {
    return this.lookupDateMetric(camera, date) === '' ? 'default' : 'pointer';
  }

  ngOnDestroy(): void {
    this.metricsRefresh.stopMetricsRefreshTimer();
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
