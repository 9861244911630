import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store';

import { AUTH as AUTH_ROUTE } from '@core/constants/app.constants';

import { User } from '@auth/models/auth.models';
import { AUTH_ROUTE_FORCE_RESET_PASSWORD } from '@auth/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class ForceResetGuard implements CanActivate {
  user: User | null = null;

  constructor(private router: Router, private store: Store<AppState>) {
    this.store.select('auth').subscribe((data) => (this.user = data.user));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentNavigation = this.router.getCurrentNavigation();
    const redirectState = currentNavigation.extras.state?.redirect;

    if (this.user && this.user.forceResetPassword) {
      this.router.navigate([AUTH_ROUTE, AUTH_ROUTE_FORCE_RESET_PASSWORD], { state: { redirect: redirectState || state.url } });
      return false;
    }
    else {
      return true;
    }
  }
}
