import { createReducer, on } from '@ngrx/store';
import {
  dateRangeSetChecked,
  dateRangeSetEnd,
  dateRangeSetStart,
  highlightSetCameraFilter,
  highlightSetIncidentFilter,
  highlightSetIncidentFilterCategories,
  highlightSetAnomalyModelFilters,
  highlightSetAnomalyLabelFilters,
} from '@core/store/user-settings/user-settings.actions';
import { CameraSwitches, RadioFilterType, Toolbar } from '@highlight-grid/models/settings.model';
import { availableDateRanges, availableIncidentFilters } from '@highlight-grid/constants/highlight-grid.constants';

export interface UserSettingsState {
  userId: number | null;
  cameraSwitches: Array<CameraSwitches>;
  dateRanges: Array<RadioFilterType>;
  dateRangeStart: Date | null;
  dateRangeEnd: Date | null;
  incidentFilter: Array<RadioFilterType>;
  incidentFilterCategories: number[];
  anomalyModelFilters: string[];
  anomalyLabelFilters: string[];
  highlightCameraFilter: number[];
  toolbars: Array<Toolbar>;
  loading: boolean;
}

export const initialUserSettingsState: UserSettingsState = {
  userId: null,
  cameraSwitches: [],
  dateRanges: availableDateRanges,
  dateRangeStart: null,
  dateRangeEnd: null,
  incidentFilter: availableIncidentFilters,
  incidentFilterCategories: [],
  anomalyModelFilters: [],
  anomalyLabelFilters: [],
  highlightCameraFilter: [],
  toolbars: [
    {
      position: 'LEFT',
      expanded: true,
    },
    {
      position: 'RIGHT',
      expanded: true,
    },
  ],
  loading: false,
};

export const userSettingsReducer = createReducer(
  initialUserSettingsState,
  on(dateRangeSetChecked, (state, { id }) => ({
    ...state,
    dateRanges: state.dateRanges.map((rx) => ({ ...rx, checked: rx.id === id })),
    loading: false,
  })),
  on(dateRangeSetStart, (state, { date }) => ({
    ...state,
    dateRangeStart: date,
  })),
  on(dateRangeSetEnd, (state, { date }) => ({
    ...state,
    dateRangeEnd: date,
  })),
  on(highlightSetCameraFilter, (state, { highlightCameraFilter }) => ({
    ...state,
    highlightCameraFilter,
  })),
  on(highlightSetIncidentFilter, (state, { id }) => ({
    ...state,
    incidentFilter: state.incidentFilter.map((rx) => ({ ...rx, checked: rx.id === id })),
    loading: false,
  })),
  on(highlightSetIncidentFilterCategories, (state, { incidentFilterCategories }) => ({
    ...state,
    incidentFilterCategories,
  })),
  on(highlightSetAnomalyModelFilters, (state, { anomalyModelFilters }) => ({
    ...state,
    anomalyModelFilters,
  })),
  on(highlightSetAnomalyLabelFilters, (state, { anomalyLabelFilters }) => ({
    ...state,
    anomalyLabelFilters,
  })),
);
