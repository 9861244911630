<div class="playback-controls">
    <div class="playback-controls__icons">
      <div class="playback-controls__icons__icon-container" (click)="onReplayClicked($event)">
        <div class="playback-label">{{ replayIcon.label }}</div>
        <div class="playback-icon" [class]="replayIcon.iconClass">
          <mat-icon>replay</mat-icon>
        </div>
      </div>
      <div
        *ngFor="let speed of speedList; index as speedIndex"
        class="playback-controls__icons__icon-container"
        [ngClass]="[speed.containerClass, speedIndex === activeSpeed ? 'active' : '']"
        (click)="onSpeedChange(speedIndex)"
      >
        <div class="playback-label">{{ speed.label }}</div>
        <div class="playback-icon" [class]="speed.iconClass"></div>
      </div>
    </div>
  <!-- 
  <div class="playback-controls__icons__icon-container">
    <button mat-button [matMenuTriggerFor]="menu" matTooltip="Download highlights"><mat-icon>get_app</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onDownloadReelClicked()"><mat-icon>videocam</mat-icon>Download whole highlight reel</button>
      <button mat-menu-item (click)="onDownloadZippedClicked()"><mat-icon>archive</mat-icon>Download individual video clips</button>
    </mat-menu>
  </div>
  -->

</div>
