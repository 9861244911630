import { createAction, props } from '@ngrx/store';
import { IncidentStatus } from '@incidents/models/incident.models';

const STATUS_PREFIX = '[INCIDENT_STATUS]';

// Fetch all statuses
export const STATUSES_FETCH_ALL_INIT = `${STATUS_PREFIX} Fetch all statuses Init`;
export const STATUSES_FETCH_ALL_SUCCESS = `${STATUS_PREFIX} Fetch all statuses Success`;
export const STATUSES_FETCH_ALL_FAIL = `${STATUS_PREFIX} Fetch all statuses Fail`;

// Fetch all statuses
export const statusesFetchAllInit = createAction(STATUSES_FETCH_ALL_INIT);
export const statusesFetchAllSuccess = createAction(
  STATUSES_FETCH_ALL_SUCCESS,
  props<{ payload: Array<IncidentStatus> }>()
);
export const statusesFetchAllFail = createAction(STATUSES_FETCH_ALL_FAIL, props<Error>());
