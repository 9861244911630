import { AccessLevel, ProtectedFeature } from '@permissions/models/permissions.models';

export const protectedSites: ProtectedFeature[] = [
  {
    feature: 'create_site',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'update_site',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
  {
    feature: 'delete_site',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'list_sites',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
];
