import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IncidentCategoriesState } from '@incidents/store/categories.reducers';
import { incidentCategoryTreeBuilder } from '@incidents/models/incident.models';

export const REDUCER_CATEGORY_ID = 'incidentCategory';

export const selectCategories = createFeatureSelector<IncidentCategoriesState>(REDUCER_CATEGORY_ID);

export const selectCategoriesEntities = createSelector(selectCategories, (categoriesState) => categoriesState.entities);

export const selectCategoriesEntitiesTree = createSelector(selectCategories, (categoriesState) =>
  incidentCategoryTreeBuilder(categoriesState.entities)
);

export const selectCategory = (index: number) =>
  createSelector(selectCategories, (categoriesState) => categoriesState.entities[index]);

export const selectCategoriesLoading = createSelector(selectCategories, (categoriesState) => categoriesState.loading);

export const selectCategoriesFail = createSelector(selectCategories, (categoriesState) => categoriesState.fail);
