import { Pipe, PipeTransform } from '@angular/core';

/*
 * Takes the given input value (either string or integer)
 * Parses the value, and maps to a given string in stringArray
 * Delineate value passed into output string with '#' symbol
 * Usage:
 *   value | number | valueTextPluraliser:['no','# hour of','# hours of']
 * Example:
 *   {{ 2000 | number | valueTextPluraliser:['no','# hour of','# hours of'] }}
 *   formats to: '2,000 hours of'
*/
@Pipe({name: 'valueTextPluraliser'})
export class ValueTextPluraliser implements PipeTransform {
  transform(value: number | string, stringArray = [] ): string {
    let parsedValue: number = null;
    let outputString: string;
    if (typeof value === 'string') {
      // parse value by removing all non-numeric character
      parsedValue = parseInt(value.replace(/\D/g,''),10);
      outputString = value;
    }
    // only allow integers and not floats
    else if (Number.isInteger(value) && (value >= 0)) {
      parsedValue = value;
      outputString = value.toString();
    }
    else {
      outputString = value.toString();
    }

    // if there's any errors with inputs, return the value as is
    if (!stringArray.length || (parsedValue === null) || isNaN(parsedValue)) {
      return `${outputString}`;
    }
    // check value against number of provided string array
    if (parsedValue >= stringArray.length) {
      // if parsed value exceeds, then take last string from string array
      return stringArray[stringArray.length - 1].replace('#', outputString);
    }
    else {
      // otherwise take the string array at the given position based on parsedValue
      return stringArray[parsedValue].replace('#', outputString);
    }
  }
}
