/* eslint-disable @typescript-eslint/naming-convention */
export type Feature =
  // cameras
  | 'update_camera'
  | 'create_camera'
  | 'delete_camera'
  | 'view_camera'
  | 'list_cameras'
  // clients
  | 'update_client'
  | 'create_client'
  | 'delete_client'
  | 'view_client'
  | 'list_clients'
  // sites
  | 'update_site'
  | 'create_site'
  | 'delete_site'
  | 'view_site'
  | 'list_sites'
  // users
  | 'update_user'
  | 'create_user'
  | 'delete_user'
  | 'view_user'
  | 'list_users'
  // livewall
  | 'view_livewall'
  // highlight grid
  | 'view_highlight_grid'
  // incidents
  | 'update_incident'
  | 'create_incident'
  | 'delete_incident'
  | 'view_incident'
  | 'list_incidents';

export enum AccessLevel {
  Administrator = 0,
  Executive = 1,
  Superviser = 2,
  Operator = 3,
  View = 4,
}

export interface ProtectedFeature {
  feature: Feature;
  allow: AccessLevel[];
}
