import { AccessLevel, ProtectedFeature } from '@permissions/models/permissions.models';

export const protectedUsers: ProtectedFeature[] = [
  {
    feature: 'create_user',
    allow: [AccessLevel.Administrator],
  },
  {
    feature: 'update_user',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
  {
    feature: 'delete_user',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
  {
    feature: 'list_users',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser],
  },
];
