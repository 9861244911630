import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LayoutModule } from '@angular/cdk/layout';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutGapStyleBuilder, FlexLayoutModule } from '@angular/flex-layout';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { GridsterModule } from 'angular-gridster2';

const materialModules = [
  TableVirtualScrollModule,
  ScrollingModule,
  LayoutModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
];

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonVideoIncidentProvider } from '@app/containers/common-video-incident/common-video-incident.component';
import { HttpGlobalInterceptor } from '@core/interceptors/http-global.interceptor';
import { ToolbarTitleComponent } from '@highlight-grid/containers/toolbar-title/toolbar-title.component';
import { DateRangeRadioComponent } from '@highlight-grid/containers/date-range-radio/date-range-radio.component';
import { PlaybackControlsComponent } from '@highlight-grid/containers/playback-controls/playback-controls.component';
import { GridScrollComponent } from '@highlight-grid/containers/grid-scroll/grid-scroll.component';
import { fakeBackendProvider } from '@shared/backendless/backendless';
import { FooterComponent } from '@shared/components/footer/footer.component';
import { IcetanaDataTableComponent } from '@shared/components/icetana-data-table/icetana-data-table.component';
import { NavbarDarkComponent } from '@shared/components/navbar-dark/navbar-dark.component';
import { StatusIndicatorComponent } from '@shared/components/status-indicator/status-indicator.component';
import { VjsPlayerComponent } from '@shared/components/vjs-player/vjs-player.component';
import { CustomNgxDateTimeModule } from '@shared/date-time-picker/date-time-format';
import '@shared/modules/array.prototype.upsert/upsert.module';
import { ValueTextPluraliser } from '@shared/pipes/value-text-pluraliser/value-text-pluraliser.component';
import { ValueToSortByOrderPipe } from '@shared/pipes/sort-by-order-pipe/sort-by-order-pipe';
import { DefaultLayoutGapStyleBuilder } from '@shared/services/layout-gap.service';


@NgModule({
  exports: [
    ReactiveFormsModule,
    FlexLayoutModule,
    ClipboardModule,
    CommonModule,
    ...materialModules,
    GridsterModule,
    IcetanaDataTableComponent,
    VjsPlayerComponent,
    StatusIndicatorComponent,
    NavbarDarkComponent,
    ToolbarTitleComponent,
    DateRangeRadioComponent,
    PlaybackControlsComponent,
    GridScrollComponent,
    FooterComponent,
    ValueTextPluraliser,
    ValueToSortByOrderPipe,
    NgSelectModule,
    MomentTimezonePickerModule,
    FontAwesomeModule,
  ],
  declarations: [
    IcetanaDataTableComponent,
    VjsPlayerComponent,
    StatusIndicatorComponent,
    NavbarDarkComponent,
    ToolbarTitleComponent,
    DateRangeRadioComponent,
    PlaybackControlsComponent,
    GridScrollComponent,
    FooterComponent,
    ValueTextPluraliser,
    ValueToSortByOrderPipe,
  ],
  imports: [
    CommonModule,
    ClipboardModule,
    ...materialModules,
    GridsterModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MomentTimezonePickerModule,
    CustomNgxDateTimeModule,
    FontAwesomeModule,
  ],
  providers: [
    { provide: LayoutGapStyleBuilder, useClass: DefaultLayoutGapStyleBuilder },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpGlobalInterceptor,
      multi: true,
    },
    fakeBackendProvider,
    CommonVideoIncidentProvider,
  ],
})
// @ts-ignore
export class SharedModule {}
// @ts-ignore
