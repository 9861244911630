import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  Incident,
  IncidentAPI,
  IncidentAPIPagination,
  IncidentPagination,
  incidentPaginationSerializer,
  incidentSerializer,
} from '@incidents/models/incident.models';
import { ServerService } from '@server/services/server.service';


@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  private get incidentsBaseUrl(): string {
    return `${this.serverService.currentServer.api}incidents/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  public getIncidentsByFilters(range: { startDate: Date; endDate: Date }, cameraIds?: number[]): Observable<IncidentPagination> {
    const startDate = moment.utc(range.startDate).format('YYYY-MM-DD+HH:mm:ss');
    const endDate = moment.utc(range.endDate).format('YYYY-MM-DD+HH:mm:ss');
    const camera = (cameraIds && cameraIds.length) ? `camera=${cameraIds.join(',')}&` : '';
    const endpoint = `${this.incidentsBaseUrl}?${camera}started=${startDate}&ended=${endDate}&ordering=started&limit=10000&offset=0`;
    return this.http
    .get<IncidentAPIPagination>(endpoint)
    .pipe(map((response) => incidentPaginationSerializer(response)))
    .pipe(catchError((error) => throwError(error)));
  }

  public fetchAllIncidents(): Observable<IncidentPagination> {
    // Force getting all incidents
    // to enable pagination, refer to incidents-list.component.html
    // and remove [paginationEnabled] attribute (or set it to 'true')
    const endpoint = `${this.incidentsBaseUrl}?limit=10000&offset=0`;
    return this.http
      .get<IncidentAPIPagination>(endpoint)
      .pipe(map((response) => incidentPaginationSerializer(response)))
      .pipe(catchError((error) => throwError(error)));
  }

  public fetchIncident(id: number): Observable<Incident> {
    const endpoint = `${this.incidentsBaseUrl}${id}/`;

    return this.http
      .get<IncidentAPI>(endpoint)
      .pipe(map((incident) => incidentSerializer(incident)))
      .pipe(catchError((error) => throwError(error)));
  }

  public updateIncident(data: Incident): Observable<Incident> {
    const { id, ...payload } = data;
    const endpoint = `${this.incidentsBaseUrl}${id}/`;

    return this.http
      .patch<IncidentAPI>(endpoint, { ...payload })
      .pipe(map((incident) => incidentSerializer(incident)))
      .pipe(catchError((error) => throwError(error)));
  }

  public createIncident(data: Incident): Observable<Incident> {
    const { id, ...payload } = data;

    return this.http
      .post<IncidentAPI>(this.incidentsBaseUrl, { ...payload })
      .pipe(map((incident) => incidentSerializer(incident)))
      .pipe(catchError((error) => throwError(error)));
  }

  deleteIncident(incident: Incident): Observable<Incident> {
    const endpoint = `${this.incidentsBaseUrl}${incident.id}/`;

    return this.http
      .delete<any>(endpoint)
      .pipe(map(() => incident))
      .pipe(catchError((error) => throwError(error)));
  }
}
