import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

export enum DownloadHighlightState {
  videoreel,
  zipped,
}

@Injectable({
  providedIn: 'root',
})
export class GridEmitterService {
  replayClicked = new EventEmitter();
  subscribedReplayClicked: Subscription;

  downloadClicked = new EventEmitter();
  subscribedDownloadClicked: Subscription;

  constructor() {}

  replayButtonClicked(event: Event): void {
    this.replayClicked.emit(event);
  }

  downloadReelButtonClicked(): void {
    this.downloadClicked.emit(DownloadHighlightState.videoreel);
  }

  downloadZippedButtonClicked(): void {
    this.downloadClicked.emit(DownloadHighlightState.zipped);
  }

}
