/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/store';
import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AnomalyDetail } from '@core/models/anomaly/anomaly.model';
import {
  incidentCreateFail,
  incidentCreateInit,
  incidentCreateSuccess,
  incidentUpdateFail,
  incidentUpdateSuccess,
} from '@incidents/store/incidents.actions';
import { IncidentCategoryTree, IncidentStatus } from '@incidents/models/incident.models';
import { selectIncidentsLoading } from '@incidents/store/incidents.selectors';
import { categoriesFetchAllFail, categoriesFetchAllInit } from '@incidents/store/categories.actions';
import { statusesFetchAllFail, statusesFetchAllInit } from '@incidents/store/statuses.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@auth/models/auth.models';
import { selectCurrentUser } from '@auth/store/auth.selectors';

@Component({
  selector: 'icetana-report-dialog',
  templateUrl: './incident-report-dialog.component.html',
  styleUrls: ['./incident-report-dialog.component.scss'],
})
export class IncidentReportDialogComponent implements OnInit, OnDestroy {
  @Input() anomaly: AnomalyDetail;

  private ngUnsubscribe = new Subject<void>();
  public isLoading$: Observable<boolean>;
  public availableCategories$: Observable<IncidentCategoryTree>;
  public availableStatuses$: Observable<IncidentStatus[]>;
  public metaLoading = true;
  public incidentReportForm: FormGroup;
  public availableCategories: any;
  public currentUser: string;
  public timezone: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      anomaly: AnomalyDetail;
      defaultStatus: IncidentStatus;
      availableCategories$: Observable<IncidentCategoryTree>;
      availableStatuses$: Observable<IncidentStatus[]>;
    },
    private dialogRef: MatDialogRef<any>,
    private store: Store<AppState>,
    private action$: Actions,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.timezone = this.data.anomaly.timezone || moment.tz.guess();
    this.formInit();

    this.isLoading$ = this.store.pipe(select(selectIncidentsLoading), takeUntil(this.ngUnsubscribe));
    this.store.dispatch(categoriesFetchAllInit());
    this.store.dispatch(statusesFetchAllInit());
    this.availableCategories$ = this.data.availableCategories$;
    this.availableStatuses$ = this.data.availableStatuses$;
    this.metaLoading = false; // TODO: this might need to be removed because it doesn't seam needed

    this.store.pipe(select(selectCurrentUser)).subscribe((user) => {
      this.currentUser = this.getCurrentUsersName(user);
    });

    this.availableCategories$.subscribe(categories => {
      this.availableCategories = categories;
    });

    // combineLatest([
    //   this.action$.pipe(ofType(categoriesFetchAllSuccess)),
    //   this.action$.pipe(ofType(statusesFetchAllSuccess)),
    // ]).subscribe(([categories, statuses]) => {
    //   this.metaLoading = false;

    //   /**
    //    * Angular Material Select supports only 2 levels of tree ('optgroup' and 'option')
    //    * Levels > 2 will be ignored when displaying select
    //    */
    //   this.availableCategories = incidentCategoryTreeBuilder(categories.payload);
    //   this.availableStatuses = statuses.payload;
    // });

    this.action$.pipe(ofType(incidentCreateSuccess), takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.dialogRef.close();
    });

    this.action$
      .pipe(ofType(incidentCreateFail, incidentUpdateFail), takeUntil(this.ngUnsubscribe))
      .subscribe((err) => {
        console.warn(err);
        this.snackBar.open(`Error saving incident: ${err.message}`, null, {
          duration: 5000,
          panelClass: ['snackbar-error-background'],
        });

        this.dialogRef.close();
      });

    this.action$
      .pipe(ofType(incidentCreateSuccess, incidentUpdateSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((err) => {
        this.snackBar.open(`Incident saved successfully`, null, {
          duration: 5000,
          panelClass: ['snackbar-success-background'],
        });

        this.dialogRef.close();
      });

    this.action$
      .pipe(ofType(categoriesFetchAllFail, statusesFetchAllFail), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.snackBar.open('Error fetching incident categories or incident statuses', null, {
          duration: 5000,
          panelClass: ['snackbar-error-background'],
        });

        this.dialogRef.close();
      });
  }

  /**
   * Form structure Definition
   *
   * @returns void
   */
  private formInit(): void {
    this.incidentReportForm = this.formBuilder.group({
      body: [null, [Validators.minLength(2), Validators.maxLength(2048)]],
      categories: [[], [Validators.required]],
    });
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const formData = this.incidentReportForm.value;

    if (this.incidentReportForm.valid) {
      this.store.dispatch(
        incidentCreateInit({
          payload: {
            subject: null,
            body: formData.body,
            anomaly: this.data.anomaly?.id,
            categories: [formData.categories],
            status: this.data.defaultStatus.id,
          },
        })
      );
    }
  }

  parseDate(timestamp: string): string {
    return timestamp ? moment(timestamp).tz(this.timezone).format('YYYY-MM-DD') : 'undefined';
  }

  parseTime(timestamp: string): string {
    return timestamp ? `${moment(timestamp).tz(this.timezone).format('HH:mm:ss')} (${this.timezone})` : 'undefined';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  private getCurrentUsersName(user: User): string {
    if (user) {
      if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
      }
      else if (user.email) {
        return user.email;
      }
    }
    return 'Current User';
  }
}
