import { createReducer, on } from '@ngrx/store';

import { User, Role } from '@auth/models/auth.models';
import { setRoles, setUser } from '@auth/store/auth.actions';

export interface AuthState {
  user: User | null;
  loading: boolean;
  roles: Role[];
  fail?: boolean;
  success?: boolean;
}

export const initialAuthState: AuthState = {
  user: null,
  loading: false,
  roles: [],
};

export const authReducer = createReducer(
  initialAuthState,
  // authenticated user
  on(setUser, (state, data) => ({
      ...state,
      user: data.payload.user,
    })),
  // available roles
  on(setRoles, (state, data) => ({
      ...state,
      roles: data.payload.roles,
    }))
);
