/* eslint-disable @typescript-eslint/naming-convention */
import { AccessToken } from '@auth/models/auth.models';
import { mockRoles } from '@users/constants/mock.values';

export const roleBasedAccessTokens = [
  {
    role: 'admin',
    token:
      `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMSIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJwYXNzd29yZCI6IjEyMzQ1NiIsInZlcmlmaWVkIjp0c\
nVlLCJ1c2VyIjp7ImlkIjoiMSIsImVtYWlsIjoiYWRtaW5pc3RyYXRvckBleGFtcGxlLmNvbSIsImZpcnN0X25hbWUiOiJKb2huIiwibGFzdF9uYW1lIjoiU21pdGgiLCJ0b\
2tlbl90eXBlIjoiYWNjZXNzIiwicm9sZSI6eyJpZCI6MSwib3JkZXIiOjEsIm5hbWUiOiJBZG1pbiIsImRlc2NyaXB0aW9uIjoiQWRtaW5pc3RyYXRvciJ9fX0.-qCBYvBeL\
XX5PY5grD-tqP3mQQ51TYAJy8ZnY1cUmZQ`,
  },
  {
    role: 'operator',
    token:
      `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMiIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJwYXNzd29yZCI6IjEyMzQ1NiIsInZlcmlmaWVkIjp0cn\
VlLCJ1c2VyIjp7ImlkIjoiMiIsImVtYWlsIjoib3BlcmF0b3JAZXhhbXBsZS5jb20iLCJmaXJzdF9uYW1lIjoiRnJhbmsiLCJsYXN0X25hbWUiOiJTaW5hdHJhIiwidG9rZW\
5fdHlwZSI6ImFjY2VzcyIsInJvbGUiOnsiaWQiOjIsIm9yZGVyIjoyLCJuYW1lIjoiT3BlcmF0b3IiLCJkZXNjcmlwdGlvbiI6Ik9wZXJhdG9yIGRlc2NyaXB0aW9uIn19fQ\
.lfhTJjHMERLBJgfZ2LWi52VONMAz0gSWiooGnu31KyQ`,
  },
  {
    role: 'test',
    token:
      `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMyIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJwYXNzd29yZCI6IjEyMzQ1NiIsInZlcmlmaWVkIjp0cn\
VlLCJ1c2VyIjp7ImlkIjoiMyIsImVtYWlsIjoidGVzdEBleGFtcGxlLmNvbSIsImZpcnN0X25hbWUiOiJKYW5lIiwibGFzdF9uYW1lIjoiRG9lIiwidG9rZW5fdHlwZSI6Im\
FjY2VzcyIsInJvbGUiOnsiaWQiOjMsIm9yZGVyIjozLCJuYW1lIjoiVGVzdCIsImRlc2NyaXB0aW9uIjoiVGVzdCByb2xlIn19fQ.nFKob_jpWjCZugZRvTuZBgZYxzVK-Qn\
yxnMaLkKYOa0`,
  },
  {
    role: 'operator(timed)',
    token:
      `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNCIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJwYXNzd29yZCI6IjEyMzQ1NiIsInZlcmlmaWVkIjp0cn\
VlLCJleHAiOjE2NTIwMjExMDIsInVzZXIiOnsiaWQiOiI0IiwiZW1haWwiOiJ0aW1lZEBleGFtcGxlLmNvbSIsImZpcnN0X25hbWUiOiJUaW1teSIsImxhc3RfbmFtZSI6Il\
R1cm5lciIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJyb2xlIjp7ImlkIjoyLCJvcmRlciI6MiwibmFtZSI6Ik9wZXJhdG9yIiwiZGVzY3JpcHRpb24iOiJPcGVyYXRvciBkZXN\
jcmlwdGlvbiJ9fX0.-OJMozEiw8GG3WMPC_sYFss8GVwFk-WV5m5sjhJELgc`,
  }
];

const getRole = (id: number) => mockRoles.find((role) => role.id === id);

// data used to generate jwt token
export const mockAPIUsers = [
  {
    user_id: '1',
    token_type: 'access',
    password: '123456',
    verified: true,
    user: {
      id: '1',
      email: 'administrator@example.com',
      first_name: 'John',
      last_name: 'Smith',
      token_type: 'access',
      role: getRole(1),
    },
  },
  {
    user_id: '2',
    token_type: 'access',
    password: '123456',
    verified: true,
    user: {
      id: '2',
      email: 'operator@example.com',
      first_name: 'Frank',
      last_name: 'Sinatra',
      token_type: 'access',
      role: getRole(2),
    },
  },
  {
    user_id: '3',
    token_type: 'access',
    password: '123456',
    verified: true,
    user: {
      id: '3',
      email: 'test@example.com',
      first_name: 'Jane',
      last_name: 'Doe',
      token_type: 'access',
      role: getRole(3),
    },
  },
  {
    user_id: '4',
    token_type: 'access',
    password: '123456',
    verified: true,
    exp: 1652021102,
    user: {
      id: '4',
      email: 'timed@example.com',
      first_name: 'Timmy',
      last_name: 'Turner',
      token_type: 'access',
      role: getRole(2),
    },
  },
];

export const handleLogin = (request) => {
  const email: string = request.body.email;
  const password: string = request.body.password;

  const getUser = () => mockAPIUsers.find((user) => user.user.email === email && user.password === password) || null;

  const fakeUser = getUser();
  if (fakeUser) {
    const index = parseInt(fakeUser.user.id, 10) - 1;
    const accessToken: AccessToken = {
      refresh: roleBasedAccessTokens[index].token,
      access: roleBasedAccessTokens[index].token,
    };
    return accessToken;
  }
  throw new Error('Unable to log in with provided credentials.');
};

export const fakeExpiryAccessTokens = {
  validToken:
    `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMSIsInRva2VuX3R5cGUiOiJhY2Nlc3MiLCJwYXNzd29yZCI6IjEyMzQ1NiIsInZlcmlmaWVkIjp0cnVl\
LCJ1c2VyIjp7ImlkIjoiMSIsImVtYWlsIjoiYWRtaW5pc3RyYXRvckBleGFtcGxlLmNvbSIsImZpcnN0X25hbWUiOiJKb2huIiwibGFzdF9uYW1lIjoiU21pdGgiLCJ0b2tlbl90eX\
BlIjoiYWNjZXNzIiwicm9sZSI6eyJpZCI6MSwib3JkZXIiOjEsIm5hbWUiOiJBZG1pbiIsImRlc2NyaXB0aW9uIjoiQWRtaW5pc3RyYXRvciJ9fX0.-qCBYvBeLXX5PY5grD-tqP3m\
QQ51TYAJy8ZnY1cUmZQ`,
  expiredToken:
    `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOi0yODgwMCwiZXhwIjotMjg4MDAsImF1ZCI6Ind3dy5leGFtcGxl\
LmNvbSIsInN1YiI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJFbWFpbCI6ImJlZUBleGFtcGxlLmNvbSJ9.ezAiSGNhEB_aBIEYr8lL4QO9EhXZ05ztYit7sEPw74w`,
};
