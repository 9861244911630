import { createReducer, on } from '@ngrx/store';

import { Camera, CameraStatus } from '@cameras/models/cameras/cameras.models';
import {
  cameraFetchFail,
  cameraFetchInit,
  cameraFetchSuccess,
  camerasFetchFail,
  camerasFetchInit,
  camerasFetchSuccess,
  cameraCreateSuccess,
  cameraCreateInit,
  cameraCreateFail,
  cameraUpdateSuccess,
  cameraUpdateInit,
  cameraUpdateFail,
  cameraStartPipelineSessionInit,
  cameraStartPipelineSessionSuccess,
  cameraStartPipelineSessionFail,
  cameraStopPipelineSessionInit,
  cameraStopPipelineSessionSuccess,
  cameraStopPipelineSessionFail,
  cameraDeleteInit,
  cameraDeleteSuccess,
  cameraDeleteFail,
  camerasFetchUnpaginatedSuccess,
  camerasFetchUnpaginatedInit,
  camerasFetchUnpaginatedFail,
} from '@cameras/store/cameras/cameras.actions';
import '@shared/modules/array.prototype.upsert/upsert.module';

export interface CameraState {
  entities: Camera[];
  count: number;
  selected: Camera;
  success: boolean;
  loading: boolean;
  fail: boolean;
  cameraPageLimit: number;
  cameraPageOffset: number;
}

export const initialCameraState: CameraState = {
  entities: [],
  count: 0,
  selected: null,
  success: false,
  loading: false,
  fail: false,
  cameraPageLimit: 20,
  cameraPageOffset: 0,
};

export const cameraReducer = createReducer(
  initialCameraState,
  // Cameras
  on(camerasFetchInit, (state, { payload }) => ({
    ...state,
    loading: true,
    cameraPageLimit: payload.pageLimit,
    cameraPageOffset: payload.pageOffset,
  })),
  on(camerasFetchSuccess, (state, { payload }) => {
    const results = [...payload.entities];
    return {
      ...state,
      count: payload.count,
      entities: [...results],
      loading: false,
      success: true,
    };
  }),
  on(
    camerasFetchFail,
    camerasFetchUnpaginatedFail,
    cameraFetchFail,
    cameraCreateFail,
    cameraUpdateFail,
    cameraDeleteFail,
    (state) => ({
      ...state,
      loading: false,
      fail: true,
      success: false,
    })
  ),
  on(camerasFetchUnpaginatedInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(camerasFetchUnpaginatedSuccess, (state, { payload }) => {
    const results = [...payload.entities];
    return {
      ...state,
      count: payload.count,
      entities: [...results],
      loading: false,
      success: true,
    };
  }),
  // Camera
  on(cameraFetchInit, (state) => ({
    ...state,
    loading: true,
    fail: false,
  })),
  on(cameraFetchSuccess, (state, { payload }) => {
    const selected = payload.camera;
    const entities = [...state.entities];
    entities.upsert(selected);

    return {
      ...state,
      entities,
      selected,
      loading: false,
      fail: false,
      success: true,
    };
  }),
  // On create camera
  on(cameraCreateInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(cameraCreateSuccess, (state, { payload }) => {
    const camera = payload.camera;
    return {
      ...state,
      entities: [...state.entities, camera],
      loading: false,
      success: true,
    };
  }),
  // On update camera
  on(cameraUpdateInit, (state) => ({
    ...state,
  })),
  on(cameraUpdateSuccess, (state, { payload }) => {
    const cameras = state.entities.map((camera) =>
      camera.id === payload.camera.id
        ? {
            ...camera,
            ...{
              name: payload.camera.name,
              description: payload.camera.description,
              username: payload.camera.username,
              password: payload.camera.password,
              site: payload.camera.site,
              zones: payload.camera.zones,
              uri: payload.camera.uri,
            },
          }
        : camera
    );

    return {
      ...state,
      entities: [...cameras],
      loading: false,
      success: true,
    };
  }),
  // On delete camera
  on(cameraDeleteInit, (state) => ({
    ...state,
  })),
  on(cameraDeleteSuccess, (state, { payload }) => ({
    ...state,
    entities: [...state.entities.filter((f) => f.id !== payload.camera.id)],
    loading: false,
    success: true,
  })),
  // On camera pipeline session starting
  on(cameraStartPipelineSessionInit, (state, { payload }) => {
    const cameras = state.entities.map((camera) =>
      camera.id === payload.camera.id ? { ...payload.camera, status: CameraStatus.INITIALIZING } : camera
    );

    return {
      ...state,
      entities: [...cameras],
    };
  }),
  on(cameraStartPipelineSessionSuccess, (state, { payload }) => {
    const cameras = state.entities.map((camera) =>
      camera.id === payload.camera.id ? { ...payload.camera, status: CameraStatus.STARTED } : camera
    );

    return {
      ...state,
      entities: [...cameras],
      loading: false,
      success: true,
    };
  }),
  on(cameraStartPipelineSessionFail, (state, { payload }) => {
    const cameras = state.entities.map((camera) =>
      camera.id === payload.camera.id ? { ...camera, status: CameraStatus.FAILED } : camera
    );

    return {
      ...state,
      loading: false,
      entities: [...cameras],
      fail: true,
      success: false,
    };
  }),
  // On camera pipeline session stopping
  on(cameraStopPipelineSessionInit, (state, { payload }) => {
    const cameras = state.entities.map((camera) =>
      camera.id === payload.camera.id ? { ...payload.camera, status: CameraStatus.STOPPING } : camera
    );

    return {
      ...state,
      entities: [...cameras],
    };
  }),
  on(cameraStopPipelineSessionSuccess, (state) => ({
    ...state,
    entities: [...state.entities],
    loading: false,
    success: true,
  })),
  on(cameraStopPipelineSessionFail, (state, { payload }) => {
    const cameras = state.entities.map((camera) =>
      camera.id === payload.camera.id ? { ...camera, status: CameraStatus.FAILED } : camera
    );

    return {
      ...state,
      loading: false,
      entities: [...cameras],
      fail: true,
      success: false,
    };
  })
);
