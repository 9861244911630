import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { AUTH_LOGOUT } from '@auth/store/auth.actions';

import { CameraState, cameraReducer } from '@cameras/store/cameras/cameras.reducer';

import { AnomalyState, anomalyReducer } from '@core/store/anomalies/anomalies.reducers';
import { LiveAnomalyState, liveAnomalyReducer } from '@core/store/live-anomalies/anomalies.reducers';
import { LiveWallState, liveWallReducer } from '@core/store/livewall/livewall.reducers';
import { userSettingsReducer, UserSettingsState } from '@core/store/user-settings/user-settings.reducers';
import { authReducer, AuthState } from '@auth/store/auth.reducers';
import { permissionsReducer, PermissionsState } from '@permissions/store/permissions.reducers';
import { usersReducer, UsersState } from '@users/store/users.reducers';
import { clientsReducer, ClientsState } from '@clients/store/clients.reducers';
import { incidentsReducer, IncidentsState } from '@incidents/store/incidents.reducers';
import { incidentCategoriesReducer, IncidentCategoriesState } from '@incidents/store/categories.reducers';
import { incidentStatusesReducer, IncidentStatusesState } from '@incidents/store/statuses.reducers';

/**
 * This is the `root reducer` for the `NGRX store`
 *
 */

/**
 * This interface defines the main `State` of the NGRX Store
 */
export interface AppState {
  cameras: CameraState;
  anomalies: AnomalyState;
  liveAnomalies: LiveAnomalyState;
  liveWall: LiveWallState;
  userSettings: UserSettingsState;
  auth: AuthState;
  permissions: PermissionsState;
  users: UsersState;
  clients: ClientsState;
  incidents: IncidentsState;
  incidentCategory: IncidentCategoriesState;
  incidentStatus: IncidentStatusesState;
}

/**
 * This merge all the `reducers` in a unified `root` reducer
 */
export const reducers: ActionReducerMap<AppState> = {
  cameras: cameraReducer,
  anomalies: anomalyReducer,
  liveAnomalies: liveAnomalyReducer,
  liveWall: liveWallReducer,
  userSettings: userSettingsReducer,
  auth: authReducer,
  permissions: permissionsReducer,
  users: usersReducer,
  clients: clientsReducer,
  incidents: incidentsReducer,
  incidentCategory: incidentCategoriesReducer,
  incidentStatus: incidentStatusesReducer,
};

export const clearStateOnLogout =
(reducer: ActionReducer<AppState>): ActionReducer<AppState> => (state: AppState, action: Action): AppState => {
    if (action.type === AUTH_LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };

/**
 * This export the `metaReducers` for the NGRX store.
 */
export const metaReducers: MetaReducer<AppState>[] = [clearStateOnLogout];
