import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createFFmpeg, fetchFile, FFmpeg } from '@ffmpeg/ffmpeg';
import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as zip from '@zip.js/zip.js';

import { ANOMALY_MODELS, ANOMALY_LABELS, GET_ANOMALY_LABEL } from '@anomalies/constants/anomaly.constants';
import { AppState } from '@app/store';
import { Anomaly, AnomalyDetail } from '@core/models/anomaly/anomaly.model';
import {
  DEFAULT_INCIDENT_CATEGORY_NAME,
  DEFAULT_INCIDENT_STATUS_NAME,
  DEFAULT_INCIDENT_SUBJECT,
  DEFAULT_INCIDENT_BODY,
} from '@incidents/constants/incidents.constants';
import { IncidentReportDialogComponent } from '@incidents/containers/incident-report-dialog.component';
import { IncidentCategoryTree, IncidentStatus } from '@incidents/models/incident.models';
import { categoriesFetchAllFail, categoriesFetchAllInit } from '@incidents/store/categories.actions';
import { selectCategoriesEntitiesTree } from '@incidents/store/categories.selectors';
import { incidentCreateInit, incidentCreateSuccess, incidentUpdateSuccess } from '@incidents/store/incidents.actions';
import { statusesFetchAllInit, statusesFetchAllFail } from '@incidents/store/statuses.actions';
import { selectIncidentStatusesEntities } from '@incidents/store/statuses.selectors';
import { VjsPlayerComponent } from '@shared/components/vjs-player/vjs-player.component';

@Injectable()
export class CommonVideoIncidentProvider {
  constructor(
    public store: Store<AppState>,
    public action$: Actions,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}
}

@Component({
  template: '',
})
export class CommonVideoIncidentComponent implements OnInit, OnDestroy {
  readonly anomalyModels = ANOMALY_MODELS;
  readonly anomalyLabels = ANOMALY_LABELS;
  readonly getAnomalyLabel = GET_ANOMALY_LABEL;

  availableCategories$: Observable<any>;
  availableStatuses$: Observable<IncidentStatus[]>;
  defaultCategory: IncidentCategoryTree;
  defaultStatus: IncidentStatus;
  public ngUnsubscribe = new Subject<void>();
  private fetchFile = fetchFile;

  constructor(public commonVideoIncident: CommonVideoIncidentProvider) {}

  ngOnInit(): void {
    if (!this.availableCategories$) {
      this.availableCategories$ = this.commonVideoIncident.store.pipe(
        select(selectCategoriesEntitiesTree),
        takeUntil(this.ngUnsubscribe)
      );
    }
    if (!this.availableStatuses$) {
      this.availableStatuses$ = this.commonVideoIncident.store.pipe(
        select(selectIncidentStatusesEntities),
        takeUntil(this.ngUnsubscribe)
      );
    }

    // set default category and status
    this.availableCategories$.subscribe((categories) => {
      if (categories) {
        this.defaultCategory = categories.find((s) => s.name === DEFAULT_INCIDENT_CATEGORY_NAME);
      }
    });
    this.availableStatuses$.subscribe((statuses) => {
      if (statuses) {
        this.defaultStatus = statuses.find((s) => s.name === DEFAULT_INCIDENT_STATUS_NAME);
      }
    });
  }

  initIncidentResources(): void {
    this.commonVideoIncident.store.dispatch(categoriesFetchAllInit());
    this.commonVideoIncident.store.dispatch(statusesFetchAllInit());

    this.commonVideoIncident.action$
      .pipe(ofType(incidentCreateSuccess, incidentUpdateSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.toastPopup('Incident saved successfully', ['snackbar-success-background']));

    this.commonVideoIncident.action$
      .pipe(ofType(categoriesFetchAllFail, statusesFetchAllFail), takeUntil(this.ngUnsubscribe))
      .subscribe(() =>
        this.toastPopup('Error fetching incident categories or incident statuses', ['snackbar-error-background'])
      );
  }

  toastPopup(message: string, panelClass: string[], duration: number = 5000): void {
    this.commonVideoIncident.snackBar.open(message, null, { duration, panelClass });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSaveAndCategorise(anomaly: AnomalyDetail): void {
    this.commonVideoIncident.dialog.open(IncidentReportDialogComponent, {
      data: {
        anomaly,
        defaultStatus: this.defaultStatus,
        availableCategories$: this.availableCategories$,
        availableStatuses$: this.availableStatuses$,
      },
      width: '100%',
      panelClass: 'icetana-modal-incident-create-panel',
    });
  }

  onQuickSave(anomaly: any, categoryId: number): void {
    if (!anomaly || !this.defaultStatus) {
      return;
    }

    this.commonVideoIncident.store.dispatch(
      incidentCreateInit({
        payload: {
          subject: DEFAULT_INCIDENT_SUBJECT,
          body: DEFAULT_INCIDENT_BODY,
          anomaly: anomaly.id,
          categories: [categoryId],
          started: anomaly.start,
          ended: anomaly.end,
          status: this.defaultStatus.id,
        },
      })
    );
  }

  downloadBlob(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.id = 'download-blob';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  /**
   * @param anomaly Anomaly
   * Downloads an anomaly video file
   */
  async downloadAnomalyVideo(anomaly: Anomaly, timezone: string) {
    return fetch(anomaly.videoUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const fileTimestamp = moment(anomaly.start)
          .tz(timezone || moment.tz.guess())
          .format('YYYY-MM-DD HH:mm:ssz');
        const fileName = `event ${anomaly.cameraName} ${fileTimestamp}.mp4`;
        this.downloadBlob(blob, fileName);
      });
  }

  /**
   * @param anomalies Anomaly[]
   * Downloads and merges anomaly videos
   * Taken from https://github.com/ffmpegwasm/ffmpeg.wasm/blob/master/examples/browser/concatDemuxer.html
   */
  async downloadAndMergeAnomalies(anomalies: Anomaly[], fileName: string, abortSignal: any) {
    // check for anomalies
    if (!anomalies.length) {
      throw Error('No anomalies to download');
    }
    // verify the users browser is cross origin isolated
    if (!(self as any).crossOriginIsolated) {
      throw Error('Web Browser is not Cross Origin Isolated');
    }

    const ffmpeg: FFmpeg = createFFmpeg({
      log: false,
      corePath: '/ffmpeg-core/ffmpeg-core.js',
    });
    await ffmpeg.load();

    try {
      this.toastPopup('Constructing video reel, this may take a few minutes, please wait ...', [
        'snackbar-info-background',
      ]);
      const inputPaths = [];
      for (const anomaly of anomalies) {
        if (abortSignal.aborted) {
          throw Error('Aborted by user');
        }

        ffmpeg.FS('writeFile', `${anomaly.videoFileName}.mp4`, await this.fetchFile(anomaly.videoUrl));
        inputPaths.push(`file ${anomaly.videoFileName}.mp4`);
      }

      await new Promise((resolve, reject) => {
        this.toastPopup('Stitching video reel, this may take a few minutes, please wait ...', [
          'snackbar-info-background',
        ]);
        const enc = new TextEncoder();

        ffmpeg.FS('writeFile', 'concat_list.txt', enc.encode(inputPaths.join('\n')));
        ffmpeg
          .run('-f', 'concat', '-safe', '0', '-i', 'concat_list.txt', 'output.mp4')
          .then(() => {
            resolve(1);
          })
          .catch((error) => {
            reject(error);
          });

        abortSignal.addEventListener('abort', () => {
          reject(new Error('Aborted by user'));
        });
      });

      const data = ffmpeg.FS('readFile', 'output.mp4');
      this.toastPopup('Downloading video reel', ['snackbar-success-background']);
      return this.downloadBlob(new Blob([data.buffer], { type: 'video/mp4' }), `${fileName}.mp4`);
    } catch (error) {
      ffmpeg.exit();
      throw Error('Failed to stitch video reel');
    }
  }

  /**
   * @param anomalies Anomaly[]
   * Downloads and zips anomaly videos
   * Taken from https://huynvk.dev/blog/download-files-and-zip-them-in-your-browsers-using-javascript
   */
  async downloadZippedAnomalies(anomalies: Anomaly[], fileName: string, abortSignal: any) {
    // check for anomalies
    if (!anomalies.length) {
      throw Error('No anomalies to download');
    }

    try {
      this.toastPopup('Compressing videos, this may take a few minutes, please wait ...', ['snackbar-info-background']);
      const blobWriter = new zip.BlobWriter('application/zip');
      const writer = new zip.ZipWriter(blobWriter);

      for (const a of anomalies) {
        if (abortSignal.aborted) {
          throw Error('Aborted by user');
        }

        const blob = await fetch(a.videoUrl).then((resp) => resp.blob());
        const fileTimestamp = moment(a.start).format('YYYY-MM-DD HH:mm:ss');
        const anomalyFileName = `anomaly ${a.cameraName} ${fileTimestamp}`;
        await writer.add(`${anomalyFileName}.mp4`, new zip.BlobReader(blob));
      }
      await writer.close();

      this.toastPopup('Downloading zipped file', ['snackbar-success-background']);
      return this.downloadBlob(blobWriter.getData(), `${fileName}.zip`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  rightClickVideoMenu(
    event,
    rightClickMenuTrigger,
    videoElement,
    contextMenuPosition,
    pauseTriggerFcn?,
    manualOffsetX = 0,
    manualOffsetY = 0,
  ): void {
    const isOnVideoElement =
      videoElement instanceof VjsPlayerComponent &&
      (event.target.closest(`#${videoElement.video.nativeElement.playerId}`) !== null ||
        event.target.closest(`#${videoElement.video.nativeElement.playerId}_overlay`) !== null);

    if (rightClickMenuTrigger.menuOpen) {
      event.preventDefault();
      rightClickMenuTrigger.closeMenu();
    }
    // Detect the right-click event target is within the video element
    else if (isOnVideoElement) {
      event.preventDefault();
      contextMenuPosition.x = event.clientX + manualOffsetX + 'px';
      contextMenuPosition.y = event.clientY + manualOffsetY + 'px';
      rightClickMenuTrigger.openMenu();

      // stop the video
      if (pauseTriggerFcn) {
        // highlight grid
        pauseTriggerFcn();
      } else {
        // otherwise pause the individual video
        const video = videoElement;
        video.player.pause();
      }
    }
  }
}
