<div class="navbar-dark">
  <mat-toolbar>
    <div class="toolbar-contents" fxLayout="row" fxLayoutAlign="start center">
      <div class="navbar-items" data-test="navbar-items">
        <ng-content select="[items]"></ng-content>
      </div>
    </div>
  </mat-toolbar>

  <div class="navbar-shadow"></div>
</div>
