import { AUTH as AUTH_ROUTE } from '@core/constants/app.constants';

const AUTH_ROUTE_LOGIN = 'login';
const AUTH_ROUTE_LOGOUT = 'logout';
const AUTH_ROUTE_FORCE_RESET_PASSWORD = 'reset-password';
const AUTH_ROUTE_FORGOT_PASSWORD = 'forgot-password';
const AUTH_ROUTE_CHANGE_PASSWORD = 'change-password';
const AUTH_TOKEN_KEY = 'icetana-auth-token';
const AUTH_ROUTE_REFRESH = 'refresh';
const AUTH_ROUTE_TEST = 'auth-test';
const LOCAL_STORAGE_SCHEMA_VERSION = 'icetana-local-storage-schema-version';

// https://github.com/auth0/angular2-jwt#usage-injection
// The origin is automatically included.
// With use in production, this can be managed via the TokenGetter function
const AUTH_ALLOWED_DOMAINS = [/.*/];
const AUTH_DISALLOWED_ROUTES = [
  new RegExp(`\/\/.*\/${AUTH_ROUTE}\/${AUTH_ROUTE_LOGIN}/`),
  new RegExp(`\/\/.*\/${AUTH_ROUTE}\/${AUTH_ROUTE_REFRESH}/`),
];

const AUTH_USERS_ID = 'auth';
const SUPPORT_EMAIL = 'support@icetana.com';

const ASSISTANCE_EMAIL = SUPPORT_EMAIL;
const ASSISTANCE_EMAIL_DEFAULT_SUBJECT = 'Assistance with icetana';

export {
  AUTH_ROUTE_LOGIN,
  AUTH_ROUTE_LOGOUT,
  AUTH_TOKEN_KEY,
  AUTH_ROUTE_REFRESH,
  AUTH_ROUTE_TEST,
  LOCAL_STORAGE_SCHEMA_VERSION,
  AUTH_ALLOWED_DOMAINS,
  AUTH_DISALLOWED_ROUTES,
  AUTH_ROUTE_FORCE_RESET_PASSWORD,
  AUTH_ROUTE_FORGOT_PASSWORD,
  AUTH_ROUTE_CHANGE_PASSWORD,
  AUTH_USERS_ID,
  SUPPORT_EMAIL,
  ASSISTANCE_EMAIL,
  ASSISTANCE_EMAIL_DEFAULT_SUBJECT,
};
