<div class="toolbar-title">
  <div class="toolbar-title__text">
    <span class="toolbar-title__text__icon">
      <mat-icon aria-hidden="false" aria-label="left">{{ iconName }}</mat-icon>
    </span>
    <span class="toolbar-title__text__label">{{ titleText }}</span>
  </div>
  <div class="toolbar-title__sort">
    <div
      *ngFor="let icon of sortIcons; index as i"
      class="toolbar-title__sort__icon"
      [class]="getIconClasses(icon.name)"
      (click)="onSortChange(icon.name)"
    >
      <mat-icon aria-hidden="false" aria-label="left">{{ getSortIconName(icon.name) }}</mat-icon>
    </div>
  </div>
</div>

<div *ngIf="!!filter" class="toolbar-filter">
  <mat-form-field appearance="outline">
    <mat-label>{{ filter }}</mat-label>
    <input matInput type="text" autocomplete="off" [placeholder]="filter" formControlName="filterValue" />
  </mat-form-field>
</div>
