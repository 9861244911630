/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import {
  statusesFetchAllFail,
  statusesFetchAllSuccess,
  STATUSES_FETCH_ALL_INIT,
} from '@incidents/store/statuses.actions';

import { IncidentStatus, IncidentStatusPagination } from '@incidents/models/incident.models';

import { StatusesService } from '@incidents/services/statuses.service';

interface StatusAction {
  readonly type: string;
  id?: number;
  payload?: IncidentStatus;
}

@Injectable()
export class IncidentStatusesEffects implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private actions$: Actions, private statusesService: StatusesService) {}

  fetchAllStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(STATUSES_FETCH_ALL_INIT),
      mergeMap(() =>
        this.statusesService.fetchAllStatuses().pipe(
          map((categories: IncidentStatusPagination) => statusesFetchAllSuccess({ payload: categories.entities })),
          catchError((error: Error) => of(statusesFetchAllFail(error)))
        )
      )
    )
  );

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
