import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  CommonVideoIncidentComponent,
  CommonVideoIncidentProvider
} from '@app/containers/common-video-incident/common-video-incident.component';
import { Camera } from '@cameras/models/cameras/cameras.models';
import { FEATURE_FLAG_NX_WITNESS, NX_WITNESS_RTSP_REGEX, FEATURE_FLAG_ANOMALY_DIAGNOSTICS } from '@core/constants/app.constants';
import { Anomaly, AnomalyDetail } from '@core/models/anomaly/anomaly.model';
import { IncidentCategoryTree, IncidentStatus } from '@incidents/models/incident.models';
import { incidentCreateSuccess } from '@incidents/store/incidents.actions';
import { VjsPlayerComponent } from '@shared/components/vjs-player/vjs-player.component';


@Component({
  selector: 'icetana-anomaly-view',
  templateUrl: './anomaly-view.component.html',
  styleUrls: ['./anomaly-view.component.scss'],
})
export class AnomalyViewComponent extends CommonVideoIncidentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('video') videoElement: VjsPlayerComponent;
  @ViewChild(MatMenuTrigger) rightClickMenuTrigger: MatMenuTrigger;

  anomaly: Anomaly;
  camera: Camera;
  timezone: string;
  availableStatuses$: Observable<IncidentStatus[]>;
  defaultCategory: IncidentCategoryTree;
  defaultStatus: IncidentStatus;
  incidentExists: boolean;
  videoStartTime: number;
  featureNxWitnessFlag = false;
  featureAnomalyDiagFlag = false; // feature flag for anomaly diagnostics

  anomalyModel = null;
  anomalyLabel = null;

  public contextMenuPosition = { x: '0px', y: '0px' };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      anomaly: Anomaly;
      camera: Camera;
      availableCategories$: Observable<IncidentCategoryTree>;
      availableStatuses$: Observable<IncidentStatus[]>;
      incidentExists: boolean;
      videoStartTime?: number;
      showNext: boolean;
      showPrev: boolean;
    },
    private dialogRef: MatDialogRef<any>,
    public commonVideoIncident: CommonVideoIncidentProvider,
    private route: ActivatedRoute,
  ) {
    super(commonVideoIncident);
  }

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event): void {
    super.rightClickVideoMenu(event, this.rightClickMenuTrigger, this.videoElement, this.contextMenuPosition);
  }

  /** keyboard event binding for listening for left and right keyboard clicks to jump forward or back an anomaly */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case 'ArrowLeft':
        event.preventDefault();
        if (this.data.showPrev) {
          this.goPrevAnomaly();
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (this.data.showNext) {
          this.goNextAnomaly();
        }
        break;
    }
  }

  ngOnInit(): void {
    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
      // detect any feature flags
      this.featureNxWitnessFlag =(params[FEATURE_FLAG_NX_WITNESS] && params[FEATURE_FLAG_NX_WITNESS] === 'true');
      this.featureAnomalyDiagFlag =
        params[FEATURE_FLAG_ANOMALY_DIAGNOSTICS] && params[FEATURE_FLAG_ANOMALY_DIAGNOSTICS] === 'true';
    });

    this.camera = this.data.camera;
    this.timezone = this.camera.timezone || moment.tz.guess();

    this.anomaly = {
      ...this.data.anomaly,
      start: moment(this.data.anomaly.start).tz(this.timezone).format(),
      end: moment(this.data.anomaly.end).tz(this.timezone).format(),
    };

    this.anomalyModel = this.anomalyModels[this.anomaly.anomalyDiagnostic?.modelName];
    this.anomalyLabel = this.getAnomalyLabel(this.anomaly);

    this.availableCategories$ = this.data.availableCategories$;
    this.availableStatuses$ = this.data.availableStatuses$;

    this.incidentExists = this.data.incidentExists;
    // if an incident is created, then set the incidentExists value
    this.commonVideoIncident.action$.pipe(ofType(incidentCreateSuccess), takeUntil(this.ngUnsubscribe))
    .subscribe(() => {
      this.incidentExists = true;
    });

    this.videoStartTime = this.data.videoStartTime || 0;
    super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.videoElement.player.on('ready', () => {
      if (this.videoStartTime !== null) {
        this.videoElement.player.currentTime(this.videoStartTime);
        this.videoElement.player.play();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  goNextAnomaly(): void {
    this.dialogRef.close({next : this.data.showNext});
  }

  goPrevAnomaly(): void {
    this.dialogRef.close({prev: this.data.showPrev});
  }

  parseTime(timestamp: string): string {
    return timestamp ? `${moment(timestamp).tz(this.timezone).format('HH:mm:ss')} (${this.timezone})` : 'undefined';
  }

  parseDate(timestamp: string): string {
    return timestamp ? moment(timestamp).tz(this.timezone).format('YYYY-MM-DD') : 'undefined';
  }

  /**
   * Downloads anomaly video
   */
  downloadVideo(): void {
    super.downloadAnomalyVideo(this.anomaly, this.timezone);
  }

  onSaveAndCategorise(): void {
    const anomalyDetail: AnomalyDetail = {
      id: this.anomaly.id,
      startTime: this.anomaly.start,
      endTime: this.anomaly.end,
      videoUrl: this.anomaly.videoUrl,
      timezone: this.timezone,
    };
    super.onSaveAndCategorise(anomalyDetail);
  }

  isNxWitnessCamera(cameraUri): boolean {
    return NX_WITNESS_RTSP_REGEX.test(cameraUri);
  }

  nxWitnessCameraTimeUri(cameraUri,timestamp): string {
    let outputStr = '#';
    if (NX_WITNESS_RTSP_REGEX.test(cameraUri) && timestamp) {
      const anomalyStart = moment(timestamp).valueOf();// returns milliseconds
      const regexMatch = cameraUri.match(NX_WITNESS_RTSP_REGEX);
      outputStr = `https://${regexMatch[1]}:7001/#/view/${regexMatch[2]}?time=${anomalyStart}`;
    }
    return outputStr;
  }
}
