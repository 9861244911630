/* eslint-disable @typescript-eslint/naming-convention */
import { LiveAnomaly, LiveAnomalyStatus } from '@core/models/anomaly/anomaly.model';
import {
  Incident,
  IncidentAPI,
  IncidentAPIPagination,
  IncidentCategory,
  IncidentCategoryAPI,
  IncidentStatus,
  IncidentStatusAPI,
  IncidentStatusAPIPagination,
  IncidentCategoryAPIPagination,
} from '@incidents/models/incident.models';

export const mockUsers = [
  {
    id: 1,
    email: 'jsmith@mock.com',
    firstName: 'John',
    lastName: 'Smith',
    forceResetPassword: false,
    role: null,
  },
  {
    id: 2,
    email: 'jwilliams@mock.com',
    firstName: 'Jane',
    lastName: 'Williams',
    forceResetPassword: false,
    role: null,
  },
  {
    id: 3,
    email: 'kanderson@mock.com',
    firstName: 'Keith',
    lastName: 'Anderson',
    forceResetPassword: false,
    role: null,
  },
];

export const emptyMockUserName = {
  id: 1,
  email: 'jsmith@mock.com',
  firstName: '',
  lastName: '',
  forceResetPassword: false,
  role: null,
};

export const emptyMockUser = {
  id: 1,
  email: '',
  firstName: '',
  lastName: '',
  forceResetPassword: false,
  role: null,
};

export const mockIncidentCategories: Array<IncidentCategoryAPI> = [
  {
    id: 1,
    parent: null,
    name: 'Uncategorised',
    description: 'Uncategorised',
    order: 0,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 2,
    parent: 1,
    name: 'Accidents',
    description: 'Accidents',
    order: 0,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: true,
  },
  {
    id: 3,
    parent: 2,
    name: 'Falling objects',
    description: 'Falling objects',
    order: 0,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 4,
    parent: 2,
    name: 'Machinery malfunction',
    description: 'Machinery malfunction',
    order: 1,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 5,
    parent: 2,
    name: 'Other',
    description: 'Other',
    order: 2,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 6,
    parent: 1,
    name: 'Human interactions',
    description: 'All items that need to be categorised',
    order: 1,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: true,
  },
  {
    id: 7,
    parent: 6,
    name: 'Theft',
    description: 'Theft',
    order: 0,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 8,
    parent: 6,
    name: 'Human error',
    description: 'Human error',
    order: 1,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 9,
    parent: 6,
    name: 'Trespassing',
    description: 'Trespassing',
    order: 0,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 10,
    parent: 1,
    name: 'Unclassified',
    description: 'Unclassified',
    order: 2,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
];

export const incidentCategories: Array<IncidentCategory> = mockIncidentCategories;

export const mockCategoriesAPIPaginated: IncidentCategoryAPIPagination = {
  count: 10,
  results: mockIncidentCategories,
};

export const mockIncidentStatuses: Array<IncidentStatusAPI> = [
  {
    id: 1,
    name: 'Unresolved',
    description: 'Unresolved incident',
    order: 1,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: true,
  },
  {
    id: 2,
    name: 'Resolved',
    description: 'Resolved incident',
    order: 2,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
  {
    id: 3,
    name: 'Open',
    description: 'Open incident',
    order: 3,
    created: '2020-01-01 00:00:00',
    updated: null,
    default: false,
  },
];

export const incidentStatuses: Array<IncidentStatus> = mockIncidentStatuses;

export const mockIncidentStatusesPagination: IncidentStatusAPIPagination = {
  count: 3,
  results: mockIncidentStatuses,
};

export const mockLiveAnomalies: Array<LiveAnomaly> = [
  {
    sender: 'icetana-algorithm',
    timestamp: '2021-01-01T12:00:00.000Z',
    body: {
      status: LiveAnomalyStatus.Start,
      cameraGuid: '11111111-1111-1111-1111-111111111111',
      cameraName: 'TestCamera',
      anomalyGuid: '11111111-1111-1111-1111-111111111111',
      hlsUri: 'https://localhost/streams/11111111-1111-1111-1111-111111111111/stream.m3u8',
      start: 1609502400000000,
      end: 1609502400000000,
    },
  },
  {
    sender: 'icetana-algorithm',
    timestamp: '2021-02-01T12:00:00.000Z',
    body: {
      status: LiveAnomalyStatus.Start,
      cameraGuid: '22222222-2222-2222-2222-222222222222',
      cameraName: 'TestCamera',
      anomalyGuid: '22222222-2222-2222-2222-222222222222',
      hlsUri: 'https://localhost/streams/22222222-2222-2222-2222-222222222222/stream.m3u8',
      start: 1612180800000000,
      end: 1612180800000000,
    },
  },
  {
    sender: 'icetana-algorithm',
    timestamp: '2021-03-01T12:00:00.000Z',
    body: {
      status: LiveAnomalyStatus.Start,
      cameraGuid: '33333333-3333-3333-3333-333333333333',
      cameraName: 'TestCamera',
      anomalyGuid: '33333333-3333-3333-3333-333333333333',
      hlsUri: 'https://localhost/streams/33333333-3333-3333-3333-333333333333/stream.m3u8',
      start: 1614600000000000,
      end: 1614600000000000,
    },
  },
  {
    sender: 'icetana-algorithm',
    timestamp: '2021-04-01T12:00:00.000Z',
    body: {
      status: LiveAnomalyStatus.Start,
      cameraGuid: '44444444-4444-4444-4444-444444444444',
      cameraName: 'TestCamera',
      anomalyGuid: '44444444-4444-4444-4444-444444444444',
      hlsUri: 'https://localhost/streams/44444444-4444-4444-4444-444444444444/stream.m3u8',
      start: 1617278400000000,
      end: 1617278400000000,
    },
  },
  {
    sender: 'icetana-algorithm',
    timestamp: '2021-05-01T12:00:00.000Z',
    body: {
      status: LiveAnomalyStatus.Start,
      cameraGuid: '55555555-5555-5555-5555-555555555555',
      cameraName: 'TestCamera',
      anomalyGuid: '55555555-5555-5555-5555-555555555555',
      hlsUri: 'https://localhost/streams/55555555-5555-5555-5555-555555555555/stream.m3u8',
      start: 1619870400000000,
      end: 1619870400000000,
    },
  },
];

export const mockIncidentsAPI: Array<IncidentAPI> = [
  {
    id: 1,
    subject: 'Subject 1',
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
Maecenas vulputate urna sit amet mattis egestas. Suspendisse volutpat consequat ullamcorper.`,
    anomaly: 0,
    categories: [2, 3],
    reporter: {
      id: 2,
      first_name: 'John',
      last_name: 'Smith',
      full_name: 'John Smith',
    },
    started: '2021-01-01 12:00:00',
    ended: null,
    status: 1,
    created: '2021-01-01 12:00:00',
    updated: '2021-01-01 12:00:00',
  },
];

export const mockIncidentsAPIPagination: IncidentAPIPagination = {
  count: 1,
  results: mockIncidentsAPI,
};

export const singleIncident: Incident = {
  id: 1,
  subject: 'Subject 1',
  body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
Maecenas vulputate urna sit amet mattis egestas. Suspendisse volutpat consequat ullamcorper.`,
  anomaly: 1,
  categories: [2, 3],
  reporter: {
    id: 2,
    firstName: 'John',
    lastName: 'Smith',
    fullName: 'John Smith',
  },
  started: '2021-01-01 12:00:00',
  ended: null,
  status: 0,
  created: '2021-01-01 12:00:00',
  updated: '2021-01-01 12:00:00',
};

export const mockIncidents: Array<Incident> = [
  {
    id: 1,
    subject: 'Subject 1',
    body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
Maecenas vulputate urna sit amet mattis egestas. Suspendisse volutpat consequat ullamcorper.`,
    anomaly: 0,
    categories: [2, 3],
    reporter: {
      id: 2,
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
    },
    started: '2021-01-01 12:00:00',
    ended: null,
    status: 0,
    created: '2021-01-01 12:00:00',
    updated: '2021-01-01 12:00:00',
    timezone: 'Australia/Perth',
  },
  {
    id: 2,
    subject: 'Subject 2',
    body: `Sed consequat nisl quis purus fermentum, non mollis ex ornare. \
Vestibulum sit amet tempor ipsum. Fusce in viverra nisi. Duis pulvinar nisl ut eros pretium, quis laoreet enim lacinia.`,
    anomaly: 1,
    categories: [1],
    reporter: {
      id: 2,
      firstName: '',
      lastName: '',
      fullName: '',
    },
    started: '2021-02-01 12:00:00',
    ended: null,
    status: 1,
    created: '2021-02-01 12:00:00',
    updated: '2021-02-01 12:00:00',
    timezone: 'Australia/Perth',
  },
  {
    id: 3,
    subject: 'Subject 3',
    body: `Quisque non nunc neque. Nulla in magna laoreet, \
egestas arcu eget, elementum lacus. Vivamus in nunc vel leo finibus auctor et quis tellus. `,
    anomaly: 2,
    categories: [2, 3],
    reporter: {
      id: 2,
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
    },
    started: '2021-03-01 12:00:00',
    ended: null,
    status: 0,
    created: '2021-03-01 12:00:00',
    updated: '2021-03-01 12:00:00',
  },
  {
    id: 4,
    subject: 'Subject 4',
    body: `Aenean rhoncus, mi vitae accumsan bibendum, augue nunc elementum massa, \
in tempus velit nibh vel nisl. Phasellus lacinia, nibh non porttitor feugiat, \
nulla libero malesuada orci, nec sodales urna neque et elit.`,
    anomaly: 2,
    categories: [2],
    reporter: {
      id: 2,
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
    },
    started: '2021-04-01 12:00:00',
    ended: null,
    status: 0,
    created: '2021-04-01 12:00:00',
    updated: '2021-04-01 12:00:00',
  },
  {
    id: 5,
    subject: 'Subject 5',
    body: '',
    anomaly: 3,
    categories: [1],
    reporter: {
      id: 2,
      firstName: 'John',
      lastName: 'Smith',
      fullName: 'John Smith',
    },
    started: '2021-05-01 12:00:00',
    ended: null,
    status: 0,
    created: '2021-05-01 12:00:00',
    updated: '2021-05-01 12:00:00',
  },
];
