/* eslint-disable @typescript-eslint/member-ordering */
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Clipboard } from '@angular/cdk/clipboard';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ofType } from '@ngrx/effects';
import { select } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';

import { AnomalyViewComponent } from '@anomalies/components/anomaly-view/anomaly-view.component';
import {
  CommonVideoIncidentComponent,
  CommonVideoIncidentProvider
} from '@app/containers/common-video-incident/common-video-incident.component';
import { Camera } from '@cameras/models/cameras/cameras.models';
import { HIGHLIGHT_GRID, FEATURE_FLAG_ANOMALY_DIAGNOSTICS } from '@core/constants/app.constants';
import { Anomaly, AnomalyDetail } from '@core/models/anomaly/anomaly.model';
import {
  highlightGridAnomaliesPlaybackState,
  highlightGridPlaybackSpeed,
  anomaliesHighlightGridListLoading
} from '@core/store/anomalies/anomalies.selectors';
import { highlightGridPlaybackState, highlightGridSpeedConfig } from '@core/store/anomalies/anomalies.actions';
import {
  selectSettingsDateRangeStart,
  selectSettingsDateRangeEnd,
  selectSettingsHighlightCameraFilter
} from '@core/store/user-settings/user-settings.selectors';
import { HighlightGridPlaybackState } from '@highlight-grid/constants/highlight-grid.constants';
import { RadioFilterType } from '@highlight-grid/models/settings.model';
import { GridEmitterService, DownloadHighlightState } from '@highlight-grid/services/grid-emitter.service';
import { Incident, IncidentCategoryTree, IncidentStatus } from '@incidents/models/incident.models';
import { incidentCreateSuccess } from '@incidents/store/incidents.actions';
import { ServerService } from '@server/services/server.service';
import { VjsPlayerComponent } from '@shared/components/vjs-player/vjs-player.component';

export const DOWNLOAD_DIALOG_ID = `confirm-download`;
export const SCROLL_WINDOW_VIEWPORT = `cdk-virtual-scroll-viewport`;
export const MAIN_BODY_SELECTOR = `main-body`;


@Component({
  selector: 'icetana-grid-scroll',
  templateUrl: './grid-scroll.component.html',
  styleUrls: ['./grid-scroll.component.scss'],
  animations: [
    /** Animate pause icon fade out to indicate the grid is in pause mode */
    trigger('pauseState', [
      state(
        String(HighlightGridPlaybackState.playing),
        style({
          visibility: 'hidden',
        })
      ),
      state(
        String(HighlightGridPlaybackState.paused),
        style({
          opacity: 0,
          fontSize: '22rem',
        })
      ),
      state(
        String(HighlightGridPlaybackState.played),
        style({
          visibility: 'hidden',
        })
      ),
      transition(`${HighlightGridPlaybackState.playing} => ${HighlightGridPlaybackState.paused}`, [animate('0.5s')]),
    ]),
    /** Animate play icon fade out to indicate the grid is in play mode */
    trigger('playState', [
      state(
        String(HighlightGridPlaybackState.playing),
        style({
          opacity: 0,
          fontSize: '22rem',
        })
      ),
      state(
        String(HighlightGridPlaybackState.paused),
        style({
          visibility: 'hidden',
        })
      ),
      state(
        String(HighlightGridPlaybackState.played),
        style({
          visibility: 'hidden',
        })
      ),

      transition(`${HighlightGridPlaybackState.paused} => ${HighlightGridPlaybackState.playing}`, [animate('0.5s')]),
    ]),
  ],
})
export class GridScrollComponent extends CommonVideoIncidentComponent implements AfterViewInit, OnDestroy {
  /** HTML Elements */
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<HTMLElement>; // Confirmation dialog element
  @ViewChild(MatMenuTrigger) rightClickMenuTrigger: MatMenuTrigger; //Right click menu trigger element
  @ViewChildren('video') videoList: QueryList<VjsPlayerComponent>; // List of video js, video players element

  //https://stackoverflow.com/a/41095677
  private scrollingViewPort: CdkVirtualScrollViewport;// Virtual Scroll Window element
  @ViewChild('contentViewport') set content(content: CdkVirtualScrollViewport) {
    if (content) {
      this.scrollingViewPort = content;
      this.scrollingViewPort.elementScrolled()
      .pipe(takeUntil(this.viewportScrollingEventNotifier$))
      .subscribe(_ => this.viewportScrollingEvent());
      this.viewportScrollingEvent();
    }
    else {
      this.viewportScrollingEventNotifier$.next();
      this.viewportScrollingEventNotifier$.complete();
    }
  }

  /** Inputs from highlights component */
  @Input() cameras: Camera[]; // list of cameras passed through to the grid-scroll directive
  @Input() anomalies$: Observable<Anomaly[]>; // observable list of anomalies passed through to the grid-scroll directive
  @Input() incidents$: Observable<Incident[]>; // observable list of incidents passed through to the grid-scroll directive
  @Input() incidentFilter$: Observable<Array<RadioFilterType>>;
  @Input() incidentCategoryFilter$: Observable<number[]>;
  @Input() anomalyModelFilter$: Observable<string[]>;
  @Input() anomalyLabelFilter$: Observable<string[]>;
  @Input() defaultCategory: IncidentCategoryTree; // default category used for the creation of new incidents
  @Input() defaultStatus: IncidentStatus; // default status used for the creation of new incidents
  @Input() leftToolbarsStateTrigger: BehaviorSubject<boolean>; // trigger subject for capturing the state of the left toolbar state
  @Input() anomalySourceSelector$ = new BehaviorSubject<boolean>(true); /** default to true */

  /** Anomaly related storage */
  anomalies?: Anomaly[]; // Stores anomalies list
  leftToolbarsPreviousState = true; // The stored previous state of the left side parent toolbar, defaulting to open (true)
  anomalyRows: [Anomaly[]] = [[]]; // Separately store the anomalies in rows, based on the view window size
  canLoadMoreAnomalies = false; // Placeholder for loading more anomalies when reaching the bottom of the page, requires fixing
  isLoading: boolean;
  currentIncidentFilter: RadioFilterType;
  viewportScrollingEventNotifier$ = new Subject(); // For unsubscribing to the elementScrolled inside of scrollingViewPort
  timezone: string;

  /** Column count subject and supporting functions */
  colCountSubject = new BehaviorSubject(2); // column count used for publishing how many anomalies to store per row
  colCountValue = 2;
  colCountReducerForSize = (colCountSubject) => (result, item) => {
    const lastElm = result[result.length - 1];
    if (lastElm.length === colCountSubject) {
      result.push([item]);
    } else {
      lastElm.push(item);
    }
    return result;
  };
  breakdownColumns = (arr, colCountSubject) => arr.reduce(this.colCountReducerForSize(colCountSubject), [[]]);
  breakPointFunction(breakpointPosition): void {
    // offset the column count based on the left toolbar collapsed state
    const colCountOffset = this.leftToolbarsPreviousState ? 0 : 1;

    if (breakpointPosition.breakpoints[Breakpoints.XSmall]) { // Max-width=599.99px
      this.colCountSubject.next(1); // 1 column
    }
    else if (breakpointPosition.breakpoints[Breakpoints.Small]) { // Min-width=600px, Max-width=959.99px
      this.colCountSubject.next(2); // 2 columns
    }
    // else if (breakpointPosition.breakpoints[Breakpoints.Medium]) { // Min-width=960px, Max-width=1279.99px
    //   this.colCountSubject.next(3 + colCountOffset); // Min 3 columns + an additional column when the left side menu is collapsed
    // }
    // else if (breakpointPosition.breakpoints[Breakpoints.Large]) { // Min-width=1280px, Max-width=1919.99px
    //   this.colCountSubject.next(4 + colCountOffset); // Min 4 columns + an additional column when the left side menu is collapsed
    // }
    // else { // Min-width=1920px
    else { // Min-width=960px
      this.colCountSubject.next(3 + colCountOffset); // Min 3 columns + an additional column when the left side menu is collapsed
    }
  };

  /** Stores incidents list for matching with the list of anomalies */
  incidents?: Incident[];

  /** Right-click menu variables */
  public contextMenuPosition = { x: '0px', y: '0px' }; // for storing the position of the context menu
  videoInCell = null; // for storing the video cell context when right-clicking

  /**For video generation / splicing in the UI */
  public isGeneratingDownload?: boolean;
  public abortController?: any;

  /** Date fields */
  private dateRangeStart?: Date;
  private dateRangeEnd?: Date;

  /** Playback variables */
  playbackState$: Observable<HighlightGridPlaybackState>; // Returns observable of the current state of the playback
  private playbackSpeed: number; // stores the playback speed
  activeSpeed = 1;// speed INDEX
  speedList = [
    { value: 0, label: 'Paused', containerClass: 'pause', iconClass: 'icon-pause' },
    { value: 1, label: 'Playing 1x', containerClass: 'play-1x', iconClass: 'icon-play-1' },
    { value: 2, label: 'Playing 2x', containerClass: 'play-2x', iconClass: 'icon-play-2' },
    { value: 6, label: 'Playing 6x', containerClass: 'play-6x', iconClass: 'icon-play-3' },
  ];
  pause = this.speedList[0];
  stopIndex = this.speedList.indexOf(this.pause);

  /** Indicates the minimum time increment (in minutes) to show in the scroll area for time labels */
  timeIncrement = 15;

  /** Scroll variables */
  scrollPos = ''; // position is parsed in as a string for easy usage with stylesheets
  scrollDate = ''; // label for showing when scrolling
  showScrollInfo = false; // flag for displaying scrolling information
  oldScrollPosition = null; // recall last known scroll position
  mouseDownScroll = false; // flag for detecting mouse-down event on the scrollbar
  scrollLabelTimeout = null; // timeout function for indicating when to hide the scroll info
  scrollAtTop = true; // flag for indicating is scroll position has reached the top
  scrollAtBottom = false; // flag for indicating is scroll position has reached the bottom

  featureAnomalyDiagFlag = false; // feature flag for anomaly diagnostics

  constructor(
    public commonVideoIncident: CommonVideoIncidentProvider,
    private gridEmitterService: GridEmitterService,
    private serverService: ServerService,
    breakpoint$: BreakpointObserver,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
  ) {
    super(commonVideoIncident);
    this.abortController = null;

    // Listening for window resize changes
    breakpoint$.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      // Breakpoints.Medium,
      // Breakpoints.Large,
    ]).subscribe(result => this.breakPointFunction(result));
  }

  onSpeedChange(speedIndex: number): void {
    const oldSpeed = this.activeSpeed;
    if (oldSpeed !== speedIndex) {
      const playbackState =
        this.stopIndex === speedIndex ? HighlightGridPlaybackState.paused : HighlightGridPlaybackState.playing;

      this.commonVideoIncident.store.dispatch(highlightGridPlaybackState({ payload: { playbackState } }));
      this.commonVideoIncident.store.dispatch(highlightGridSpeedConfig({ payload: { speed: this.speedList[speedIndex].value } }));

      this.activeSpeed = speedIndex;
    }
  }

  /** keyboard event binding for listening for space bar, left and right keyboard clicks to control playback */
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // check that keyboard events are occuring on the main document body
    if (!(event.target as Element).classList.contains(MAIN_BODY_SELECTOR)) {
      return;
    }

    switch (event.code) {
      case 'ArrowLeft':
        event.preventDefault();
        if (this.activeSpeed > 0) {
          this.onSpeedChange(this.activeSpeed - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (this.activeSpeed < this.speedList.length - 1) {
          this.onSpeedChange(this.activeSpeed + 1);
        }
        break;
      case 'Space':
        event.preventDefault();
        if (this.activeSpeed === this.stopIndex) {
          this.onSpeedChange(this.stopIndex + 1);
        }
        else {
          this.onSpeedChange(this.stopIndex);
        }
        break;
    }
  }

  /** Toggle button function for playback */
  onToggleSpeedButton(): void {
    // increment from speedList
    if (this.activeSpeed >= this.speedList.length-1) {
      this.onSpeedChange(this.stopIndex);
    }
    else {
      this.onSpeedChange(this.activeSpeed + 1);
    }
  }

  /** Right-click menu listener event */
  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event): void {
    if (!this.canClick) {
      event.preventDefault();
      return;
    }
    // determine which video grid is the target
    const videos = this.videoList.toArray();
    const matchedVideo = videos.find((video) =>
      video.video.nativeElement === event.target
    );
    if (matchedVideo) {
      this.videoInCell = matchedVideo;
    }
    super.rightClickVideoMenu(
      event,
      this.rightClickMenuTrigger,
      matchedVideo,
      this.contextMenuPosition,
      () => {}
    );
  }

  getAnomalyIdForPlayer(player: VjsPlayerComponent): number {
    return player.anomalyId;
  }

  onSaveAndCategorise(): void {
    const anomalyId = this.getAnomalyIdForPlayer(this.videoInCell);
    const anomaly = this.anomalies.find((a) => a.id === anomalyId);
    // get camera object
    const foundCamera = this.cameras.find((camera) => camera.id === anomaly.camera);

    const anomalyDetail: AnomalyDetail = {
      id: anomaly.id,
      startTime: anomaly.start,
      endTime: anomaly.end,
      videoUrl: anomaly.videoUrl,
      timezone: foundCamera.timezone || moment.tz.guess()
    };
    super.onSaveAndCategorise(anomalyDetail);
  }

  onQuickSave(categoryId: number): void {
    const anomalyId = this.getAnomalyIdForPlayer(this.videoInCell);
    const anomaly = this.anomalies.find((a) => a.id === anomalyId);
    super.onQuickSave(anomaly,categoryId);
  }

  get canClick(): boolean {
    const currentServers = this.serverService.currentServers.value;
    return currentServers.length === 1;
  }

  /** listen for mouse down event on the scroll view */
  @HostListener('window:mousedown', ['$event.target']) mouseDwn(event) {
    if (event.matches(SCROLL_WINDOW_VIEWPORT)) {
      this.showScrollInfo = true;
      this.mouseDownScroll = true;
    }
  }
  /** listen for mouse up event on the scroll view */
  @HostListener('window:mouseup', ['$event.target']) mouseUp(event) {
    if (event.matches(SCROLL_WINDOW_VIEWPORT)) {
      this.showScrollInfo = false;
      this.mouseDownScroll = false;
    }
  }

  /** jump to top of scroll window */
  jumpToTop(): void {
    if (this.scrollingViewPort) {
      this.scrollingViewPort.scrollToIndex(0);
    }
  }
  /** jump to bottom of scroll window */
  jumpToBottom(): void {
    if (this.scrollingViewPort) {
      this.scrollingViewPort.scrollToIndex(this.anomalyRows.length);
    }
  }

  recheckScrollMovement(): void {
    if (this.scrollingViewPort) {
      const currentScrollPosition = this.scrollingViewPort.measureScrollOffset('top');
      if (this.mouseDownScroll || (currentScrollPosition !== this.oldScrollPosition) ) {
        this.scrollStopLabelTimeout();
      }
      else if (this.scrollLabelTimeout === null) {
        this.scrollStartLabelTimeout();
      }
    }
  }

  scrollStartLabelTimeout(): void {
    this.scrollLabelTimeout = setTimeout(() => {
      this.showScrollInfo = false;
      // reset timeout
      this.scrollLabelTimeout = null;
    }, 2000);
  }

  scrollStopLabelTimeout(): void {
    clearTimeout(this.scrollLabelTimeout);
    // reset timeout
    this.scrollLabelTimeout = null;
  }

  matchIncidentWithAnomaly(anomaly: Anomaly): boolean {
    return this.incidents.findIndex((e) => (e.anomaly === anomaly.id)) !== -1;
  }

  openAnomalyView(anomaly: Anomaly, event?: Event): void {
    if (!this.canClick) {
      if (event) {
        event.preventDefault();
      }
      return;
    }

    let currentVideoTime = 0;
    if (event) {
      // Grab the video element
      const videos = this.videoList.toArray();
      const matchedVideo = videos.find((video) =>
        video.video.nativeElement === event.target
      );
      // Extract current time from video for sending
      currentVideoTime = matchedVideo ?  matchedVideo.player.currentTime() : 0;
    }

    // get camera object
    const foundCamera = this.cameras.find((camera) => camera.id === anomaly.camera);

    // find anomalyIdx
    const anomalyIdx = this.anomalies.findIndex(anom => anom.id===anomaly.id);

    const dialogModal = this.commonVideoIncident.dialog.open(AnomalyViewComponent, {
      data: {
        camera: foundCamera,
        anomaly,
        availableCategories$: this.availableCategories$,
        availableStatuses$: this.availableStatuses$,
        incidentExists: this.matchIncidentWithAnomaly(anomaly),
        videoStartTime: currentVideoTime,
        showNext: anomalyIdx < (this.anomalies.length-1),
        showPrev: anomalyIdx > 0,
      },
      width: '100%',
      panelClass: 'icetana-modal-anomaly-view-panel'
    });

    dialogModal.afterClosed().pipe(first()).subscribe(res => {
      if (res && res.next && anomalyIdx < (this.anomalies.length-1)) {
        this.openAnomalyView(this.anomalies[anomalyIdx+1]);
      }
      else if (res && res.prev && anomalyIdx > 0) {
        this.openAnomalyView(this.anomalies[anomalyIdx-1]);
      }
    });
  }

  // Used for calculating the time labels
  timeDivider(rowIndex: number): string {
    if (
      !this.anomalies ||
      !this.anomalyRows ||
      !this.anomalies.length ||
      !this.anomalyRows.length
    ) {
      return '';
    }
    const currentRow = moment(this.anomalyRows[rowIndex][0].start);
    const currentRemainder = (currentRow.minute() % this.timeIncrement);
    const roundedCurrent = currentRow.subtract(currentRemainder, 'minutes');

    if (rowIndex > 0) {
      const previousRow = moment(this.anomalyRows[rowIndex-1][0].start);
      if (roundedCurrent.isBefore(previousRow)) {
        return '';
      }
    }
    return `${roundedCurrent.tz(this.timezone).format('YYYY-MM-DD HH:mm')} (${this.timezone})`;
  }


  private viewportScrollingEvent(): void {
    // render the scroll info
    this.showScrollInfo = true;

    // store the scroll position
    this.oldScrollPosition = this.scrollingViewPort.measureScrollOffset('top');

    const viewportHeight = this.scrollingViewPort.elementRef.nativeElement.offsetHeight;
    const elementHeight = 280;
    const scrollPosition = this.oldScrollPosition;
    const offsetBias = -13;
    const minScrollThumbHeight = 20;
    const scaledScrollThumbHeight = ( viewportHeight * viewportHeight /  (this.anomalyRows.length * elementHeight) );
    const actualScrollThumbHeight = scaledScrollThumbHeight < minScrollThumbHeight ? minScrollThumbHeight : scaledScrollThumbHeight;
    const percentScrolled = (scrollPosition) / ((this.anomalyRows.length * elementHeight) - viewportHeight);
    const scaledScrollPos = percentScrolled * (viewportHeight - actualScrollThumbHeight);
    // determine the scroll position for rendering the scroll info
    this.scrollPos = ( scaledScrollPos + offsetBias + actualScrollThumbHeight/2  ).toString() + 'px';

    if (percentScrolled <= 0) {
      this.scrollAtTop = true;
      this.scrollAtBottom = false;
    }
    // if withing 0.1% of the end, or exceeding the end
    else if ((Math.abs(1 - percentScrolled) < 0.001) || percentScrolled >= 1) {
      this.scrollAtTop = false;
      this.scrollAtBottom = true;
    }
    else {
      this.scrollAtTop = false;
      this.scrollAtBottom = false;
    }

    // examine the scroll position to determine the date to display in the scroll-text
    if (
      !this.anomalies ||
      !this.anomalyRows ||
      !this.anomalies.length ||
      !this.anomalyRows.length
    ) {
      return;
    }

    let date = null;

    // determine if position is at top
    if (this.scrollAtTop) {
      date = parseFloat(moment(this.anomalies[0].start).unix().toFixed(2));
    }
    // determine if position is at bottom
    else if (this.scrollAtBottom) {
      date = parseFloat(moment(this.anomalies[this.anomalies.length - 1].start).unix().toFixed(2));

      // check for requiring more anomalies to be loaded
      if (this.canLoadMoreAnomalies) {
        // console.log('load more content');
        // this.commonVideoIncident.store.dispatch(highlightGridAnomaliesInit({ payload: { pageLimit: 1000, pageOffset: 1000 } }));
      }
    }
    // otherwise determine the positioning relative to the anomaly array
    else {
      const anomalyPos = percentScrolled*(this.anomalies.length - 1);
      const rowIdx = Math.floor( anomalyPos );
      date = parseFloat(moment(this.anomalies[rowIdx].start).unix().toFixed(2));
    }

    // round back to the nearest 15mins
    const parsedDate = moment.unix(date);
    const dateRemainder = (parsedDate.minute() % this.timeIncrement);
    const roundedDate = parsedDate.subtract(dateRemainder, 'minutes');
    this.scrollDate = `${roundedDate.tz(this.timezone).format('YYYY-MM-DD HH:mm')} (${this.timezone})`;

    // lastly run the timeout on the scroll info
    this.recheckScrollMovement();
  }

  public ngAfterViewInit(): void {
    this.timezone = moment.tz.guess();

    this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
      // detect any feature flags
      this.featureAnomalyDiagFlag =
        params[FEATURE_FLAG_ANOMALY_DIAGNOSTICS] && params[FEATURE_FLAG_ANOMALY_DIAGNOSTICS] === 'true';
    });

    this.colCountSubject
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((colCountSubject) => {
      this.colCountValue = colCountSubject;

      if (this.anomalies && this.anomalies.length && this.colCountValue) {
        this.anomalyRows = this.breakdownColumns(this.anomalies, this.colCountValue);
      }

    });

    // trigger subject for left toolbar expand / collapse event
    // to add additional column to grid
    this.leftToolbarsStateTrigger.subscribe((data) => {
      if (data === this.leftToolbarsPreviousState) {
        return;
      }
      // ignore adding additional column if screensize is only accomadating 2 or less
      const currentColCount = this.colCountSubject.value;
      if (currentColCount < 3) {
        return;
      }

      if (data) {
        this.colCountSubject.next(currentColCount - 1);
      }
      else {
        this.colCountSubject.next(currentColCount + 1);
      }
      this.leftToolbarsPreviousState = data;
    });

    // on menu close, clear the videoInCell property
    this.rightClickMenuTrigger.menuClosed.subscribe(() => {
      this.videoInCell = null;
    });

    // subscribe to changes to the videoList viewChildren
    // retains playstate
    this.videoList.changes.subscribe((videoList) => {
      const videos = videoList.toArray();
      for (const video of videos) {
        video.player.loadingSpinner.hide();
        if ((this.playbackSpeed > 0) && (video.player.paused)) {
          video.player.playbackRate(this.playbackSpeed);
          video.player.play().catch((err) => {
            console.log(err);
          });
        }
      }
    });

    this.onPlaybackSpeedChangedHandler();
    this.onPlaybackPlayStateChangedHandler();
    this.assignAnomalyHighlightsToBePlayed();
    this.onDownloadButtonClicked();

    this.commonVideoIncident.store.pipe(select(selectSettingsDateRangeStart)).subscribe((date) => {
      this.dateRangeStart = date;
    });
    this.commonVideoIncident.store.pipe(select(selectSettingsDateRangeEnd)).subscribe((date) => {
      this.dateRangeEnd = date;
    });

    // listen to any incident creation or update events, and ensure to append to the incident list, and update any matching video grid
    this.commonVideoIncident.action$.pipe(ofType(incidentCreateSuccess), takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        const incident = data.payload;
        this.incidents.push(incident);

        // if incident filter is set to 'without', remove anomaly from array
        if (this.currentIncidentFilter && this.currentIncidentFilter.id === 'without') {
          const anomalyToRemove = this.anomalies.findIndex(anomaly => anomaly.id === incident.anomaly);
          this.anomalies.splice(anomalyToRemove,1);
          this.anomalyRows = this.breakdownColumns(this.anomalies, this.colCountValue);
        }
      });

    this.commonVideoIncident.store.pipe(select(anomaliesHighlightGridListLoading))
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((loading) => {
      this.isLoading = loading;
    });

    this.anomalySourceSelector$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((sourceFlag) => {
      if (!sourceFlag) {
        this.commonVideoIncident.snackBar.open('No cameras selected', null, {
          duration: 5000,
        });
      }
    });
  }

  /*
    * This action is triggered from PlaybackControlsComponent via GridEmitterService
    */
  private onDownloadButtonClicked(): void {
    if (!this.gridEmitterService.subscribedDownloadClicked) {
      this.gridEmitterService.subscribedDownloadClicked = this.gridEmitterService.downloadClicked
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((downloadState) => {
          this.downloadDialogPrompt(downloadState);
        });
    }
  }

  /*
   * This handles highlight grid play/pause/paused
   * states which triggered by  or events
   */
  private onPlaybackPlayStateChangedHandler(): void {
    this.playbackState$ = this.commonVideoIncident.store.pipe(select(highlightGridAnomaliesPlaybackState));
    this.playbackState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((gridState) => {
      if (this.videoList.length) {
        if (gridState !== null) {
          this.updateHighlightGridState(gridState);
        }
      }
    });
  }

  /*
   * Updates the highlight grid state after certain operation is done
   * @param gridState HighlightGridPlaybackState
   */
  updateHighlightGridState(gridState: HighlightGridPlaybackState): void {
    let playbackState = gridState;
    if (gridState === HighlightGridPlaybackState.play) {
      playbackState = HighlightGridPlaybackState.playing;
    }
    if (gridState === HighlightGridPlaybackState.pause) {
      playbackState = HighlightGridPlaybackState.paused;
    }
    if (gridState === HighlightGridPlaybackState.replay) {
      playbackState = HighlightGridPlaybackState.playing;
    }
    this.commonVideoIncident.store.dispatch(highlightGridPlaybackState({ payload: { playbackState } }));
  }

  /*
   * Goes through available highlight anomalies streams and
   * Starts assigning anomaly footage to video players once they are ready
   */
  private assignAnomalyHighlightsToBePlayed(): void {
    combineLatest([
      this.anomalies$.pipe(takeUntil(this.ngUnsubscribe)),
      this.incidents$.pipe(takeUntil(this.ngUnsubscribe)),
      this.incidentFilter$.pipe(takeUntil(this.ngUnsubscribe)),
      this.incidentCategoryFilter$.pipe(takeUntil(this.ngUnsubscribe)),
      this.anomalyModelFilter$.pipe(takeUntil(this.ngUnsubscribe)),
      this.anomalyLabelFilter$.pipe(takeUntil(this.ngUnsubscribe)),
    ])
      .subscribe(([anomalies, incidents, incidentFilter, incidentCategoryFilter, anomalyModelFilter, anomalyLabelFilter]) => {
        this.currentIncidentFilter = incidentFilter.find(filter => filter.checked);

        // reset the scroll info
        this.scrollDate = '';
        this.scrollPos = '';
        this.showScrollInfo = false;
        this.oldScrollPosition = null;
        this.mouseDownScroll = false;

        if (this.scrollingViewPort) {
          this.scrollingViewPort.scrollToIndex(0);
        }

        // Apply filters
        // Filter anomalies of any anomalies that are still happening, i.e. end=null
        let filteringAnomalies = anomalies ? [...anomalies].filter(anomaly => anomaly.end !== null) : [];

        const selectedIncidentFilter = incidentFilter.find(filter => filter.checked);
        switch(selectedIncidentFilter.id) {
          case 'without':
            filteringAnomalies = filteringAnomalies.filter(anomaly => !incidents.some(incident => incident.anomaly === anomaly.id));
          break;
          case 'with':
            filteringAnomalies = filteringAnomalies.filter(anomaly => incidents.some(incident => incident.anomaly === anomaly.id));
          break;
          case 'category':
            // check if any categories are currently selected
            if (incidentCategoryFilter.length) {
              const filteredIncidents = incidents.filter(
                incident => incident.categories.some(category => incidentCategoryFilter.includes(category))
              );
              filteringAnomalies = filteringAnomalies.filter(
                anomaly => filteredIncidents.some(incident => incident.anomaly === anomaly.id)
              );
            }
            else {
              filteringAnomalies = filteringAnomalies.filter(anomaly => incidents.some(incident => incident.anomaly === anomaly.id));
            }
          break;
        }

        // Filter on anomaly model and label
        if (anomalyModelFilter && anomalyModelFilter.length) {
          filteringAnomalies = filteringAnomalies.filter(
            anomaly => anomalyModelFilter.some(anomalyModel => anomalyModel === anomaly.anomalyDiagnostic.modelName)
          );
        }
        if (anomalyLabelFilter && anomalyLabelFilter.length) {
          filteringAnomalies = filteringAnomalies.filter(
            anomaly => anomalyLabelFilter.some(anomalyLabel => {
              const anomalyDiagnosticLabel = this.getAnomalyLabel(anomaly);
              return (anomalyDiagnosticLabel.key === anomalyLabel) || (anomalyDiagnosticLabel.parentLabel === anomalyLabel);
            })
          );
        }

        if (!this.isLoading && (anomalies && !filteringAnomalies.length)) {
          this.commonVideoIncident.snackBar.open('No anomalies', null, {
            duration: 5000,
          });
        }

        const filteredAnomalies = filteringAnomalies;
        this.anomalies = filteredAnomalies;
        this.anomalyRows = this.breakdownColumns(this.anomalies, this.colCountValue);
        this.incidents = [...incidents];

        // check if anomaly count equals page limit
        this.canLoadMoreAnomalies = (this.anomalies.length >= 1000);
      });
  }

  /*
   * This is responsible for handling changes on playback speed
   * triggered by the user
   */
  private onPlaybackSpeedChangedHandler(): void {
    this.commonVideoIncident.store
      .pipe(select(highlightGridPlaybackSpeed))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((speed) => {
        this.activeSpeed = this.speedList.findIndex((e) => e.value === speed);

        this.playbackSpeed = speed;

        if (speed === undefined || speed === null || !this.videoList.length) {
          return;
        }

        if (speed > 0) {
          this.videoList.forEach((video) => {
            if (video.player) {
              video.player.autoplay(true);
              // Owing to the fact that the `play()` function is asynchronous, it is more suitable to perform the
              // configuration of the player prior to using the function rather than afterwards. Otherwise we will be
              // required to perform this configuration in a promise success callback.
              video.player.playbackRate(this.playbackSpeed);
              video.player.play();
                // .catch(() => video.player.loadingSpinner.hide());
            }
          });
        }

        if (speed === 0) {
          this.videoList.forEach((video) => {
            // We don't need to check if the video has been loaded before attempting to pause. Therefore, it'll either
            // pause the current video, or do nothing.
            video.player.pause();
          });
        }

      });
  }

  downloadDialogPrompt(downloadState: DownloadHighlightState): void {
    if (!this.anomalies?.length) {
      return;
    }

    let title = '';
    let message = '';
    switch (downloadState) {
      case DownloadHighlightState.videoreel:
        title = 'Download Video Reel';
        message = `Are you sure you want to download a video reel?
        <br/>This may take a long time to create when there are a large number of highlights.
        <br/>Number of highlights: ${this.anomalies?.length}`;
        break;
      case DownloadHighlightState.zipped:
        title = 'Download Individual Video Clips';
        message = `Are you sure you want to download all individual video clips?
        <br/>This may take a long time to generate when there are a large number of highlights.
        <br/>Number of highlights: ${this.anomalies?.length}`;
        break;
      default:
        return;
    }

    const dialogRef = this.commonVideoIncident.dialog.open(this.confirmDialog, {
      id: DOWNLOAD_DIALOG_ID,
      panelClass: 'confirmation-dialog',
      disableClose: true,
      data: {
        title,
        message,
        downloadState
      },
    });
    this.onDismissDialog(dialogRef);
  }

  onDismissDialog(dialogRef: MatDialogRef<HTMLElement, any>): void {
    const subscription = dialogRef.afterClosed().subscribe(() => {
      this.isGeneratingDownload = false;
      subscription.unsubscribe();
    });
  }

  onDialogCancel() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
    this.commonVideoIncident.dialog.getDialogById(DOWNLOAD_DIALOG_ID).close();
  }

  async onConfirmDownloadAllVideos(downloadState: DownloadHighlightState) {
    if (this.anomalies?.length) {
      this.abortController = new AbortController();

      this.isGeneratingDownload = true;
      const start = moment(this.dateRangeStart).format('YYYY-MM-DD HH:mm:ss');
      const end = moment(this.dateRangeEnd).format('YYYY-MM-DD HH:mm:ss');
      const fileName = `highlights-${start} to ${end}`;

      switch (downloadState) {
        case DownloadHighlightState.videoreel:
          await super.downloadAndMergeAnomalies(this.anomalies, fileName, this.abortController.signal);
          break;
        case DownloadHighlightState.zipped:
          await super.downloadZippedAnomalies(this.anomalies, fileName, this.abortController.signal);
          break;
      }

      this.abortController = null;
      this.isGeneratingDownload = false;
    }
    return this.commonVideoIncident.dialog.getDialogById(DOWNLOAD_DIALOG_ID).close();
  }

  generateShareLink(): void {
    combineLatest([
      this.commonVideoIncident.store.pipe(select(selectSettingsHighlightCameraFilter),take(1)),
      this.incidentFilter$.pipe(take(1)),
      this.incidentCategoryFilter$.pipe(take(1)),
      this.anomalyModelFilter$.pipe(take(1)),
      this.anomalyLabelFilter$.pipe(take(1)),
      this.commonVideoIncident.store.pipe(select(selectSettingsDateRangeStart),take(1)),
      this.commonVideoIncident.store.pipe(select(selectSettingsDateRangeEnd),take(1)),
    ]).subscribe(([
      cameraFilterIds,
      incidentFilter,
      categoryFilterIds,
      anomalyModelFilter,
      anomalyLabelFilter,
      startDate,
      endDate
    ]: [number[], RadioFilterType[], number[], string[], string[], Date, Date]) => {
      const startDateStr = `?start=${moment(startDate).unix()}`;
      const endDateStr = `&end=${moment(endDate).unix()}`;

      const selectedIncidentFilter = incidentFilter.find(incident => incident.checked);
      const incidentFilterStr = selectedIncidentFilter ? `&incidents=${selectedIncidentFilter.id}` : '';

      const cameraFilterStr = cameraFilterIds.length ? `&cameras=${cameraFilterIds.join(',')}` : '';
      const categoryFilterStr = categoryFilterIds.length ? `&categories=${categoryFilterIds.join(',')}` : '';

      const anomalyModelFilterStr = anomalyModelFilter.length ? `&event-models=${anomalyModelFilter.join(',')}` : '';
      const anomalyLabelFilterStr = anomalyLabelFilter.length ? `&event-labels=${anomalyLabelFilter.join(',')}` : '';

      const combinedUrl = `${window.location.protocol}//${window.location.host}/${HIGHLIGHT_GRID}\
${startDateStr}${endDateStr}${cameraFilterStr}${incidentFilterStr}${categoryFilterStr}${anomalyModelFilterStr}${anomalyLabelFilterStr}`;

      this.clipboard.copy(combinedUrl);
      super.toastPopup('Link copied to clipboard', ['snackbar-success-background']);
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
    this.videoList?.forEach((video) => video.player?.dispose());
    super.ngOnDestroy();
  }

}
