import { LiveAnomaly, LiveWallTile } from '@core/models/anomaly/anomaly.model';

export const getOldestAnomalyTile = (tiles: LiveWallTile, gridSize: number) => {
  let tileIndexOldest = -1;
  let oldestAnomaly: LiveAnomaly;

  for (let i = 0; i < gridSize; i++) {
    if (Object.keys(tiles).length < 1) {
      oldestAnomaly = tiles[i];
      tileIndexOldest = i;
      return i;
    } else if (i === 0) {
      oldestAnomaly = tiles[i];
      tileIndexOldest = i;
    } else if (
      tiles[i] !== undefined &&
      // Because the `start` property represents microseconds, it needs to be divided by 1000 in order for it to
      // represent milliseconds instead.
      //
      // -J.O.
      new Date(tiles[i].body.start / 1000) < new Date(oldestAnomaly.body.start / 1000)
    ) {
      oldestAnomaly = tiles[i];
      tileIndexOldest = i;
    }
  }

  return tileIndexOldest;
};
