/* eslint-disable @typescript-eslint/naming-convention */
import { Role, UserAPI } from '@auth/models/auth.models';
import { Site, Zone } from '@clients/models/client.models';
import {
  CreateUpdateAPIUser,
  CreateUpdateUser,
  PaginatedAPIUser,
  PaginatedUser,
  UserAPIPagination,
  UserPagination,
} from '@users/models/user.models';

export const mockRoles = [
  {
    id: 1,
    order: 1,
    name: 'Admin',
    description: 'Administrator',
  },
  {
    id: 2,
    order: 2,
    name: 'Operator',
    description: 'Operator description',
  },
  {
    id: 3,
    order: 3,
    name: 'Test',
    description: 'Test role',
  },
] as Array<Role>;

export const mockZones = [
  {
    id: 1,
    name: 'Zone 1',
  },
  {
    id: 2,
    name: 'Zone 2',
  },
  {
    id: 55,
    name: 'Zone 55',
  },
  {
    id: 123123,
    name: 'Zone 123123',
  },
] as Array<Zone>;

export const mockSites = [
  {
    id: 1,
    name: 'Site 1',
    zones: [mockZones[0]],
  },
  {
    id: 2,
    name: 'Site 2',
    zones: [mockZones[1], mockZones[2], mockZones[3]],
  },
  {
    id: 3,
    name: 'Site 3',
    zones: [],
  },
] as Array<Site>;

export const mockAPIUsers: Array<UserAPI> = [
  {
    id: 1,
    email: 'jsmith@mock.com',
    first_name: 'John',
    last_name: 'Smith',
    force_reset_password: false,
    role: mockRoles[0],
  },
  {
    id: 2,
    email: 'jwilliams@mock.com',
    first_name: 'Jane',
    last_name: 'Williams',
    force_reset_password: false,
    role: mockRoles[1],
  },
  {
    id: 3,
    email: 'kanderson@mock.com',
    first_name: 'Keith',
    last_name: 'Anderson',
    force_reset_password: false,
    role: mockRoles[2],
  },
];

export const mockPaginatedUsers: Array<PaginatedUser> = [
  {
    id: 1,
    email: 'jsmith@mock.com',
    firstName: 'John',
    lastName: 'Smith',
    active: true,
    enabled: 'yes',
    role: mockRoles[0],
    roleName: mockRoles[0].name,
    sites: [mockSites[0], mockSites[2]],
  },
  {
    id: 2,
    email: 'jwilliams@mock.com',
    firstName: 'Jane',
    lastName: 'Williams',
    role: mockRoles[1],
    roleName: mockRoles[1].name,
    sites: [mockSites[1]],
    active: true,
    enabled: 'yes',
  },
  {
    id: 3,
    email: 'kanderson@mock.com',
    firstName: 'Keith',
    lastName: 'Anderson',
    role: mockRoles[2],
    roleName: mockRoles[2].name,
    sites: [mockSites[0]],
    active: false,
    enabled: 'no',
  },
];

export const mockUserPagination: UserPagination = {
  count: 3,
  next: null,
  prev: null,
  results: mockPaginatedUsers,
};

export const mockPaginatedApiUsers: Array<PaginatedAPIUser> = [
  {
    ...mockAPIUsers[0],
    is_active: true,
    sites: [mockSites[0], mockSites[2]],
  },
  {
    ...mockAPIUsers[1],
    is_active: true,
    sites: [mockSites[1]],
  },
  {
    ...mockAPIUsers[2],
    is_active: false,
    sites: [mockSites[0]],
  },
];

export const mockUserAPIPagination: UserAPIPagination = {
  count: 3,
  next: null,
  prev: null,
  results: mockPaginatedApiUsers,
};

export const mockCreateUpdateUser: CreateUpdateUser = {
  id: 1,
  email: mockAPIUsers[0].email,
  firstName: mockAPIUsers[0].first_name,
  lastName: mockAPIUsers[0].last_name,
  active: true,
  password: 'password',
  role: 1,
  sites: [1, 3],
};

export const mockCreateUpdateAPIUser: CreateUpdateAPIUser = {
  email: mockAPIUsers[0].email,
  first_name: mockAPIUsers[0].first_name,
  last_name: mockAPIUsers[0].last_name,
  is_active: true,
  password: 'password',
  role: 1,
  sites: [1, 3],
};
