import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IncidentStatusesState } from '@incidents/store/statuses.reducers';

export const REDUCER_STATUS_ID = 'incidentStatus';

export const selectIncidentStatuses = createFeatureSelector<IncidentStatusesState>(REDUCER_STATUS_ID);

export const selectIncidentStatusesEntities = createSelector(
  selectIncidentStatuses,
  (statusesState) => statusesState.entities
);

export const selectCategory = (index: number) =>
  createSelector(selectIncidentStatuses, (statusesState) => statusesState.entities[index]);

export const selectIncidentStatusesLoading = createSelector(
  selectIncidentStatuses,
  (statusesState) => statusesState.loading
);

export const selectIncidentStatusesFail = createSelector(selectIncidentStatuses, (statusesState) => statusesState.fail);
