import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '@app/store';

import { User } from '@auth/models/auth.models';
import { Feature } from '@permissions/models/permissions.models';
import { PermissionsService } from '@permissions/services/permissions.service';
import { HOMEPAGE } from '@core/constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  user: User | null = null;

  constructor(private router: Router, private store: Store<AppState>, private permissionsService: PermissionsService) {
    this.store.select('auth').subscribe((data) => (this.user = data.user));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentNavigation = this.router.getCurrentNavigation();
    const redirectState = currentNavigation.extras.state?.redirect;

    const feature = next.data.feature as Feature;
    if (!this.permissionsService.hasPermissionFor(feature)) {
      this.router.navigate([HOMEPAGE], { state: { redirect: redirectState || state.url } });
      return false;
    }
    else {
      return true;
    }
  }
}
