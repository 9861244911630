import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SERVER_REGEX } from '@core/constants/app.constants';
import { HttpResponseData } from '@core/interceptors/http-response';
import { VersionMetadata } from '@core/models/metadata/metadata.model';
import { ServerService } from '@server/services/server.service';


@Injectable({
  providedIn: 'root',
})
export class MetadataService {

  constructor(
    private http: HttpClient,
    private serverService: ServerService,
  ) {}

  extractServerName(url: string): string {
    return url.match(SERVER_REGEX)[1];
  }

  public getAppVersion(): Observable<VersionMetadata>[] {
    const servers = this.serverService.storedServers.value;
    return servers.map(server => this.http
        .get<HttpResponseData<VersionMetadata>>(`${server.api}.well-known/version`)
        .pipe(map((response: any) => ({serverName: this.extractServerName(server.api), version: response.version} as VersionMetadata)))
        .pipe(catchError((error) => throwError(error)))
    );
  }
}
