/* eslint-disable */
export const handleSettings = () => {
  return [
    {
      id: 1,
      livewall_columns: 3,
      livewall_rows: 3,
    },
  ];
};
