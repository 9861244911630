import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError, of } from 'rxjs';
import { catchError, map, mergeAll, mergeMap, toArray } from 'rxjs/operators';
import * as moment from 'moment';

import { HttpResponseData } from '@core/interceptors/http-response';
import { SERVER_REGEX } from '@core/constants/app.constants';
import { Anomaly, anomalySerializer, AnomalyAPI } from '@core/models/anomaly/anomaly.model';
import { ServerService } from '@server/services/server.service';

@Injectable({
  providedIn: 'root',
})
export class AnomaliesService {
  private get anomaliesBaseUrl(): string {
    return `${this.serverService.currentServer.api}anomalies/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  public getAnomaliesByFilters(
    range: { startDate: Date; endDate: Date },
    serverUrl: string = null,
    supressErrors = false,
    cameraIds?: number[],
  ): Observable<Anomaly[]> {
    const startDate = range.startDate ? `start=${moment.utc(range.startDate).format('YYYY-MM-DD+HH:mm:ss')}&` : '';
    const endDate = range.endDate ? `end=${moment.utc(range.endDate).format('YYYY-MM-DD+HH:mm:ss')}&` : 'end=&';
    const camera = (cameraIds && cameraIds.length) ? `camera=${cameraIds.join(',')}&` : '';
    const endpoint = `anomalies/?${camera}${startDate}${endDate}ordering=start,end&limit=1000000&offset=0`;

    return forkJoin(this.requestAllGetAnomalies(endpoint, serverUrl, supressErrors)).pipe(
      mergeMap((anomaly) => anomaly),
      mergeAll(),
      toArray()
    );
  }

  public fetchAnomaly(anomalyId: number): Observable<Anomaly> {
    const endpoint = `${this.anomaliesBaseUrl}${anomalyId}`;

    return this.http
      .get<AnomalyAPI>(endpoint)
      .pipe(map((anomaly) => anomalySerializer(anomaly)))
      .pipe(catchError((error) => throwError(error)));
  }

  extractServerBaseUri(url: string): string {
    const serverProtocol = url.split('//')[0];
    const regexMatch = url.match(SERVER_REGEX);
    return `${serverProtocol}//${regexMatch[1]}`;
  }

  private requestAllGetAnomalies(params: string, serverUrl: string = null, supressErrors = false): Observable<Anomaly[]>[] {
    const servers =
      serverUrl ?
      this.serverService.currentServers.value.filter((server) => server.api === serverUrl) :
      this.serverService.currentServers.value;

    return servers.map((server) =>
      this.http
        .get<HttpResponseData<AnomalyAPI[]>>(`${server.api}${params}`)
        .pipe(map((anomalies) => anomalies.results))
        .pipe(map((anomalies) => anomalies.map((anomaly) => anomalySerializer(anomaly, this.extractServerBaseUri(server.api)) as Anomaly)))
        .pipe(catchError((error) => {
          if (supressErrors) {
            return of([]);
          }
          else {
            return throwError(error);
          }
        })
      )
    );
  }
}
