import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { ClientsEffects } from '@clients/store/clients.effects';
import { HomeComponent } from '@core-home-component';
import { PermissionsModule } from '@permissions/permissions.module';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    EffectsModule.forFeature([ClientsEffects]),
    PermissionsModule],
  exports: [],
  declarations: [HomeComponent],
})
export class CoreModule {}
