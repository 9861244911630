<footer>
  <div class="footer-icons">
    <div class="footer-icon-item" *ngFor="let icon of icons; index as i" (click)="onClick(icon)">
      <span mat-icon-button [matTooltip]="icon.tooltip" matTooltipPosition="above" [attr.disabled]="icon.disabled" *ngIf="!icon.hidden">
        <mat-icon>{{ icon.name }}</mat-icon>
      </span>
      <span *ngIf="icon.label?.length" class="footer-icon-label">{{ icon.label }}</span>
    </div>
  </div>
</footer>
