<div fxLayout="column" fxFlexFill>
  <div class="navbar">
    <icetana-navbar-dark>
      <ng-container items>
        <span fxLayout="row" fxLayoutAlign="space-between center">
          <div class="navbar-dark__logo-container-small">
            <a [routerLink]="['/']" class="navbar-dark__logo" title="icetana"></a>
          </div>
          <span *ngIf="user$ | async" fxLayout="row" fxLayoutAlign="space-evenly center">
            <ng-container *ngIf="!(user$ | async)?.forceResetPassword">
              <a *icetanaHasAccessTo="'view_highlight_grid'" mat-button [routerLink]="['/highlight-grid']"
                [routerLinkActive]="['active']">
                <mat-icon aria-hidden="false" aria-label="24 hours Highlight Grid">view_module</mat-icon>
                <span>Highlight</span>
              </a>
              <a *icetanaHasAccessTo="'view_livewall'" mat-button [routerLink]="['/live-wall']"
                [routerLinkActive]="['active']">
                <mat-icon aria-hidden="false" aria-label="cameras">videocam</mat-icon>
                <span>Live</span>
              </a>
            </ng-container>

            <button mat-button [matMenuTriggerFor]="serverDropdown">
              <mat-icon
                aria-hidden="false"
                aria-label="live stream"
                [class.success-icon]="serverHealthIndicator"
              >circle</mat-icon>
            </button>
            <mat-menu class="settings-dropdown-menu dropdown-server-menu" #serverDropdown="matMenu">
              <a mat-button disabled *ngFor="let server of eventStreamServers.servers | keyvalue" [matTooltip]="serverTooltip(server.value)" matTooltipPosition="right">
                <mat-icon [class.success-icon]="serverHealth(server.value)" aria-hidden="false" aria-label="computer">computer</mat-icon>
                <span>{{ extractServerName(server.key) }}</span>
              </a>
            </mat-menu>
          </span>

          <div class="navbar-dark__logo-container">
            <a [routerLink]="['/']" class="navbar-dark__logo" title="icetana"></a>
          </div>

          <span *ngIf="user$ | async" fxLayout="row" fxLayoutAlign="space-evenly center">
            <ng-container *ngIf="!(user$ | async)?.forceResetPassword">
              <button *icetanaHasAccessTo="'list_incidents'" mat-button [matMenuTriggerFor]="reportsDropdown">
                <mat-icon aria-hidden="false" aria-label="cameras">query_stats</mat-icon>
              </button>
              <mat-menu class="settings-dropdown-menu" #reportsDropdown="matMenu">
                <a mat-button [routerLink]="['/incidents']" [routerLinkActive]="['active']">
                  <mat-icon aria-hidden="false" aria-label="star">star</mat-icon>
                  <span>Incidents</span>
                </a>
                <a mat-button [routerLink]="['/analytics']" [routerLinkActive]="['active']">
                  <mat-icon aria-hidden="false" aria-label="analytics">analytics</mat-icon>
                  <span>Analytics</span>
                </a>
              </mat-menu>
            </ng-container>

            <ng-container *ngIf="!(user$ | async)?.forceResetPassword">
              <button *icetanaHasAccessTo="'list_incidents'" mat-button [matMenuTriggerFor]="collectionsDropdown">
                <mat-icon aria-hidden="false" aria-label="cameras">remove_red_eye</mat-icon>
              </button>
              <mat-menu class="settings-dropdown-menu" #collectionsDropdown="matMenu">
                <ng-container *ngIf="!(user$ | async)?.forceResetPassword">
                  <a *icetanaHasAccessTo="'list_cameras'" fxLayout="row" fxLayoutAlign="left" mat-button [routerLink]="['/cameras']"
                    [routerLinkActive]="['active']" [hidden]="(user$ | async)?.forceResetPassword === true">
                    <mat-icon aria-hidden="false" aria-label="videocam">videocam</mat-icon>
                    <span>Cameras</span>
                  </a>
                  <a *icetanaHasAccessTo="'list_clients'" fxLayout="row" fxLayoutAlign="left" mat-button [routerLink]="[clientsListPath]"
                    [routerLinkActive]="['active']" [hidden]="(user$ | async)?.forceResetPassword === true">
                    <mat-icon aria-hidden="false" aria-label="business">business</mat-icon>
                    <span>Clients</span>
                  </a>
                  <a *icetanaHasAccessTo="'list_users'" fxLayout="row" fxLayoutAlign="left" mat-button [routerLink]="[usersListPath]"
                    [routerLinkActive]="['active']" [hidden]="(user$ | async)?.forceResetPassword === true">
                    <mat-icon aria-hidden="false" aria-label="people">people</mat-icon>
                    <span>Users</span>
                </a>
                </ng-container>
              </mat-menu>
            </ng-container>

            <button mat-button [matMenuTriggerFor]="settingsDropdown">
              <mat-icon aria-hidden="false" aria-label="cameras">settings</mat-icon>
            </button>
            <mat-menu class="settings-dropdown-menu" #settingsDropdown="matMenu">
              <a fxLayout="row" fxLayoutAlign="left" mat-button [href]="assistanceEmailLink"
                [routerLinkActive]="['active']">
                <mat-icon aria-hidden="false" aria-label="help">help</mat-icon>
                <span>Contact Us</span>
              </a>
              <a fxLayout="row" fxLayoutAlign="left" mat-button (click)="openAboutDialog()">
                <mat-icon aria-hidden="false" aria-label="about">info</mat-icon>
                <span>About</span>
              </a>
              <a fxLayout="row" fxLayoutAlign="left" mat-button [routerLink]="[changePasswordPath]" [routerLinkActive]="['active']">
                <mat-icon aria-hidden="false" aria-label="password">password</mat-icon>
                <span>Change password</span>
              </a>
              <a fxLayout="row" fxLayoutAlign="left" mat-button [routerLink]="[logoutPath]"
                [routerLinkActive]="['active']">
                <mat-icon aria-hidden="false" aria-label="logout">logout</mat-icon>
                <span>Sign out</span>
              </a>
            </mat-menu>
          </span>
        </span>
      </ng-container>
    </icetana-navbar-dark>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>