import { createReducer, on } from '@ngrx/store';

import { PaginatedUser } from '@users/models/user.models';
import { usersFetchFail, usersFetchInit, usersFetchSuccess } from '@users/store/users.actions';

export interface UsersState {
  entities: PaginatedUser[];
  loading: boolean;
  count?: number;
  fail?: boolean;
  success?: boolean;
}

export const initialUsersState: UsersState = {
  entities: [],
  loading: false,
};

export const usersReducer = createReducer(
  initialUsersState,
  // users list
  on(usersFetchInit, (state) => ({
    ...state,
    loading: true,
  })),
  on(usersFetchSuccess, (state, { payload }) => {
    const results = [...payload.results];
    return {
      ...state,
      count: payload.count,
      entities: [...results],
      loading: false,
      success: true,
    };
  }),
  on(usersFetchFail, (state, error: Error) => ({
    ...state,
    loading: false,
    fail: true,
    success: false,
  }))
);
