import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '@app/store';

import { User } from '@auth/models/auth.models';
import { Feature, ProtectedFeature } from '@permissions/models/permissions.models';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private user: User;
  private protectedFeatures: ProtectedFeature[] = [];

  constructor(private store: Store<AppState>) {
    this.store.select('auth').subscribe((s) => {
      this.user = s.user;
    });
    this.store.select('permissions').subscribe((s) => {
      this.protectedFeatures = s.protectedFeatures;
    });
  }

  hasPermissionFor(feature: Feature): boolean {
    if (!this.user || this.user.forceResetPassword) {
      return false;
    }
    const protectedFeature = this.protectedFeatures.find((f) => f.feature === feature);
    if (!protectedFeature) {
      return true;
    }
    return protectedFeature.allow.find((accessLevel) => accessLevel === this.user.role.order) !== undefined;
  }
}
