import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

import { VersionMetadata } from '@core/models/metadata/metadata.model';
import { MetadataService } from '@core/services/metadata/metadata.service';


@Component({
  selector: 'icetana-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent implements OnInit {
  public serverAppVersions$: Observable<VersionMetadata>[];

  constructor(
    private dialogRef: MatDialogRef<any>,
    private metadataService: MetadataService,
  ) {}

  ngOnInit(): void {
    this.serverAppVersions$ = this.metadataService.getAppVersion();
  }

  close(): void {
    this.dialogRef.close();
  }
}
