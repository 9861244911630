import { Component, Input, NgModule, OnInit } from '@angular/core';

// @ts-ignore
@Component({
  selector: 'icetana-navbar-dark',
  templateUrl: './navbar-dark.component.html',
  styleUrls: ['./navbar-dark.component.scss'],
})
export class NavbarDarkComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
