import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  Client,
  ClientAPI,
  ClientPagination,
  clientSerializer,
  clientDeserializer,
  ClientAPIPagination,
  clientPaginationSerializer
} from '@clients/models/client.models';
import { ServerService } from '@server/services/server.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private get userBaseUrl(): string {
    return `${this.serverService.currentServer.api}clients/`;
  }

  constructor(private http: HttpClient, private serverService: ServerService) {}

  fetchAll(pageLimit: number, pageOffset: number): Observable<ClientPagination> {
    return this.http
      .get<ClientAPIPagination>(`${this.userBaseUrl}?limit=${pageLimit}&offset=${pageOffset}`)
      .pipe(map((response) => clientPaginationSerializer(response) as ClientPagination))
      .pipe(catchError((error) => throwError(error)));
  }

  delete(client: Client): Observable<any> {
    return this.http
      .delete(`${this.userBaseUrl}${client.id}/`)
      .pipe(map(() => client))
      .pipe(catchError((error) => throwError(error)));
  }

  create(clientPayload: Client): Observable<Client> {
    return this.http
      .post<ClientAPI>(`${this.userBaseUrl}`, clientDeserializer(clientPayload))
      .pipe(map((client) => clientSerializer(client) as Client))
      .pipe(catchError((error) => throwError(error)));
  }

  update(clientPayload: Client): Observable<Client> {
    return this.http
      .put<ClientAPI>(`${this.userBaseUrl}${clientPayload.id}/`, clientDeserializer(clientPayload))
      .pipe(map((client) => clientSerializer(client) as Client))
      .pipe(catchError((error) => throwError(error)));
  }
}
