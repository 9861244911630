const USERS_ROUTE_USERS_LIST = 'list';
const USERS_STORE_ID = 'users';

const EMAIL_MAX_LENGTH = 254;
const PASSWORD_MAX_LENGTH = 128;
const FIRST_NAME_MAX_LENGTH = 150;
const LAST_NAME_MAX_LENGTH = 150;

export {
  USERS_ROUTE_USERS_LIST,
  USERS_STORE_ID,
  EMAIL_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
};
