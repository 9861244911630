import { Component, Input } from '@angular/core';

import { StatusIndicator } from '@shared/constants/status.constants';
@Component({
  selector: 'icetana-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
  /**
   * The status indicator accepts three Status types OFF, WARN, OK, NONE
   */
  @Input() statusIndicator: StatusIndicator = StatusIndicator.NONE;
  /**
   * The status Subject is the domain/object/device the status indicator represents.
   * It is put as the suffix to the status message
   * E.g. Algorithm (OFF)
   */
  @Input() statusSubject: string;

  /**
   * Returns the concatenated message for the tooltip.
   * The subject and it's status are concatenated together.
   */
  get tooltipMessage(): string {
    return `${this.statusSubject} (${this.statusIndicator})`;
  }
}
