import { AccessLevel, ProtectedFeature } from '@permissions/models/permissions.models';

export const protectedIncidents: ProtectedFeature[] = [
  {
    feature: 'create_incident',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser, AccessLevel.Operator],
  },
  {
    feature: 'update_incident',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser, AccessLevel.Operator],
  },
  {
    feature: 'delete_incident',
    allow: [AccessLevel.Administrator, AccessLevel.Superviser, AccessLevel.Operator],
  },
  {
    feature: 'list_incidents',
    allow: [
      AccessLevel.Administrator,
      AccessLevel.Executive,
      AccessLevel.Superviser,
      AccessLevel.Operator,
      AccessLevel.View,
    ],
  },
];
