<div class="grid-videos">
  <div class="center-div" *ngIf="(anomalies$ | async) === null || isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div class="container container-fluid" [style.visibility]="isLoading || !anomalies || !anomalies.length ? 'hidden' : 'visible'">
    <div class="scroll-arrow" [style.top]="scrollPos" [style.opacity]="showScrollInfo? '1' : '0' " *ngIf="!isLoading && anomalies && anomalies.length">
      <mat-icon>arrow_forward</mat-icon>
    </div>
    <div class="scroll-text" [style.top]="scrollPos" [style.opacity]="showScrollInfo? '1' :'0'"  *ngIf="!isLoading && anomalies && anomalies.length">
      <span>{{ scrollDate }}</span>
    </div>
    <div *ngIf="!isLoading">
      <div style="position: absolute; z-index: 999; right: -25px; top: -14px;" [style.opacity]="anomalies && anomalies.length ? '1' :'0'">
        <button mat-icon-button aria-label="Scroll to top" matTooltip="Scroll to top" (click)="jumpToTop()">
          <mat-icon style="transform: rotate(90deg) scale(1);">skip_previous</mat-icon>
        </button>
      </div>
      <div style="position: absolute; z-index: 999; right: -25px; bottom: 70px;" [style.opacity]="anomalies && anomalies.length ? '1' :'0'">
        <button mat-icon-button aria-label="Share link" matTooltip="Share link" (click)="generateShareLink()">
          <mat-icon style="transform: scale(1);">share</mat-icon>
        </button>
      </div>
      <div style="position: absolute; z-index: 999; right: -25px; bottom: -14px;" [style.opacity]="anomalies && anomalies.length ? '1' :'0'">
        <button mat-icon-button aria-label="Scroll to bottom" matTooltip="Scroll to bottom" (click)="jumpToBottom()">
          <mat-icon style="transform: rotate(90deg) scale(1);">skip_next</mat-icon>
        </button>
      </div>
      <div style="position: absolute; z-index: 999; right: -25px; bottom: 25px;" [style.opacity]="anomalies && anomalies.length ? '1' :'0'">
        <button mat-icon-button aria-label="Toggle playback" [matTooltip]="speedList[activeSpeed].label" (click)="onToggleSpeedButton()">
          <div class="playback-icon" [class]="speedList[activeSpeed].iconClass"></div>
        </button>
      </div>
    </div>
    <div class="scroll-bottom-fade" [style.opacity]="scrollAtBottom? '0' :'1'"></div>
    <div class="scroll-top-fade" [style.opacity]="scrollAtTop? '0' :'1'"></div>

    <cdk-virtual-scroll-viewport itemSize="280" class="viewport" *ngIf="!isLoading" #contentViewport>
      <!-- https://stackoverflow.com/questions/55696084/obtaining-a-static-component-reference-within-a-cdk-virtual-scroller-reference -->
      <div *cdkVirtualFor="let anomalyRow of anomalyRows; templateCacheSize: 0; let indexOfelement=index;">
        <div class="time-divider">
          <span>{{ timeDivider( indexOfelement ) }}</span>
        </div>
        <div class="viewport-row">
          <div class="grid-videos__tile" *ngFor="let anomaly of anomalyRow" (click)="openAnomalyView(anomaly, $event)">
            <icetana-vjs-player
            [ngClass]="{'allow-mouse-pointer': canClick}"
            [overlayPath]="this.featureAnomalyDiagFlag ? anomaly.anomalyDiagnostic?.diagnosticsPath : null"
            [anomalyModel]="anomalyModels[anomaly.anomalyDiagnostic?.modelName]"
            [anomalyLabel]="getAnomalyLabel(anomaly)"
            [videoTitleRight]="anomaly.cameraName"
            [incidentExists]="matchIncidentWithAnomaly(anomaly)"
            [anomalyId]="anomaly.id"
            [options]="{
              autoplay: false,
              fluid: true,
              controls: false,
              preload: 'auto',
              loop: true,
              sources: [{ src: anomaly.videoUrl, type: 'video/mp4' }]
            }"
            #video
            ></icetana-vjs-player>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>

    <!-- Context menu on right click -->
    <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
    ></div>

    <mat-menu #contextMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <ng-container *ngIf="videoInCell">
          <button mat-menu-item [matMenuTriggerFor]="incidentMenu" *ngIf="(availableCategories$ | async).length">Quick save</button>
          <button mat-menu-item (click)="onSaveAndCategorise()">Save and categorise</button>
        </ng-container>
        <mat-menu #incidentMenu="matMenu" class="incident-category-menu">
          <ng-container *ngFor="let category of (availableCategories$ | async)">
            <button mat-menu-item (click)="onQuickSave(category.id)">{{ category.name }}</button>
          </ng-container>
        </mat-menu>
        <ng-container *ngIf="!videoInCell">
          <div mat-menu-item disabled>No anomaly playing!</div>
        </ng-container>
        <!-- 
        <button mat-menu-item (click)="downloadDialogPrompt(0)" [disabled]="!anomalies || anomalies?.length === 0">Download whole highlight reel</button>
        <button mat-menu-item (click)="downloadDialogPrompt(1)" [disabled]="!anomalies || anomalies?.length === 0">Download individual video clips</button>
        -->
      </ng-template>
    </mat-menu>

  </div>
</div>
