/* eslint-disable @typescript-eslint/member-ordering */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { ColumnConfig } from '@shared/models/column-config.model';
import { takeUntil, delay } from 'rxjs/operators';
import { DataTableEmitterService } from '@shared/services/icetana-data-table-emitter.service';
import { Subject } from 'rxjs';
import { Camera } from '@cameras/models/cameras/cameras.models';

@Component({
  selector: 'icetana-data-table',
  templateUrl: './icetana-data-table.component.html',
  styleUrls: ['./icetana-data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0' })),
      state('expanded', style({height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class IcetanaDataTableComponent implements OnInit, OnDestroy, AfterViewInit {
  // Pass data source
  @Input() dataSource: MatTableDataSource<any> = null;
  // Event Emitter for sending expansion states back to parent
  @Output() expandStateEvent = new EventEmitter<{id: number; expandState: boolean}>();
  // pass table columns with their configs
  @Input() tableColumns: ColumnConfig<any>[] = null;
  // pagination page size options
  @Input() pageSizeOptions = [10, 20, 50, 100];
  // pagination row size
  @Input() pageSize = 20;
  // pagination lenght
  @Input() pageLength = 0;
  // pagination index
  @Input() pageIndex = 0;
  // no pagination
  @Input() paginationEnabled = true;

  // Table row action column template elements
  @Input() customTemplateRef: TemplateRef<HTMLElement>[];
  // Table row expand  row section template elements
  @Input() expandContentTemplateRef: TemplateRef<HTMLElement>;

  /**
   * Header template input
   */
  @Input() headerTemplateRef: TemplateRef<HTMLElement>[];

  // Show filter form field
  @Input() showFilter = true;

  // On Row click event handler
  @Output() rowClick = new EventEmitter<any>();
  // Next paginator page click event handler
  @Output() nextPage = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static : false}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input;

  private ngUnsubscribe = new Subject<void>();

  // MatPaginator Output
  pageEvent: PageEvent;
  displayedColumns: string[];
  expandedElement = null;
  expandedAnomalies = null;
  previousPageSize: number = this.pageSize;
  paginatorPageLength: number;
  paginatorLoaded: boolean;
  dataRendering: boolean;

  constructor(private dataTableEmitter: DataTableEmitterService, private elem: ElementRef) {}

  // emit any updates to expansion events
  updateExpandEvent(id: number, expandState: boolean): void {
    this.expandStateEvent.emit({id, expandState});
  }

  /**
   * This action is triggered from CameraListComponent via DataTableEmitter service
   */
  private onActionButtonsClicked(): void {
    this.dataTableEmitter.anomaliesListClickedS = this.dataTableEmitter.anomaliesListClicked
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: {camera: Camera; template: any}) => {
        // Toggle anomalies display
        if (data.camera.guid === this.expandedAnomalies?.camera?.guid) {
          this.expandedAnomalies = null;
        }
        else {
          this.expandedAnomalies = data;
        }

        // catch ONLY directives related to the camera list, and not the anomaly list
        if (this.dataSource.data as Camera[]) {
          const camIdx = this.dataSource.data.findIndex((c) => data.camera.id === c.id);
          const expandState = data.camera.guid === this.expandedAnomalies?.camera?.guid;
          this.updateExpandEvent(camIdx, expandState);
        }
      });
  }

  private tablePaginatorSet(): void {
    // prevents the paginator from being reloaded multiple times
    if (!this.paginatorLoaded) {
      this.paginator.initialized.pipe(delay(0))
      .subscribe(() => {
        this.paginatorPageLength = this.pageLength;
        this.dataSource.paginator = this.paginator;
        this.paginatorLoaded = true;
        this.dataSource.connect().subscribe(() => this.dataRendering = false);
      });
    }
  }

  ngOnInit(): void {
    if (!this.dataSource) {
      throw Error('Icetana Data Table must be provided with data source.');
    }
    if (!this.tableColumns) {
      throw Error('Icetana Data Table must be provided with column definitions.');
    }
    this.displayedColumns = this.tableColumns.map((column, index) => column.name);
    this.onActionButtonsClicked();

    // Initially set the datarending flag based on if pagination is enabled
    this.dataRendering = this.paginationEnabled;
    this.paginatorLoaded = false;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    if (this.paginationEnabled) {
      this.tablePaginatorSet();
    }
    else {
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onRowClick(expandedElement: any): void {
    this.rowClick.next(expandedElement);
  }

  handlePageEvent(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.previousPageSize = this.pageSize;
    this.pageSize = event.pageSize;

    // New page size
    if (this.pageSize > this.previousPageSize) {
      this.nextPage.emit(event);
    }
    // Next page clicked
    if (event.pageIndex > event.previousPageIndex) {
      this.nextPage.emit(event);
    }
    // Previous page clicked
    if (event.pageIndex < event.previousPageIndex) {
      // No actions needed when going back
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }
}
