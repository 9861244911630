/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { Observable, Subject, } from 'rxjs';
import { delay, retryWhen, takeUntil, tap, timeout } from 'rxjs/operators';
import { SSE } from 'sse.js';

import { LiveAnomalyWebSocketService } from '@anomalies/services/live-anomaly-websocket/live-anomaly-web-socket.service';
import { AppState } from '@app/store';
import { AuthService } from '@auth/services/auth.service';
import {
  RETRY_DELAY,
  SSE_STREAM_ROUTE,
  TEST_EVENTSTREAM_TIMEOUT,
  EARLIEST_LIVE_EVENT_REQUEST_INIT,
  SSE_RETRY_ERROR_COUNT_LIMIT,
  SERVER_REGEX,
  LOCAL_STORAGE_EXTEND_LOGGING
} from '@core/constants/app.constants';
import { LiveAnomaly, liveAnomalySerializer, LiveAnomalyStatus } from '@core/models/anomaly/anomaly.model';
import {
  liveAnomalyAllConnectionsFinished,
  liveAnomalyCloseAllConnectionsFinished,
  liveAnomalyCloseConnectionSuccess,
  liveAnomalyConnectionInit,
  liveAnomalyConnectionSuccess,
  liveAnomalyConnectionFail,
  liveAnomalyReceiveMessage
} from '@core/store/live-anomalies/anomalies.actions';
import {
  liveAnomalyStartPlayingOnLiveWallInit,
  liveAnomalyStopPlayingOnLiveWallInit,
  clearLiveWall,
} from '@core/store/livewall/livewall.actions';
import { AnomaliesService } from '@highlight-grid/services/anomalies.service';
import { ServerService } from '@server/services/server.service';


@Injectable({
  providedIn: 'root',
})
export class LiveAnomalySseService {
  private sourceMap = new Map<string, any>();
  private errorMap = new Map<string, {
    count: number;
    retry: boolean;
  }>();
  private ngUnsubscribe = new Subject<void>();
  private extendedLogging: string;

  constructor(
    private store: Store<AppState>,
    private serverService: ServerService,
    private authService: AuthService,
    private http: HttpClient,
    private anomalyService: AnomaliesService,
    private anomalyWebSocketService: LiveAnomalyWebSocketService,
  ) {
    this.extendedLogging = localStorage.getItem(
      LOCAL_STORAGE_EXTEND_LOGGING
    );
  }

  // Function for testing a preflight check of the eventstream route
  testEventStream(url: string) {
    return this.http.options<any>(url)
    .pipe(timeout(TEST_EVENTSTREAM_TIMEOUT));
  }

  getRunningEvents(serverUrl?: string) {
    return this.anomalyService.getAnomaliesByFilters(
      { startDate: moment().subtract(EARLIEST_LIVE_EVENT_REQUEST_INIT, 'hours').toDate(), endDate: null },
      serverUrl,
      true,
      null
    );
  }

  async resetLivewallWithRunningEvents(
    errorObj: {
      count: number;
      retry: boolean;
    },
    serverUrl?: string
  ){
    // if retrying, then do not reset any events on the livewall
    if (errorObj?.retry) {
      return;
    }

    this.store.dispatch(clearLiveWall({ serverUrl }));

    await this.getRunningEvents(serverUrl).toPromise().then((events) => {
      for (const event of events) {
        const newLiveAnomaly: LiveAnomaly = {
          sender: '',
          timestamp: '',
          body: {
            anomalyId: event.id,
            status: LiveAnomalyStatus.Start,
            cameraGuid: event.cameraGuid,
            cameraName: event.cameraName,
            anomalyGuid: event.guid,
            hlsUri: event.hlsUri,
            start: moment(event.start).valueOf()*1E+6,
            end: null,
            anomalyDiagnostic: event.anomalyDiagnostic,
            timezone: event.timezone,
          },
        };
        this.store.dispatch(liveAnomalyStartPlayingOnLiveWallInit({ payload: newLiveAnomaly }));
      }
    });
  }

  clearLiveWall(
    errorObj: {
      count: number;
      retry: boolean;
    },
    sseUrl: string
  ) {
    // if error count is under it's limit
    if (errorObj.count < SSE_RETRY_ERROR_COUNT_LIMIT) {
      // increment the counter
      this.errorMap.set(sseUrl, {
        ...errorObj,
        count: errorObj.count + 1
      });
    }
    else {
      // clear the specific servers events off of the livewall
      this.store.dispatch(clearLiveWall({ serverUrl: this.extractServerBaseUri(sseUrl) }));
    }
  }

  extractServerBaseUri(url: string): string {
    const serverProtocol = url.split('//')[0];
    const regexMatch = url.match(SERVER_REGEX);
    return `${serverProtocol}//${regexMatch[1]}`;
  }

  async dispatchStreamSource(server: any): Promise<void> {
    const wsUrl = `${server.websocket}live-anomalies/`;
    const eventSourceRoute = `${this.extractServerBaseUri(server.api)}/${SSE_STREAM_ROUTE}/`;

    await this.testEventStream(eventSourceRoute)
    .toPromise()
    .then(async (data) => {
      if (!this.sourceMap.get(eventSourceRoute)) {
        this.sourceMap.set(
          eventSourceRoute,
          await this.register(server.api, eventSourceRoute)
        );
      }
    })
    .catch(async (data) => {
      if (!this.sourceMap.get(wsUrl)) {
        this.sourceMap.set(
          wsUrl,
          await this.anomalyWebSocketService.register(wsUrl)
        );
      }
    });
  }


  public async init(): Promise<void> {
    // reset the subscriptions subject
    this.ngUnsubscribe = new Subject<void>();
    this.anomalyWebSocketService.ngUnsubscribe = new Subject<void>();

    const servers = this.serverService.storedServers.value;

    if (!servers.length) {
      return;
    }

    for (const server of servers) {
      await this.dispatchStreamSource(server);
    }

    for (const [url, eventSource] of this.sourceMap) {
      this.store.dispatch(liveAnomalyConnectionInit({ payload: { url } }));
      // eslint-disable-next-line no-underscore-dangle
      const addressProtocol = eventSource._config?.url.split('//')[0];
      // if a websocket object, call the websocket service listen function
      if ((addressProtocol === 'ws:') || (addressProtocol === 'wss:')) {
        this.anomalyWebSocketService.listenToMessage(eventSource, url);
      }
      else {
        this.listenToEventStream(eventSource, url);
      }
    }
    this.store.dispatch(liveAnomalyAllConnectionsFinished());
  }

  async register(baseUrl: string, sseUrl: string): Promise<any> {
    return this.getNewEventSource(baseUrl, sseUrl);
  }

  private getNewEventSource(baseUrl: string, sseUrl: string): Observable<any> {
    return new Observable<any>((subscriber) => {
      let eventSource: SSE = null;

      this.authService.getValidToken(baseUrl)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
      (token) => {
        if (!token) {
          subscriber.error(new Error('No valid token'));
          return;
        }
        eventSource = new SSE(sseUrl, {headers: {authorization: `Bearer ${token.access}`}} );
        eventSource.addEventListener('open', () => {
          if (this.extendedLogging) {
            console.log('opening SSE');
          }
          this.store.dispatch(liveAnomalyConnectionSuccess({ payload: { url: sseUrl } }));

          const errorObj = this.errorMap.get(sseUrl);
          this.resetLivewallWithRunningEvents(errorObj);

          // reset the error object
          this.errorMap.set(sseUrl, {
            count: 0,
            retry: false
          });
        });
        eventSource.onmessage = (event) => subscriber.next(event.data);
        // Event listener for TTL messages
        eventSource.addEventListener('keep-alive', () => {
          this.store.dispatch(liveAnomalyReceiveMessage({ payload: { url: sseUrl } }));
        });
        eventSource.addEventListener('error', (error) => onError(error));
        eventSource.addEventListener('stream-error', (error) => onError(error));
        const onError = (error) => {
          if (this.extendedLogging) {
            console.log('error SSE');
            console.log(error);
            console.log(eventSource.xhr.status);
            console.log('premature closing SSE');
          }

          eventSource.close();
          subscriber.error(error);
          this.store.dispatch(liveAnomalyConnectionFail({ payload: { url: sseUrl, error: new Error(error)} }));

          // get the current error counter
          const errorObj = this.errorMap.get(sseUrl);

          this.clearLiveWall(errorObj, sseUrl);
        };
        eventSource.stream();
      },
      (err) => subscriber.error(err));

      return () => eventSource ? eventSource.close() : null;
    });
  }

  private retry(errors: Observable<any>): Observable<any> {
    return errors.pipe(
      tap((err) => {
        console.error('Live Anomalies EventStream error:', err.data || err);
      }),
      delay(RETRY_DELAY)
    );
  }

  listenToEventStream(eventSource: SSE, url: string): void {
    // stackoverflow.com/questions/73773354
    eventSource
    .pipe(
      retryWhen((errors) => {
        // if retrying, update errorObj
        const errorObj = this.errorMap.get(url);
        this.errorMap.set(url, {
          ...errorObj,
          retry: true
        });
        return this.retry(errors);
      }),
      takeUntil(this.ngUnsubscribe),
    )
    .subscribe({
      next: (data) => {
        if (data) {
          if (this.extendedLogging) {
            console.log('message');
            console.log(data);
          }
          let anomaly: LiveAnomaly = null;
          try {
            const sanitisedData = data
              .replace(/\\"/g, '"') //any escaped double quotes
              .replace(/^"/g, '') // any first char quote
              .replace(/"$/g, ''); // any last char quote
            anomaly = liveAnomalySerializer(JSON.parse(sanitisedData), this.extractServerBaseUri(url));

          } catch (error) {
            return;
          }

          this.store.dispatch(liveAnomalyReceiveMessage({ payload: { url } }));

          // Start/Stop Playing on the LiveWall
          if (anomaly.body.status === LiveAnomalyStatus.Start) {
            this.store.dispatch(liveAnomalyStartPlayingOnLiveWallInit({ payload: anomaly }));
          } else if (anomaly.body.status === LiveAnomalyStatus.End) {
            this.store.dispatch(liveAnomalyStopPlayingOnLiveWallInit({ payload: anomaly }));
          }
        }
      },
      error: (error) => {
        this.store.dispatch(liveAnomalyConnectionFail({ payload: { url, error: new Error(error)} }));
      },
      complete: () => {
        if (this.extendedLogging) {
          console.log('closing SSE');
        }
        this.store.dispatch(liveAnomalyCloseConnectionSuccess({ payload: { url } }));
      }
    });
  }

  close(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();

    this.anomalyWebSocketService.ngUnsubscribe.next(null);
    this.anomalyWebSocketService.ngUnsubscribe.complete();
    this.anomalyWebSocketService.ngUnsubscribe.unsubscribe();

    for (const [url, eventSource] of this.sourceMap) {
      // if a websocket object
      // eslint-disable-next-line no-underscore-dangle
      const addressProtocol = eventSource._config?.url.split('//')[0];
      if ((addressProtocol === 'ws:') || (addressProtocol === 'wss:')) {
        this.store.dispatch(liveAnomalyCloseConnectionSuccess({ payload: { url } }));
      }
    }

    this.sourceMap.clear();
    this.errorMap.clear();

    this.store.dispatch(liveAnomalyCloseAllConnectionsFinished());
  }
}
